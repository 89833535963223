import React from "react";
import {Link} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import {camelToString, sentenceCaps} from "../../utilities/stringOperations";

const SimpleDropDown = ({items, selected, setSelected, clss, camel}) => {
    const linkItem = (item) => ( <Dropdown.Item key={item.to}><Link to={item.to}>{item.name}</Link></Dropdown.Item>);
    const listItem = (item) => ( <Dropdown.Item key={item.name}>{item.name}</Dropdown.Item>);
    return (
        <Dropdown className='custom-button'>
            <Dropdown.Toggle variant='outline-primary' className={clss}>
               <span className="pe-3">{camel ? sentenceCaps((camelToString(selected))) : selected }</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    items.map(item => (
                        typeof item === "object"
                        ? (
                            item?.isLink
                            ? linkItem(item)
                            : listItem(item)
                            )
                        : ( <Dropdown.Item key={item}  onClick={() => setSelected(item)}><div>
                                {
                                    camel ? sentenceCaps(camelToString(item)) : item
                                }
                        </div></Dropdown.Item>)
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default SimpleDropDown;
