import axios from "axios";
import Layout from "components/Layout/Layout";
import moment from "moment";
import { allUnits } from "plugins/urls";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCaretDown, FaEdit, FaPlus } from "react-icons/fa";
import { Slide, toast } from "react-toastify";

export const getAllUnit = (setState) => {
  axios
    .get(`${allUnits}`)
    .then((res) => {
      setState((state) => ({
        ...state,
        allUnit: res.data.data ?? [],
      }));
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const Unit = () => {
  const [state, setState] = useState({
    allUnit: [],
    totalPendingInvites: 0,
    desc: "",
    unitName: "",
    searchEmail: "",
    pageNo: 0,
    pageSize: 10,
    submit: false,
    add: false,
    unitItem: {},
    update: false,
  });

  const { allUnit, unitItem, searchEmail, pageNo, pageSize } = state;
  const [currentPage, setCurrentPage] = useState(1);

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      unitItem: { ...unitItem, [e.target.name]: e.target.value },
    }));
  };
  const clearSearchEmail = (e) => {
    setState((state) => ({
      ...state,
      searchEmail: "",
    }));
    getAllUnit(setState);
  };

  useEffect(() => {
    getAllUnit(setState);
  }, [pageSize, pageNo]);

  const getUserByEmail = () => {
    if (!searchEmail) {
      toast.error(`Please input an email`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    const found = allUnit.filter(({ email }) => searchEmail === email);
    setState((state) => ({
      ...state,
      allUnit: found,
    }));
  };
  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
  };

  return (
    <Layout title="Units">
      <div className="d-flex justify-content-md-between  align-items-start">
        <div className="d-flex w-50 bg-gray">
          <div className="input_Search d-flex justify-content-center align-items-center  w-50 ms-2 me-2 me-md-0">
            {/* <div className="justify-content-center align-items-center">
              <FiSearch color="#FF6700" />
            </div> */}
            <input
              className="input ml-10"
              placeholder="Search Unit by name"
              name="searchEmail"
              value={searchEmail}
              onChange={onChange}
              required
            />
            {!searchEmail ? null : (
              <i onClick={clearSearchEmail}>
                {" "}
                <AiFillCloseCircle color="#FF6700" />
              </i>
            )}
          </div>
          <button className="orange-button ml-10" onClick={getUserByEmail}>
            Search
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center ">
          <div className=" ">
            <button
              className="orange-button px-3 me-4 fs-14"
              onClick={() =>
                setState((s) => ({ ...s, add: true, unitItem: {} }))
              }
            >
              <FaPlus color={"#fff"} className="mr-5" />
              Create New Unit
            </button>
          </div>
        </div>
      </div>
      <div>
        <Container fluid>
          {/* <Row className="mt-40 ">
            <Col>
              <AdminUserCards
                title="All Unit"
                number={allUnit ? allUnit.length : 0}
              />
            </Col>

            <Col></Col>
            <Col></Col>
          </Row> */}

          <div className="admin-users-table mt-5">
            <Table responsive borderless className="bg-inherit">
              <thead>
                <tr className=" t-head-colored">
                  <th className="py-3 th ">UNIT ID</th>
                  <th className="py-3 th ">UNIT NAME</th>
                  <th className="py-3 th ">CREATED BY</th>
                  <th className="py-3 th ">DATE CREATED</th>
                  <th className="py-3 th text-center">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {allUnit && allUnit.length > 0 ? (
                  allUnit?.map((unitItem, i) => {
                    const {
                      unitId,
                      unitName,
                      createdBy,
                      dateCreated: [y, m, d],
                    } = unitItem;

                    return (
                      <tr
                        key={i}
                        className="p-4"
                        style={{ borderBottom: "0.5px solid #D9D5EC" }}
                      >
                        <td className=" td ">{unitId}</td>
                        <td className=" td ">{unitName}</td>
                        <td className=" td ">{createdBy}</td>
                        <td className=" td ">
                          {moment(
                            `${y}${m.toString().padStart(2, "0")}${d
                              .toString()
                              .padStart(2, "0")}`
                          ).format("ll")}
                        </td>

                        <td className=" td text-center">
                          <i
                            className="text-theme px-3"
                            onClick={() => {
                              setState((s) => ({
                                ...s,
                                update: true,
                                unitItem,
                              }));
                            }}
                          >
                            <FaEdit />
                          </i>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
            {allUnit && allUnit.length > 0 ? (
              <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
                <div className="d-flex me-5">
                  <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                  <div className="dropdown">
                    <i
                      id="addminuserdropdownMenuButton1"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaCaretDown />
                    </i>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="adminuserdropdownMenuButton1"
                    >
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(10)}
                      >
                        10
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(20)}
                      >
                        20
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(50)}
                      >
                        50
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(100)}
                      >
                        100
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="me-4">
                  <p className="m-0">
                    {firstIndex} - {lastIndex} of {allUnit?.length ?? 0}
                  </p>
                </div>
                {/* <div className="mt-3 me-2">
                  <Pagination2
                    total={Math.ceil(allUnit?.length / pageSize)}
                    paginate={paginate}
                    selected={currentPage}
                  />
                </div> */}
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    </Layout>
  );
};
