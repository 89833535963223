import { Box, Button } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import UpgradeKYC from '../screens/KYC/UpgradeKYC';

TransferLimitComponent.propTypes = {
	limit: PropTypes.number,
	setCurrentTab: PropTypes.func,
};

export default function TransferLimitComponent({ limit, tier = 1, setCurrentTab }) {
	return (
		<div style={{textAlign: 'right', fontSize: '.9rem'}} className='w-100 mt-5'>
			{/* <Box pb={1}>
				<small>{`Account Transfer Limit: ${limit.toLocaleString()}`}</small>
			</Box> */}

			<Box className='bold'>
					<svg
						style={{verticalAlign: 'middle'}}
						width='14'
						height='22'
						viewBox='0 0 14 22'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M6.99904 0C5.62247 0.00172601 4.27697 0.409289 3.13082 1.17171C1.98467 1.93413 1.08876 3.01757 0.555143 4.28651C0.0215281 5.55544 -0.126102 6.95355 0.130717 8.30595C0.387537 9.65835 1.03741 10.905 1.99904 11.89V21C1.99955 21.1705 2.04364 21.338 2.1271 21.4866C2.21057 21.6352 2.33064 21.7601 2.47592 21.8492C2.6212 21.9384 2.78687 21.989 2.95718 21.9961C3.1275 22.0032 3.29681 21.9667 3.44904 21.89L6.99904 20.12L10.549 21.89C10.6884 21.9614 10.8425 21.9991 10.999 22C11.1863 22.0006 11.3699 21.9486 11.529 21.85C11.6732 21.7599 11.7919 21.6346 11.8742 21.4859C11.9564 21.3372 11.9994 21.1699 11.999 21V11.89C12.9607 10.905 13.6105 9.65835 13.8674 8.30595C14.1242 6.95355 13.9765 5.55544 13.4429 4.28651C12.9093 3.01757 12.0134 1.93413 10.8673 1.17171C9.72111 0.409289 8.37561 0.00172601 6.99904 0ZM9.99904 19.38L7.44904 18.11C7.30946 18.0397 7.15534 18.003 6.99904 18.003C6.84274 18.003 6.68862 18.0397 6.54904 18.11L3.99904 19.38V13.32C4.63196 13.6189 5.30612 13.8211 5.99904 13.92V15C5.99904 15.2652 6.1044 15.5196 6.29193 15.7071C6.47947 15.8946 6.73382 16 6.99904 16C7.26426 16 7.51861 15.8946 7.70615 15.7071C7.89368 15.5196 7.99904 15.2652 7.99904 15V13.92C8.69195 13.8211 9.36612 13.6189 9.99904 13.32V19.38ZM6.99904 12C6.01013 12 5.04343 11.7068 4.22119 11.1573C3.39894 10.6079 2.75808 9.82705 2.37964 8.91342C2.0012 7.99979 1.90219 6.99445 2.09511 6.02455C2.28804 5.05464 2.76424 4.16373 3.46351 3.46447C4.16277 2.7652 5.05368 2.289 6.02359 2.09607C6.99349 1.90315 7.99883 2.00216 8.91246 2.3806C9.82609 2.75904 10.607 3.3999 11.1564 4.22215C11.7058 5.04439 11.999 6.01109 11.999 7C11.999 8.32608 11.4723 9.59785 10.5346 10.5355C9.59689 11.4732 8.32512 12 6.99904 12Z'
							fill='#D4AF37'
						/>
					</svg>
				&nbsp; &nbsp;
				<small className='pl-2' style={{}}>
					{`T${tier}`} &nbsp;
					<span
						style={{fontSize: '.8rem',}}
						onClick={() =>
							setCurrentTab({
								component: (
									<UpgradeKYC setCurrentTab={setCurrentTab} />
								),
							})
						}
						className='text-orange px-1 pointer'>
						Upgrade
					</span>

					your account to do larger transactions
				</small>
			</Box>
		</div>
	);
}
