import React from "react";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import PinInput from "react-pin-input";
import Loader from "../../../components/Loader";

const ConfirmTransfer = ({ handleChange, formData, confirm, checked, handleChecked, bank = 'Bank', loading, cancel }) => (
    <div className="text-center">
        <div>
            <div className="text-black fw-bold fs-20">Input your 4 digit pin</div>
            <small>Please input your 4digit pin to transfer fund</small>
        </div>
        {
            loading
            ? (<Loader />)
                : (
                    <div>
                        <div className="my-4 px-5 mx-5">
                            {
                                formData.recipient
                                && (
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="small text-muted">Recipient</div>
                                        <div className="fw-bold text-black">{formData.recipient}</div>
                                    </div>
                                )
                            }

                            <div className="d-flex justify-content-between my-2">
                                <div className="small text-muted">Amount</div>
                                <div className="fw-bold text-black">{formData.amount?.toLocaleString()}</div>
                            </div>
                            {
                                formData.bank
                                && (
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="small text-muted">Beneficiary {bank}</div>
                                        <div className="fw-bold text-black">{formData.bank}</div>
                                    </div>
                                )
                            }


                            <div className="d-flex justify-content-between my-2">
                                <div className="small text-muted">From</div>
                                <div className="fw-bold text-black">{formData.from}</div>
                            </div>

                            {
                                formData.fee
                                && (
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="small text-muted">Transaction fee</div>
                                        <div className="fw-bold text-black">
                                            <span>{formData.fee?.currencyCode}</span>
                                            <span>{formData.fee?.fixedAmount}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="my-3">
                            <PinInput
                                length={4}
                                initialValue=""
                                secret
                                onChange={(value, index) => {}}
                                type="numeric"
                                style={{padding: '10px'}}
                                inputStyle={{borderColor: 'blue', borderLeft: "none", borderRight: "none", borderTop: "none"}}
                                inputFocusStyle={{borderColor: 'green'}}
                                onComplete={(value, index) => {handleChange({target: {name: 'pin', value}})}}
                            />
                            {/*<NumberFormat value={formData.pin}  onChange={handleChange} name="pin" type="password" variant="standard" customInput={Input}  prefix='&#8358;' format="# # # #" allowEmptyFormatting mask="______ "  />*/}
                        </div>

                        {
                            (typeof handleChecked === 'function') && (
                                <div className="d-flex justify-between px-5 mx-5">
                                    <div className="text-black">
                                        Save as Beneficiary
                                    </div>
                                    <Switch
                                        checked={checked}
                                        onChange={handleChecked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                            )
                        }
                        {
                            typeof cancel === "function"
                            ? (
                                    <div className="d-flex justify-content-between">
                                        <Button className="bg-reddish text-orange w-100 py-3 fw-bold mt-3" onClick={cancel}>Cancel</Button>
                                        <Button className="bg-orange text-white w-100 py-3 fw-bold mt-3" onClick={confirm}>Confirm</Button>
                                    </div>
                                )
                                : (<Button className="bg-orange text-white w-100 py-3 fw-bold mt-3" onClick={confirm}>Confirm</Button>)
                        }
                    </div>
                )
        }

    </div>
);
export default ConfirmTransfer;
