import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import React from "react";
import CountryList from "country-list-with-dial-code-and-flag";
import { Box, Button, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import BackdropModal from "../../components/BackdropModal";
import UploadProfilePicture from "../../components/UploadProfile";
import {
  slugify,
  slugToString,
} from "../../../../../../utilities/stringOperations";
import { statusColors } from "../../../../../../utilities/dummyData";
import Loader from "../../../../../../components/Loader";
import NumberFormat from "react-currency-format";
import EllipsisLoader from "../../../../../../components/SubmitLoader/ElipseLoader";
import VerifyBvn from "./bvn/VerifyBvn";
import FilePreview from "react-file-preview-latest";

export const tier1Limits = (previousTier, nextTier, kycStatus, tier, limits, loading) =>
  loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      <div>
        Daily Number of Transfers(
      {limits?.maxNumberOfTxnAllowed?.toLocaleString() || ""}){" "}      
      </div>
      <div>
        Daily limit (&#8358;{" "}
        <span>{limits?.maximumLimit?.toLocaleString() || ""}</span>)
      </div>
      <div>
        Single Transfer limit(&#8358;{" "}
        <span>{limits?.singleTransferLimit?.toLocaleString() || ""}</span>)
      </div>
      {/*<div>
        Daily Number of Transfers - Internal (
        {limits?.maxNumberOfTxnAllowed?.toLocaleString() || ""}){" "}
      </div>
      <div>
        Daily limit - Internal Transfer - Within Wayabank: (&#8358;{" "}
        <span>{limits?.maximumLimit?.toLocaleString() || ""}</span>)
      </div>*/}
      <div className="row">
        <div className="d-lg-flex justify-between">
          {/*<div>
            Single Transfer limit- Internal Transfer - Within Wayabank :
            (&#8358;{" "}
            <span>{limits?.singleTransferLimit?.toLocaleString() || ""}</span>)
          </div>*/}
          <div>{' '}</div>
          <div>
            <div className="d-flex mt-2 mt-lg-0">
              {tier > 1 && (
                <button className="btn border" onClick={previousTier}>
                  <div className="d-flex text-muted">
                    <KeyboardBackspaceOutlinedIcon />
                    <span className="small">Previous</span>
                  </div>
                </button>
              )}
              {tier !== 1 && tier !== 4 && (
                <div className="mx-2 text-muted small mt-2"> {tier}</div>
              )}
              {tier < 4 && (
                <button className="btn border" onClick={nextTier}>
                  <div className="d-flex text-muted">
                    <span className="small">Next</span>
                    <ArrowRightAltOutlinedIcon />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

export const inputBox = (
  label,
  name,
  verify,
  handleChange,
  formData,
  canVerify,
  placeholder,
  numberInput,
  loadingBvn,
  openBvn,
  setOpenBvn,
  bvn
) => (
  <div className="d-lg-flex justify-content-between" key={name}>
    <Box flex={1} className="mb-2 mb-md-0">
      <small>{label}</small>
    </Box>
    <Box
      flex={3}
      className="d-lg-flex"
      style={{
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "fit-content",
      }}
    >
      <div className="w-100">      
        {numberInput ? (
          <NumberFormat
            style={{ width: "92%" }}
            className="input-container p-2"
            onChange={handleChange}
            disabled={name !== "BVN"}
            name={name}
            value={formData[name]?.reqValue || formData[name]}
            placeholder={placeholder}
            variant="outlined"
            format={
              numberInput?.isPhone
                ? "(+###) - ### - ### - ####"
                : "#### - ### - ####"
            }
            mask="_"
          />
        ) : (
          <TextField
            fullWidth
            sx={{ "& .css-hdw1oc": { display: "none" } }}
            // onChange={handleChange}
            name={name}
            disabled={name === "BVN"}
            value={name === 'BVN'?bvn:(formData[name]?.reqValue || formData[name])}
            placeholder={placeholder}
            inputProps={{
              style: { padding: 9 },
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      </div>
      {numberInput
        ? (formData[name]?.reqValue || formData[name]) &&
          canVerify && (
            <div className="align-items-center justify-content-center d-flex">
              <Button
                onClick={() => verify(name)}
                sx={{ color: "black" }}
                className="border-radius-5 ms-2 border-right border-left border-top border-bottom"
                style={{ height: "35px" }}
              >
                {loadingBvn ? (
                  <EllipsisLoader />
                ) : (
                  <div className="d-flex">
                    <ArrowRightAltOutlinedIcon />
                    <span>
                      <small>Verify</small>
                    </span>
                  </div>
                )}
              </Button>
            </div>
          )
        : formData[name]?.status || name === 'BVN' &&
          typeof verify === "function" &&
          canVerify && (
            <div className="align-items-center justify-content-center d-flex">
              <Button
                onClick={() => verify(name)}
                sx={{ color: "black" }}
                className="border-radius-5 ms-2 border-right border-left border-top border-bottom"
                style={{ height: "35px" }}
              >
                <ArrowRightAltOutlinedIcon />
                <span>
                  <small>Verify</small>
                </span>
              </Button>
            </div>
          )}
      {formData[name]?.status && (
        <div
          style={{ height: "fit-content" }}
          className={`border-radius-5 p-1 text-white d-flex bg-${
            statusColors[formData[name]?.status]
          }`}
        >
          <CheckCircleOutlinedIcon />
          <span>{formData[name]?.status?.toUpperCase()}</span>
        </div>
      )}
    </Box>
    {openBvn && <VerifyBvn
      openBvn={openBvn}
      setOpenBvn={setOpenBvn}
      bvn={formData?.BVN?.reqValue}
    />}
  </div>
);

const locateUr = (name, title, formData) =>
  formData[name]?.uploadUrl ||
  formData[slugToString(title)?.toUpperCase()]?.uploadUrl ||
  formData[slugToString(name)]?.uploadUrl;
const locateStatus = (name, title, formData) =>
  formData[name]?.status ||
  formData[slugToString(title)?.toUpperCase()]?.status ||
  formData[slugToString(name)]?.status;
const locateName = (name, title, formData) =>
  formData[name] ||
  formData[slugToString(title)?.toUpperCase()] ||
  formData[slugToString(name)];

export const uploadBox = (
  name,
  label,
  title,
  formData,
  uploadDoc,
  setOpen,
  uploading,
  handlePreview
) => (
  <div className="d-lg-flex justify-content-between ">
    <Box flex={1} className="">
      <small>{label}</small>
    </Box>
    <Box flex={3} className="d-md-flex">
      <Box flex={1} className="d-flex">
        <button
          className={
            locateName(name, title, formData)?.reqValue
              ? "dotted-box px-5 py-1 text-grey blue-hover orange-button text-white"
              : " btn px-5 py-1 text-grey blue-hover"
          }
          onClick={() => setOpen(name)}
        >
          {locateName(name, title, formData) ? (
            <div>
              {uploading[name] ? (
                <Loader />
              ) : (
                <span>
                  {locateName(name, title, formData)?.reqValue || "Change"}
                </span>
              )}
            </div>
          ) : (
            <AddIcon />
          )}
        </button>
        {!locateName(name, title, formData)?.reqValue &&
          locateName(name, title, formData) && (
            <Button
              className="dotted-box px-5 py-1 text-grey blue-hover ml-2"
              onClick={() => uploadDoc({ reqItem: name })}
            >
              <span>{upload}</span>
              <small className="pl-1">Try Again</small>
            </Button>
          )}
      </Box>
      {locateUr(name, title, formData) && (
        <Button
          onClick={() => handlePreview(locateUr(name, title, formData))}
          sx={{ color: "black" }}
          className="border-radius-5 m-2 p-1 border-right border-left border-top border-bottom d-flex"
          style={{ marginTop: "-4.5px" }}
        >
          {eye}
        </Button>
      )}
      {locateStatus(name, title, formData) && (
        <div
          className={`border-radius-5 p-1 text-white d-flex bg-${
            statusColors[
              slugify(locateStatus(name, title, formData)?.toUpperCase())
            ]
          }`}
        >
          <CheckCircleOutlinedIcon />
          <span>{locateStatus(name, title, formData)}</span>
        </div>
      )}
    </Box>
  </div>
);

const examples = {
  UTILITY_BILL: "Nepa Bill, Water Bill, LAWMA",
};
const titles = {
  UTILITY_BILL: "PROOF OF ADDRESS",
};

export const uploadModal = (onChange, setOpen, open, upload, postData, setPostData) => (
  <BackdropModal
    open={open}
    content={
      <UploadProfilePicture
        loading={false}
        examples={examples[open]}
        onChange={onChange}
        uploadProfile={() => upload(open)}
        label={slugToString(open)}
        name={open}
        title={titles[open] || slugToString(open)}
        postData={postData}
        setPostData={setPostData}
      />
    }
    handleClose={() => setOpen(false)}
  />
);

export const viewFileModal = (url, preview, setPreview) => (
  <BackdropModal
    open={preview}
    content={
      <FilePreview
        type={"url"}
        url={[url]}
        // onError={this.onError}
      />
    }
    handleClose={() => setPreview(false)}
  />
);

export const upload = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_445_22726)">
      <path
        d="M8.00065 4.99935L5.33398 7.66602L7.33398 7.66602L7.33398 13.666L8.66732 13.666L8.66732 7.66602L10.6673 7.66602L8.00065 4.99935ZM2.00065 13.666L6.00065 13.666L6.00065 12.3393L2.00065 12.3394L2.00065 2.98602L14.0007 2.98602L14.0007 12.3393L10.0007 12.3393L10.0007 13.666L14.0007 13.666C14.734 13.666 15.334 13.066 15.334 12.3327L15.334 2.99935C15.334 2.26602 14.734 1.66602 14.0007 1.66602L2.00065 1.66602C1.26732 1.66602 0.667317 2.26602 0.667317 2.99935L0.667318 12.3327C0.667318 13.066 1.26732 13.666 2.00065 13.666Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_22726">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(16 16) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const eye = (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 3C8.04249 3 8.56276 3.21071 8.94635 3.58579C9.32995 3.96086 9.54545 4.46957 9.54545 5C9.54545 5.53043 9.32995 6.03914 8.94635 6.41421C8.56276 6.78929 8.04249 7 7.5 7C6.95751 7 6.43724 6.78929 6.05365 6.41421C5.67005 6.03914 5.45455 5.53043 5.45455 5C5.45455 4.46957 5.67005 3.96086 6.05365 3.58579C6.43724 3.21071 6.95751 3 7.5 3ZM7.5 0C10.9091 0 13.8205 2.07333 15 5C13.8205 7.92667 10.9091 10 7.5 10C4.09091 10 1.17955 7.92667 0 5C1.17955 2.07333 4.09091 0 7.5 0ZM1.48636 5C2.61136 7.24 4.93636 8.66667 7.5 8.66667C10.0636 8.66667 12.3886 7.24 13.5136 5C12.3886 2.76 10.0636 1.33333 7.5 1.33333C4.93636 1.33333 2.61136 2.76 1.48636 5Z"
      fill="black"
    />
  </svg>
);

export const countryTemp = CountryList.map((item) => ({
  label: `${item.flag} ${item.dial_code}`,
  display: `${item.flag} ${item.name} (${item.code}) ${item.dial_code}`,
  ...item,
}));

const industries = [
  "aviation",
  "restaurant",
  "IT",
  "software",
  "banking",
  "bakery",
];

export const industryTemp = industries.map((item) => ({
  label: item,
  display: item,
}));
