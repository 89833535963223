import React from 'react'

const NoResultFound = () => {
    return (
        <tr>
            <td style={{textAlign:'center', fontSize:'13px'}} colSpan="100">No Result Found</td>   
        </tr>
    )
}

export default NoResultFound
