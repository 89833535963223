import {useState} from "react";
import {apiCall} from "../../utilities/apis";
import {changePasswordUrl} from "../../plugins/urls";
import {Slide, toast} from "react-toastify";

const useResetPassword = ({ dat, success }) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    // const [changePassword, setChangePassword] = useState(false);


    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    const submitPassword = () => {
        if (formData.newPassword === formData.confirm_password) {
            const data = {...formData, ...dat };
            delete data.confirm_password;

            apiCall({
                url: changePasswordUrl,
                data,
                success: () =>{
                    success()
                },
                method: 'post',
                setLoading
            })
        }
        else {
            toast.error(
                'Passwords do not match, ensure your new password matches confirm password',
                { transition: Slide, hideProgressBar: true, autoClose: 3000 }
            );
        }

    };

    return ({
        loading, setLoading, submitPassword, formData, handleChangePassword
    })
};
export default useResetPassword;
