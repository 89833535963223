import {Slide, toast} from "react-toastify";
import axios from "axios";


export const apiCall2 = ({ data, url, method, setLoading, success, error, defaultMeta, meta, noError, token, contentType = 'multipart/form-data'  })=>{
    if (defaultMeta) {
        meta = {
            dataIndex: 'respBody',
            codeIndex: 'respCode',
            codeValue: 'Successful',
            messageIndex: 'respDesc'
        };
    }
    const config = {
        headers: {
            'Authorization': `serial ${token}`,
           'Content-Type': contentType,
           "Client-Type": "CORPORATE",
            "Client-Id": "WAYAPOS"
        },
        body: data,
        method
    };
    setLoading && setLoading(true);
    axios(url, config).then(res => {
        setLoading && setLoading(false);
        if(res.data.status === true) {
           return  success && success(res.data[meta?.dataIndex] || res.data);
        }
        if(typeof meta === 'object' && res.data[meta?.messageIndex] === meta?.codeValue) {
           return  success && success(res.data[meta?.dataIndex] || res.data);
        }
        else{
            // console.log(res.data);

            if (typeof meta === 'object') {
                const err = typeof res?.data[meta?.dataIndex] === 'string' ? res?.data[meta?.dataIndex] : res?.data[meta?.messageIndex];
                error && error(err);
                return !noError && toast.error( err || 'Request failed', {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            } else {
                error && error(res?.data?.message);
                return !noError && toast.error(res?.data?.message || 'Request failed', {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            }

        }

    }).catch(err=>{
        setLoading && setLoading(false);

            error && error(err?.response?.data?.message || err.response[meta?.messageIndex]?.error || 'Connection failed');
            setLoading && setLoading(false);
           return !noError && toast.error(err?.response?.data?.message || err.response[meta?.messageIndex]?.error || 'Connection failed', {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            });


    })
};
