import React from 'react';
// @mui
import {
  Stack,
  Box, TextField, Button
} from '@mui/material';

import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {statusColors} from "../../../../../utilities/dummyData";
import Loader from "../../../../../components/Loader";


export default function KYCCorporateFor5({ formData, handleChange, loading }) {

  return (
      <div>
          {
              loading ?
                  <Loader />
                  : (
                      <Stack spacing={3} pr={4}>

                          <div className="d-flex justify-content-between">
                              <Box flex={1} className=""><small>Website URL</small></Box>
                              <Box flex={3} className="d-flex ">
                                  <Box flex={1}>
                                      <TextField
                                          fullWidth
                                          sx={{'& .css-hdw1oc': { display: 'none'}, paddingRight: 6 }}
                                          onChange={handleChange}
                                          value={formData?.allData?.reqValue?.WEBSITE_URL}
                                          placeholder="www.customerwebsite.com"
                                          name="WEBSITE_URL"
                                          inputProps={{
                                              style: {padding: 9},
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                          }}
                                      />
                                  </Box>
                                  {/* <Button sx={{color: 'black'}} className="border-radius-5 mr-2 p-1 border-right border-left border-top border-bottom d-flex" style={{marginTop: '-4.5px'}}>
              <ArrowRightAltOutlinedIcon />
              <span><small>Verify</small></span>
            </Button> */}
                                  <Box className={`border-radius-5 p-1 text-white d-flex bg-${statusColors[formData.allData?.status]}`}>
                                      <CheckCircleOutlinedIcon />
                                      <span>{formData.allData?.status}</span>
                                  </Box>
                              </Box>
                          </div>

                          <div className="d-flex justify-content-between">
                              <Box flex={1} className=""><small>App URL</small></Box>
                              <Box flex={3} className="d-flex ">
                                  <Box flex={1}>
                                      <TextField
                                          fullWidth
                                          sx={{'& .css-hdw1oc': { display: 'none'}, paddingRight: 6 }}
                                          onChange={handleChange}
                                          value={formData?.allData?.reqValue?.APP_URL}
                                          placeholder="www.appurl.com"
                                          name="APP_URL"
                                          inputProps={{
                                              style: {padding: 9},
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                          }}
                                      />
                                  </Box>
                                  {/* <Button sx={{color: 'black'}} className="border-radius-5 mr-2 p-1 border-right border-left border-top border-bottom d-flex" style={{marginTop: '-4.5px'}}>
              <ArrowRightAltOutlinedIcon />
              <span><small>Verify</small></span>
            </Button> */}
                                  <Box className={`border-radius-5 p-1 text-white d-flex bg-${statusColors[formData.allData?.status]}`}>
                                      <CheckCircleOutlinedIcon />
                                      <span>{formData.allData?.status}</span>
                                  </Box>
                              </Box>
                          </div>

                          <div className="d-flex justify-content-between">
                              <Box flex={1} className=""><small>Facebook URL</small></Box>
                              <Box flex={3} className="d-flex ">
                                  <Box flex={1}>
                                      <TextField
                                          fullWidth
                                          sx={{'& .css-hdw1oc': { display: 'none'}, paddingRight: 6 }}
                                          onChange={handleChange}
                                          value={formData?.allData?.reqValue?.FACEBOOK_URL}
                                          placeholder="www.facebook.com/customer"
                                          name="FACEBOOK_URL"
                                          inputProps={{
                                              style: {padding: 9},
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                          }}
                                      />
                                  </Box>
                                  {/* <Button sx={{color: 'black'}} className="border-radius-5 mr-2 p-1 border-right border-left border-top border-bottom d-flex" style={{marginTop: '-4.5px'}}>
              <ArrowRightAltOutlinedIcon />
              <span><small>Verify</small></span>
            </Button> */}
                                  <Box className={`border-radius-5 p-1 text-white d-flex bg-${statusColors[formData.allData?.status]}`}>
                                      <CheckCircleOutlinedIcon />
                                      <span>{formData.allData?.status}</span>
                                  </Box>
                              </Box>
                          </div>

                          <div className="d-flex justify-content-between">
                              <Box flex={1} className=""><small>Instagram URL</small></Box>
                              <Box flex={3} className="d-flex ">
                                  <Box flex={1}>
                                      <TextField
                                          fullWidth
                                          sx={{'& .css-hdw1oc': { display: 'none'}, paddingRight: 6 }}
                                          onChange={handleChange}
                                          value={formData?.allData?.reqValue?.INSTAGRAM_URL}
                                          placeholder="www.instagram.com/customer"
                                          name="INSTAGRAM_URL"
                                          inputProps={{
                                              style: {padding: 9},
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                          }}
                                      />
                                  </Box>
                                  {/* <Button sx={{color: 'black'}} className="border-radius-5 mr-2 p-1 border-right border-left border-top border-bottom d-flex" style={{marginTop: '-4.5px'}}>
              <ArrowRightAltOutlinedIcon />
              <span><small>Verify</small></span>
            </Button> */}
                                  <Box className={`border-radius-5 p-1 text-white d-flex bg-${statusColors[formData.allData?.status]}`}>
                                      <CheckCircleOutlinedIcon />
                                      <span>{formData.allData?.status}</span>
                                  </Box>
                              </Box>
                          </div>

                          <div className="d-flex justify-content-between">
                              <Box flex={1} className=""><small>Twitter URL</small></Box>
                              <Box flex={3} className="d-flex ">
                                  <Box flex={1}>
                                      <TextField
                                          fullWidth
                                          sx={{'& .css-hdw1oc': { display: 'none'}, paddingRight: 6 }}
                                          onChange={handleChange}
                                          value={formData?.allData?.reqValue?.TWITTER_URL}
                                          placeholder="www.twitter.com/customer"
                                          name="TWITTER_URL"
                                          inputProps={{
                                              style: {padding: 9},
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                          }}
                                      />
                                  </Box>
                                  {/* <Button sx={{color: 'black'}} className="border-radius-5 mr-2 p-1 border-right border-left border-top border-bottom d-flex" style={{marginTop: '-4.5px'}}>
              <ArrowRightAltOutlinedIcon />
              <span><small>Verify</small></span>
            </Button> */}
                                  <Box className={`border-radius-5 p-1 text-white d-flex bg-${statusColors[formData.allData?.status]}`}>
                                      <CheckCircleOutlinedIcon />
                                      <span>{formData.allData?.status}</span>
                                  </Box>
                              </Box>
                          </div>


                      </Stack>
                  )
          }
      </div>

  );
}

