import { IconButton } from "@material-ui/core";
import { Box, Modal } from "@mui/material";
import { Typography } from "antd";
import axios from "axios";
import { Formik } from "formik";
import { createAdmin } from "plugins/urls";
import { Button } from "react-bootstrap";
import { createAdminInitialVal, CreateAdminSchema } from "./data";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  bgcolor: "background.paper",
  padding: 4,
  boxShadow: 24,
};

export const CreateAdmin = ({ show, onClose, formProp }) => {
  const {
    phoneNumber,
    dateOfBirth,
    gender,
    password,
    adminType,
  } = formProp || {};
  return (
    <Modal open={show}  title="Create Admin User" >
      <Box sx={{ ...style, width: 500 }}>
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Typography variant={"h4"}>{"Create New Admin User"}</Typography>
          <IconButton onClick={onClose} sx={{background:"#FF6700"}}>
            <CancelIcon/>
            </IconButton>
        </Box>
        <Formik
          initialValues={createAdminInitialVal}
          validationSchema={CreateAdminSchema}
          onSubmit={(values) => {
            const reqBody={...values,admin:true,roleName:"ROLE_APP_ADMIN"}
            axios.post(`${createAdmin}`,reqBody).then(res=>{
              console.log(res)
            })
          }}
        >
          {({ errors, touched,handleChange,handleBlur,handleSubmit }) => (
            <form
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBlur={handleBlur}>
              <div className="container-fluid ">
                <div className="row mb-3">
                  <div className="col-12 col-md-6 ">
                    <label className="fs-12 fw-400 mb-1">First Name</label>
                    <input
                      className="admin-user-inputs"
                      placeholder=""
                      type="text"
                      id={"firstName"}
                      required
                      name="firstName"
                    />
                    {errors.firstName && touched.firstName ? (
                      <p>{errors.firstName}</p>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6  ">
                    <label className="fs-12 fw-400 mb-1">Last Name</label>
                    <input
                      className="admin-user-inputs"
                      type="text"
                      name="surname"
                      required
                      
                    />
                    {errors.surname && touched.surname ? (
                      <Typography>{errors.surname}</Typography>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <label className="fs-12 fw-400 mb-1">Email Address</label>
                    <input
                      className="admin-user-inputs"
                      type="text"
                      name="email"
                      required
                    />
                     {errors.email && touched.email ? (
                      <p>{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="fs-12 fw-400 mb-1">Phone Number</label>
                    <input
                      className="admin-user-inputs"
                      type="number"
                      name="phoneNumber"
                      value={phoneNumber}
                      required
                    />
                    
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <Typography sx={{color:"red"}}>{errors.phoneNumber}</Typography>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6 ">
                    <label className="fs-12 fw-400 mb-1">Date of Birth</label>
                    <input
                      className="admin-user-inputs"
                      // placeholder="DD/MM/YYYY"
                      type="date"
                      name="dateOfBirth"
                      value={dateOfBirth}
                      required
                      
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <div className="d-flex flex-column">
                      <label className="fs-12 fw-400 mb-1">Gender</label>
                      <select
                        className="admin-user-inputs my-1 "
                        value={gender}
                        name="gender"
                        required
                        
                      >
                        <option value="">Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 ">
                    <div className="d-flex flex-column">
                      <label className="fs-12 fw-400 mb-1">Admin Type</label>
                      <select
                        className="admin-user-inputs my-1 "
                        value={adminType}
                        name="adminType"
                        required
                        
                      >
                        <option value="">Select admin Type</option>
                        <option value={"OWNER"}>{"OWNER"}</option>
                        <option value={"SUPER_ADMIN"}>{"SUPER ADMIN"}</option>
                        <option value={"ADMIN"}>{"ADMIN"}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="fs-12 fw-400 mb-1">Password</label>
                    <input
                      className="admin-user-inputs"
                      type="password"
                      name="password"
                      value={password}
                      required
                    />
                    <p className="text-danger fs-12">{}</p>
                  </div>
                </div>
              </div>
              <Box sx={{display:"flex",justifyContent:"center"}}>
              <Button type={"submit"} sx={{background:"#FF6700"}} >{"Create Admin User"}</Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
