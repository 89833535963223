import { Container } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import { supportMenu } from "../../utilities/dummyData";
import BoxTemp from "./BoxTemp";

const Support = () => {
  return (
    <Layout title="Support">
      <Container>
        <div className="text-center mt-40 pb-5">
          <h4 className="fs-24 text-darker fw-bold font-default">Support</h4>
        </div>

        <div className="row">
          {supportMenu.map((item) => (
            <div className="col-md-4 py-5">
              <BoxTemp
                icon={item.icon}
                content={item.content}
                btnText={item.btnText}
                title={item.title}
              />
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Support;
