import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import useUser from "../../../../../components/Hooks/useUser";
import LoadComponent from "../../../../../components/LoadComponent";
import EllipsisLoader from "../../../../../components/SubmitLoader/ElipseLoader";
import { getBankList, nameEnquiry } from "../../../../../plugins/urls";
import { apiCall } from "../../../../../utilities/apis";
import { changeHandler } from "../../../../../utilities/handlers";
import { sentenceCaps } from "../../../../../utilities/stringOperations";
import SelectBanks from "../../../../Dashboard/temps/widthrawal/selectBank";

const SettlementAccount = ({ back }) => {
  /*##### HOOKs #####*/
  const { user } = useUser();

  /*##### STATE #####*/
  const [formData, setFormData] = useState({ user: "waya" });
  const [wallet, setWallet] = useState(null);
  const [banks, setBanks] = useState([]);
  const [retry, setRetry] = useState({});
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [loadingName, setLoadingName] = useState(false);
  const [loading, setLoading] = useState(false);

  /*##### EFFECTS #####*/
  useEffect(() => {
    if (isEmpty(formData?.bank)) getBanks();
    else setRetry((cur) => ({ ...cur, bank: false }));

    return false;
  }, [formData]);

  useEffect(() => {
    if (formData.recipient?.length === 10) confirmAccount();
    return false;
  }, [formData?.recipient]);

  //get lists of banks
  const getBanks = useCallback(() => {
    setRetry((cur) => ({ ...cur, bank: false }));
    apiCall({
      url: getBankList,
      setLoading: setLoadingBanks,
      success: getBanksSuccess,
      error: () => setRetry((cur) => ({ ...cur, bank: true })),
      method: "get",
    });
  }, []);
  //confirm bank account
  const confirmAccount = () => {
    setRetry((cur) => ({ ...cur, accountName: false }));
    apiCall({
      url: `${nameEnquiry}${formData.recipient}`,
      setLoading: setLoadingName,
      data: {
        bankCode: formData.bank?.code,
        accountNumber: formData.recipient,
      },
      error: (e) => setRetry((cur) => ({ ...cur, accountName: true })),
      success: nameSuccess,
      method: "post",
    });
  };

  /*##### LOGIC #####*/

  /*change handler*/
  const handleChange = (e) => changeHandler({ e, setFormData });

  //bank list success callback
  const getBanksSuccess = (data) => {
    setRetry((cur) => ({ ...cur, bank: false }));
    setBanks(data?.data?.bankList);
    setFormData({ ...formData, bank: data?.data?.bankList[0] });
  };

  //name enquiry success callback
  const nameSuccess = (data) => {
    if (data.data !== "") {
      setFormData({ ...formData, accountName: data?.data });
    } else {
      setRetry((cur) => ({ ...cur, accountName: true }));
    }
  };

  /*##### RENDER #####*/
  return (
    <Paper elevation={3}>
      <Stack spacing={3} className="py-3 ">
        <div className="py-3 d-flex text-danger">
          <IconButton onClick={back}>
            <IoIosArrowRoundBack className="text-danger" />
          </IconButton>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <h2> Set Default Settlement Account</h2>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 280 }}>
            <InputLabel id="demo-simple-select-standard-label">
              {
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Select Default Bank Settlement Account
                </p>
              }
            </InputLabel>
            <Select
              name="user"
              className="w-100"
              value={formData?.user}
              onChange={handleChange}
              label="Select default bank"
            >
              <MenuItem value="">
                <em>Select Default Bank Settlement Account</em>
              </MenuItem>
              <MenuItem value="waya">Default Waya bank account</MenuItem>
              <MenuItem value="other">Other banks</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formData?.user === "other" && (
          <div>
            <div className="py-3 d-flex justify-content-center">
              <div>
                <LoadComponent
                  loading={loadingBanks}
                  component={
                    <SelectBanks
                      readOnly={!isEmpty(formData.beneficiary)}
                      options={banks}
                      onChange={handleChange}
                      value={formData?.bank}
                      name="bank"
                      label=" Bank Name"
                      valueIndex="name"
                      optionIndex="name"
                      helperText="Select Bank"
                    />
                  }
                />
                <div className="d-flex justify-content-center">
                  {retry?.bank && (
                    <Button onClick={getBanks} color="secondary">
                      Retry Bank Enquiry
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="py-3  d-flex justify-content-center">
              <div className="py-3">
                <TextField
                  disabled={formData.recipient?.length === 10}
                  value={formData.recipient}
                  onChange={handleChange}
                  name="recipient"
                  placeholder="Account Number"
                  variant="standard"
                  helperText="Account number of recipient"
                />

                <div className="d-flex justify-content-end">
                  {retry?.accountName && (
                    <Button onClick={confirmAccount} color="secondary">
                      Retry Name Enquiry
                    </Button>
                  )}
                  {formData?.recipient && (
                    <Button
                      onClick={() =>
                        setFormData((prevState) => ({
                          ...prevState,
                          recipient: "",
                          accountName: "",
                        }))
                      }
                      color="secondary"
                    >
                      Clear Input
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="py-3  d-flex justify-content-center">
              <div>
                <LoadComponent
                  loading={loadingName}
                  component={
                    <TextField
                      value={sentenceCaps(formData.accountName) || ""}
                      readOnly={true}
                      name="accountName"
                      placeholder="Account Name"
                      variant="standard"
                      helperText="Account name of recipient"
                    />
                  }
                />
              </div>
            </div>
          </div>
        )}
        {formData?.user === "waya" && (
          <div>
            {loading ? (
              <EllipsisLoader />
            ) : (
              <div className="text-orange d-flex justify-content-center h2">
                {wallet}
              </div>
            )}
          </div>
        )}

        <div className="d-flex py-3 justify-content-center">
          <Button
            disabled={
              isEmpty(formData?.bank) ||
              isEmpty(formData?.accountName) ||
              isEmpty(formData?.recipient)
            }
            className="bg-orange text-white py-3 w-25 fw-bold mt-5"
            onClick={() => console.log(formData)}
          >
            Submit
          </Button>
        </div>
      </Stack>
    </Paper>
  );
};
export default SettlementAccount;
