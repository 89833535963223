import { useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Col, Row } from "react-bootstrap";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { AddTerminalContext } from "../../pages/Terminals/AddTerminal";
import "../DashboardComponent/styles.scss";
import EllipsisLoader from "../SubmitLoader/ElipseLoader";

import { ReactComponent as Lock } from "assets/icons/lock.svg";
import { CustomIcon } from "utilities/CustomIcon";

const Payment = ({ setShowTopUp }) => {
  const { onChangeStep, wallet, loadingWal, total } =
    useContext(AddTerminalContext);
  // const [wallet, setwallet] = useState({});
  /*   const walletDetail = () => {
    setloadingWal(true);
    axios
      .get(walletDetails)
      .then((res) => {
        setwallet(res.data.respBody);
      })
      .finally(() => {
        setloadingWal(false);
      });
  };
  useEffect(() => {
    walletDetail();
  }, []); */
  const { activeBalance, bankDetail } = wallet;
  return (
    <div className="font-default address">
      <h4
        className="text-darker fs-14 fw-700 cursor-pointer"
        onClick={() => onChangeStep("select", "Continue")}
      >
        <span className="mr-15">
          <Back />
        </span>
        Back
      </h4>
      <h2 className="text-grey fs-18 fw-700 mt-20">Purchase WayaPOS</h2>

      <div className="mt-40">
        <h4 className="text-darker fs-16 fw-700">
          {" "}
          How would you like to pay?
        </h4>
        <h6 className="text-default fs-12">
          Please select a preferred payment option
        </h6>
      </div>

      <Row className="mt-40">
        <Col lg={4}>
          <div className="bg-white payment-option d-flex justify-content-between cursor-pointer border-green">
            <div className="d-flex justify-content-between ">
              <div className="currency-green"> ₦ </div>
              <div className="mt-02 ml-22">
                <h4 className="text-darker fs-14 fw-700">Make full payment</h4>
                <h4 className="text-grey fs-12">
                  Make payment for this device in full
                </h4>
              </div>
            </div>

            <div>
              <Col className="fs-2 text-orange">
                <IoCheckmarkCircleOutline />
              </Col>
            </div>
          </div>
        </Col>
      </Row>
      <ScrollAnimation animateIn="animate__slideInLeft">
        <Row>
          {!loadingWal && (
            <div className="mt-40">
              <h6 className="text-default fs-12">
                {`The total amount of N${total} will be debited from your wallet`}
              </h6>
            </div>
          )}

          <Row className="mb-15">
            {loadingWal ? (
              <EllipsisLoader />
            ) : (
              /* wallets.map((wal) => ( */
              <Col lg={4}>
                <div
                  className={`${"border-green"} bg-white payment-option d-flex justify-content-between pointer`}
                  tabIndex={1}
                  role="button"
                  /* onClick={() => setWallet(wal)} */
                >
                  <div className="d-flex justify-content-between ">
                    <div className="currency-grey">
                      {" "}
                      <Home />{" "}
                    </div>
                    <div className="mt-02 ml-22">
                      <h4 className="text-darker fs-14 fw-700">
                        {"My WAYAPOS Wallet"}
                      </h4>
                      <div className="d-flex justify-between">
                        <h4 className="text-darker fs-16 fw-900 mt-02">
                          <span className="fw-100">₦</span> {activeBalance}
                        </h4>
                        <Button
                          className="btn-top-up"
                          onClick={() =>
                            setShowTopUp((s) => ({
                              ...s,
                              show: true,
                              frame: "topUp",
                            }))
                          }
                        >
                          <CustomIcon
                            IconComp={Lock}
                            style={{ marginRight: "4px" }}
                          />
                          {"Top Up"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              /*  )) */
            )}
          </Row>
        </Row>
      </ScrollAnimation>
    </div>
  );
};

export default Payment;
