import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import useUser from "../../../components/Hooks/useUser";
import Loader from "../../../components/Loader";
import TeamTable from "../../../components/Tables/TeamTable";
import { getAllInvites } from "../../../plugins/urls";
import { apiCall } from "../../../utilities/apis";
import { CreateAdmin } from "./manageUsers/component/CreateAdmin";
import UpdateAccount from "./updateAccount/UpdateAccount";

const TeamMembers = ({ setCurrentTab }) => {
  const { user } = useUser();

  const [state, setState] = useState({
    roles: [],
    role: "",
    createrole: "",
    invitees: {},
  });
  const [showCreateAdmin, setShowCreateAdmin] = useState(false);
  const { roles, invitees } = state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // get all invitees.
  const getAllInvite = useCallback(() => {
    apiCall({
      url: `${getAllInvites}${user.id}`,
      setLoading,
      success: (res) =>
        setState((prevState) => ({
          ...prevState,
          invitees: res.data,
        })),
    });
  }, [invitees]);

  const goBackTo = useCallback((page) => {
    setCurrentTab(page);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="my-3 d-flex justify-content-between">
            <button
              onClick={() => goBackTo({ component: <UpdateAccount /> })}
              className="orange-button"
            >
              <BiArrowBack /> Back
            </button>

            <div>
              <div className="d-flex justify-content-between px-2">
                <Button
                  style={{
                    background: "#FF6700",
                    color: "#fff",
                    marginRight: 8,
                  }}
                  onClick={() => navigate("/team-members/unit")}
                >
                  <span className="mx-1 fs-12">Manage Unit</span>
                </Button>
                <Button
                  style={{ background: "#FF6700", color: "#fff" }}
                  onClick={() => setShowCreateAdmin(true)}
                >
                  <span className="mx-1 fs-12">Create Admin User</span>
                </Button>
              </div>
            </div>
          </div>
          <Paper className="p-4">
            <div className="">
              <TeamTable roles={roles} getAllInvite={getAllInvite} />
            </div>
            <CreateAdmin
              show={showCreateAdmin}
              onClose={() => setShowCreateAdmin(false)}
            />
          </Paper>
        </div>
      )}
    </Container>
  );
};

export default TeamMembers;
