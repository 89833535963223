import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import WayaPos from "../../components/TransactionComponents/WayaPos";
import { ReactComponent as Arrow } from "../../assets/icons/arrows.svg";
import WayaAgency from "../../components/TransactionComponents/WayaAgency";
// import { apiCall } from "../../utilities/apis";
// import { searchTransactionByDate } from "../../plugins/urls";
import { useLocation, useNavigate } from "react-router-dom";
import FadeMenu from "pages/Settings/pages/updateAccount/components/FadeMenu";
import { apiCall } from "utilities/apis";
import { allTransactions, revenueStats, searchTransactionByParam, agStats, getAggregators, getAggCommission } from "plugins/urls";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import EllipsisLoader from "components/SubmitLoader/ElipseLoader";
import Cards from "components/Cards/Cards";
import TransactionCard from "components/Cards/TransactionCard";
import useSimpleTable from "components/Hooks/useSimpleTable";
import { aggCommissionTableCols, aggregatorFilters, aggregatorTableCols } from "utilities/dummyData";
import { AiFillEye } from "react-icons/ai";
import SimpleSearchBox from "components/UI/SimpleSearchBox";
import SimpleDateFilter from "components/UI/DateFilter";
import { camelToString, sentenceCaps } from "utilities/stringOperations";
import { IoFilterOutline } from "react-icons/io5";

const AgCommissionInner = () => {
  const active = useLocation()?.state?.active;
  const navigate = useNavigate()
  const [menuEl, setMenuEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [loadingStats, setLoadingStats] = useState(null);
  const [state, setState] = useState({
    transactions: [],
    refunded: "",
    failed: "",
    abandoned: "",
    successful: "",
    gross_revenue: "",
    net_revenue: "",
    tab: "transactions",
  });

  const { tableData, simpleTable, exportButton, searchPane } = useSimpleTable({
    setLoading,
    url: getAggCommission,
    method: "get",
    includeMeta: true,
    filters: aggregatorFilters,
    filterUrl: searchTransactionByParam,
    //  filterCallMethod: "meth1",
    showSearchIcon: true,
    csvName: "Aggregators_",
    filterMethod: "get",
    dateFilterMenuName: "date",
    cols: 
      aggCommissionTableCols(
        <AiFillEye size={20} color="#FF6700" />)
    ,
    startCb: () => {
      getAgStats();
      getCustomerAggregator();
    },
  });

  const getAgStats = () =>
    apiCall({
      url: agStats,
      method: "get",
      noError: true,
      defaultMeta: true,
      setLoading: setLoadingStats,
      success: (res) => {
        if (res.status) {
          const {
            countFail,
            countSuccess,
            totalFail,
            totalReferred,
            totalRevenueGenerated,
            totalSuccess,
          } = res?.data;
          setState((state) => ({
            ...state,
            countFail,
            countSuccess,
            totalFail,
            totalReferred,
            totalRevenueGenerated,
            totalSuccess,
          }));
        }
      },
    });
  const getCustomerAggregator = () =>
    apiCall({
      url: getAggCommission,
      method: "get",
      noError: true,
      defaultMeta: true,
      success: (res) => {
        if (res) {
          const { content } = res.data;
          setState((state) => ({
            ...state,
            aggreators: content,
          }));
        }
      },
    });


  const { tab } = state;

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <>
      <FadeMenu
        temp={
          <div className="p-3">
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                Download
              </Button>
            </div>
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                <a>Email</a>
              </Button>
            </div>
          </div>
        }
        open={Boolean(menuEl)}
        handleClose={() => setMenuEl(null)}
        anchorEl={menuEl}
      />

      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          {/* <div>{searchPane}</div> */}
        <div />

          {tableData?.content?.length > 0 && exportButton}
        </div>
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
           <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Onboarded Agents"
                value={state?.totalReferred ?? 0}
                color="text-orange"
                textColor="text-darker"
                noCurrency
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Transaction Count"
                value={state?.countFail + state?.countSuccess ?? 0}
                color="text-orange"
                textColor="text-darker"
                noCurrency
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Commission Generated"
                value={state.totalRevenueGenerated ?? 0}
                color="text-orange"
                textColor="text-darker"
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Pending Commission"
                value={state.totalFail ?? 0}
                color="text-white"
                textColor="text-white"
                bg="bg-orange"
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Settled Commission"
                value={state.totalSuccess ?? 0}
                color="text-white"
                textColor="text-white"
                bg="bg-orange"
              />
            </Col>
          </Row>
        )}

        <div className="mt-5">
          {loading ? (
            <div className="align-items-center d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="success" />
            </div>
          ) : (
            <Container fluid>{simpleTable}</Container>
          )}
        </div>
      </Container>
    </>
  );
};

export default AgCommissionInner;
