import React, { useEffect, useState } from "react";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import SubmitLoader from "../../components/SubmitLoader/SubmitLoader";
import { useRef } from "react";
import axios from "../../plugins/axios";
import { generateOtp, resend, verify, verifyEmail } from "../../plugins/urls";
import { toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../utilities/apis";
import PinInput from "react-pin-input";
import { Button } from "@mui/material";
import { changeHandler } from "../../utilities/handlers";
import { formatDonation2 } from "../../utilities/stringOperations";
import Loader from "../../components/Loader";

const VerifyOtp = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    submit: false,
    verified: false,
  });
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const [otp, setOtp] = useState("");
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState("otp");
  const [loading, setLoading] = useState(false);

  const { submit, verified } = state;

  useEffect(() => {
    let reqBody = {
      otp: formatDonation2(formData?.otp),
      phoneOrEmail: props.phoneOrEmail,
    };
    formatDonation2(formData?.otp)?.length === 6 &&
      apiCall({
        url: verifyEmail,
        method: "post",
        data: reqBody,
        setLoading,
        success: (data) => {
          setStep("success");
          toast.success(
            `Account and Email Verified, You'll now be redirected to login`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
              onClose: () => {
                props?.redirectUrl && navigate(props?.redirectUrl);
              },
            }
          );
        },
      });
  }, [formData]);

  const onChange = (e) => changeHandler({ e, setFormData });

  const handleChange = (ref, val) => {
    if (val === "") {
      ref1.current.value = "";
      ref2.current.value = "";
      ref3.current.value = "";
      ref4.current.value = "";
      ref5.current.value = "";
      ref6.current.value = "";
      setOtp("");
    } else {
      setOtp(`${otp}${val}`);
      ref.current.focus();
    }
  };

  const sendOTP = () =>
    apiCall({
      url: `${generateOtp}${props.phoneOrEmail}`,
      method: "get",
      success: (data) => setStep("email"),
    });

  const handleSubmit = (val) => {
    if (`${otp}${val}`.length > 5) {
      setState((state) => ({
        ...state,
        submit: true,
      }));
      let reqBody = {
        otp: `${otp}${val}`,
        phoneOrEmail: props.phoneOrEmail,
      };
      axios({
        method: "post",
        url: `${verify}`,
        data: reqBody,
      })
        .then((res) => {
          if (res.data.status === true) {
            setState((state) => ({
              ...state,
              submit: false,
              verified: true,
            }));
            sendOTP();
          }
        })
        .catch((err) => {
          setOtp("");
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    } else {
      setOtp("");
      toast.error(`Pls, check fill any missing field`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
    ref1.current.value = "";
    ref2.current.value = "";
    ref3.current.value = "";
    ref4.current.value = "";
    ref5.current.value = "";
    ref6.current.value = "";
  };
  const onResend = () => {
    axios({
      method: "get",
      url: `${resend}${props.phoneOrEmail}`,
    })
      .then((res) => {
        toast.success(
          `OTP Sent, kindly check the email or phone number provided`,
          { transition: Slide, hideProgressBar: true, autoClose: 3000 }
        );
      })
      .catch((err) => {
        // const err = JSON.parse(e)
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        // console.log(err.response.data.message)
      });
  };
  return (
    <AuthComponent>
      <AuthContainer>
        {step === "otp" ? (
          <div>
            <div className="login-header">
              <h4 className="fs-24 fw-500 login-text">Verify Your Account</h4>

              <h4 className="fs-16 fw-400 text-default mt-20">
                Please Input the OTP sent to your mobile number.
              </h4>
            </div>

            <div className="mt-40 full-width">
              <form>
                <div className="inputsHolder">
                  <div className=" d-flex justify-content-between full-width text-center">
                    <input
                      type="number"
                      className="single"
                      ref={ref1}
                      onKeyUp={(e) => handleChange(ref2, e.target.value)}
                    />
                    <input
                      type="number"
                      className="single"
                      ref={ref2}
                      onKeyUp={(e) => handleChange(ref3, e.target.value)}
                    />
                    <input
                      type="number"
                      className="single"
                      ref={ref3}
                      onKeyUp={(e) => handleChange(ref4, e.target.value)}
                    />
                    <input
                      type="number"
                      className="single"
                      ref={ref4}
                      onKeyUp={(e) => handleChange(ref5, e.target.value)}
                    />
                    <input
                      type="number"
                      className="single"
                      ref={ref5}
                      onKeyUp={(e) => handleChange(ref6, e.target.value)}
                    />
                    <input
                      type="number"
                      className="single"
                      ref={ref6}
                      onKeyUp={(e) => {
                        ref6.current.blur();
                        // setOtp(`${otp}${e.target.value}`)
                        handleSubmit(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <button
                  className="mt-40 orange-button full-width"
                  disabled={!verified}
                  onClick={() => navigate("/login")}
                >
                  {submit ? <SubmitLoader className="left-43" /> : "Log in"}
                </button>

                <div className="text-center mt-20">
                  <h4 className="mt-20 fs-16 text-orange fw-400">
                    Did not get OTP?{" "}
                    <span className="fw-700 cursor-pointer" onClick={onResend}>
                      Resend
                    </span>
                  </h4>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {loading ? (
              <Loader />
            ) : (
              <div className="my-3">
                <div className="my-5">
                  <div className="text-black fs-18 text-center px-2">
                    Enter the new OTP sent to your registered email to verify
                    your Email
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {}}
                      type="numeric"
                      style={{ padding: "10px" }}
                      inputStyle={{
                        borderColor: "blue",
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                      }}
                      inputFocusStyle={{ borderColor: "green" }}
                      onComplete={(value, index) => {
                        onChange({ target: { name: "otp", value } });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <p className="pt-3">You did not receive the OTP?</p>
                    <div className="d-flex justify-content-center">
                      <Button onClick={sendOTP}>Try Again</Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </AuthContainer>
    </AuthComponent>
  );
};

export default VerifyOtp;
