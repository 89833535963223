import React, { useState, useEffect } from "react";
import { Alert, Grid, Box, Container, Typography } from "@mui/material";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { copyText } from "../../../../../utilities/stringOperations";
import InviteesTable from "../components/InviteesTable";
import axios from "axios";
import { toast, Slide } from "react-toastify";
import {
  allReferrals,
  getReferralEarning,
  userReferralCode,
} from "../../../../../plugins/urls";
import InviteBySocials from "../components/InviteBySocials";
import useUser from "../../../../../components/Hooks/useUser";

InviteFriend.propTypes = {
  setCurrentTab: PropTypes.func,
};

export default function InviteFriend({ setCurrentTab, invitees }) {
  const { user } = useUser();

  const [invite, setInvite] = useState(false);
  const [referralData, setReferralData] = useState({});
  const [referralEarning, setReferralEarning] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isCopied, setIsCopied] = useState(null);
  const [share, setShare] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const colors = {
    pending: "danger",
    completed: "success",
  };

  const copySuccess = ({ copied, str }) => {
    if (copied === str) {
      setIsCopied(true);
    } else {
      setIsCopied(false);
    }
    setTimeout(() => setIsCopied(null), 3000);
  };

  useEffect(() => {
    // getUserState();
    getReferralCode();
    getReferrals();
    getTotalReferralEarning();
  }, []);

  // get user details.
  const getUserState = async () => {
    await axios({
      method: "get",
      url: `https://services.wayapos.ng/role-service/api/v1/user/${Number(
        user.id
      )}`,
    })
      .then((res) => {
        if (res.status) {
          setUserDetails(res.data);
        }
      })
      .catch((err) => {
        // toast.error(`${err.response.data.message}`, {
        // 	transition: Slide,
        // 	hideProgressBar: true,
        // 	autoClose: 3000,
        // });
      });
  };

  // get referral code.
  const getReferralCode = async () => {
    await axios({
      method: "get",
      url: `${userReferralCode}/${Number(user.id)}`,
    })
      .then((res) => {
        if (res.status) {
          setReferralCode(res.data.data.referralCode);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  // get referrals.
  const getReferrals = async () => {
    await axios({
      method: "get",
      url: `${allReferrals}/${referralCode}`,
    })
      .then((res) => {
        if (res.status) {
          setReferralData(res.data.data.referredUsers);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  // get total referral earning.
  const getTotalReferralEarning = async () => {
    await axios({
      method: "get",
      url: `${getReferralEarning}/${user.id}`,
    })
      .then((res) => {
        if (res.status) {
          setReferralEarning(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  return (
    <Container>
      <div className="row mb-4">
        <div className="col-7 fw-bold fs-14 text-center w-100">
          Invite Friends
        </div>
      </div>

      <Box className="border bg-white p-4">
        <Button
          onClick={() => setCurrentTab({ component: null })}
          style={{
            width: "13%",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ color: "black" }}
          >
            <KeyboardBackspaceOutlinedIcon />
            <small className="ml-1">Back</small>
          </div>
        </Button>

        <div className="row mt-4">
          <div className="col-md-7">
            <div className="col-md-8 w-100">
              <h5 className="fs-16 text-capitalize">
                Invite your friends and Earn
              </h5>
              <p className="pt-2 fs-13 lh-base w-75">
                Invite your friends and earn for yourself when they complete
                registration and start performing transactions.
              </p>
            </div>
          </div>

          <div className="col-md-5">
            <Box className="border border-secondary px-3 py-2">
              <div className="row">
                {isCopied !== null && (
                  <Alert severity={isCopied ? "success" : "error"}>
                    {isCopied
                      ? `${referralCode} Copied to clipboard`
                      : "Failed to copy"}
                  </Alert>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center w-100">
                <Box
                  className=" pointer"
                  style={{ width: "38%", height: "30px" }}
                >
                  <p className="mx-auto fs-12">
                    <small>Refferal Code</small>
                  </p>
                </Box>

                <Box
                  className="pointer d-flex justify-content-between"
                  style={{ width: "52%", height: "30px" }}
                  onClick={() =>
                    copyText({
                      str: referralCode,
                      callback: copySuccess,
                    })
                  }
                >
                  <p className="text-dark fs-13">
                    {referralCode} &nbsp;
                    <small className="error">
                      <FileCopyOutlinedIcon color="error" className="fs-14" />
                    </small>
                  </p>
                </Box>
              </div>
            </Box>

            <Box className="border mt-3 border-secondary px-3 py-2">
              <div className="d-flex justify-content-between">
                <Box>
                  <p className="mb-1 fs-12 fw-semibold">
                    <small>Total Earning</small>
                  </p>
                  <h6 className="fs-14">{`N ${referralEarning}.00` || 0.0} </h6>
                </Box>

                <Box>
                  <p className="mb-1 fs-12 fw-semibold">
                    <small>Total Signups</small>
                  </p>
                  <h6 className="fs-14">
                    {referralData?.referredUsers?.length || 0}
                  </h6>
                </Box>
              </div>
            </Box>
          </div>
        </div>

        <Box mt={5} textAlign="right" className="pointer mt-1">
          <Button
            className="text-secondary"
            style={{
              textUnderlineOffset: "5px",
              fontSize: ".6rem",
              backgroundColor: "transparent",
              border: "none",
              textDecoration: "underline",
            }}
            color="error"
            onClick={() =>
              setCurrentTab({
                component: (
                  <div>
                    <Box className="d-flex justify-content-between">
                      <Button
                        onClick={() =>
                          setCurrentTab({
                            component: (
                              <InviteFriend setCurrentTab={setCurrentTab} />
                            ),
                          })
                        }
                      >
                        <div
                          className="d-flex justify-content-between align-items-center w-100"
                          style={{ color: "black" }}
                        >
                          <KeyboardBackspaceOutlinedIcon />
                          <small className="ml-1">Back</small>
                        </div>
                      </Button>

                      <Box>
                        <Typography
                          className="text-uppercase"
                          style={{
                            fontSize: ".8rem",
                            letterSpacing: ".7px",
                          }}
                        >
                          Total Signup:{" "}
                          {referralData?.referredUsers?.length || 0}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <InviteesTable
                        status="success"
                        // data={invitees || []}
                        data={[]}
                      />
                    </Box>
                  </div>
                ),
              })
            }
          >
            View Details
          </Button>
        </Box>

        <Button
          type="submit"
          size="md"
          onClick={() => setShare(true)}
          className="mx-auto rounded-2 text-uppercase"
          style={{
            display: "block",
            width: "45%",
            color: "#fff",
            backgroundColor: "#FF6700",
            marginTop: "150px",
            border: "0.5px solid #FF6700",
            fontSize: ".7rem",
            letterSpacing: "1px",
            fontWeight: "600",
            padding: "10px",
          }}
        >
          share
        </Button>
      </Box>

      <InviteBySocials show={share} onClose={() => setShare(false)} />
    </Container>
  );
}
