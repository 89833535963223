import { Button, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import UpdateProfileForm from "pages/Settings/pages/updateAccount/components/UpdateProfileForm";
import UpdateProfileModal from "pages/Settings/pages/updateAccount/components/UpdateProfileModal";
import { updateAgentProfile } from "plugins/urls";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { updateProfile } from "utilities/apis";
import ExploreCards from "../../components/GettingStarted/ExploreCards";
import QuickLinks from "../../components/GettingStarted/QuickLinks";
import useUser from "../../components/Hooks/useUser";
import Layout from "../../components/Layout/Layout";

const Start = () => {
  const [show, setShow] = useState(false);

  const { user } = useUser(true);
  const [authUser, setAuthUser] = useState({});
  const [updating, setUpdating] = useState(false);

  const profileUpdate = () =>
    updateProfile({
      data: { ...authUser, phoneNumber: authUser?.phoneNumber },
      setUpdating,
      updateSuccess: async () => {
        setShow(false);
        window.location.reload();
      },
    });

  useEffect(() => {
    show &&
      axios.get(updateAgentProfile({ userId: user.id })).then((res) => {
        if (res.status === 200 && res.data.respCode === 0) setShow(false);
      });
    setAuthUser({
      ...user,
      dateOfBirth: new Date(user?.dateOfBirth),
      officeAddress: user?.otherDetails?.organizationAddress,
    });
  }, [show]);
  return (
    <Layout title="Get Started">
      <Container>
        <div className="text-center mt-40">
          <h4 className="fs-20 text-darker fw-700 font-default">
            Welcome to WayaPOS Merchant Dashboard
          </h4>
          <h4 className="fs-14 text-darker font-default mt-10">
            Follow the steps below to get started.
          </h4>
        </div>

        <div className="">
          <QuickLinks />
          <ExploreCards />
        </div>

        {/* update settings */}
        {show && (
          <UpdateProfileModal
            state={show}
            scroll="body"
            title="Update Profile"
            rightAction={
              <DialogActions>
                <Button
                  fullWidth
                  size="medium"
                  color="primary"
                  onClick={profileUpdate}
                  loading={updating}
                >
                  <div className="text-orange"> Save</div>
                </Button>
              </DialogActions>
            }
          >
            <DialogContent
              sx={{ gap: 2, display: "flex", flexDirection: "column" }}
            >
              <UpdateProfileForm
                formData={authUser}
                setFormData={setAuthUser}
                userId={authUser?.id}
                updating={updating}
              />
            </DialogContent>
          </UpdateProfileModal>
        )}
      </Container>
    </Layout>
  );
};

export default Start;
