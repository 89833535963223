import Layout from 'components/Layout/Layout';
import React from 'react';
import AgDashbordInner from './agDashboard';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import AgCommissionInner from './AgCommission';


const AgDashboard = () => {
    const [isUser, setIsusers] = useState(true);

    return(
        <Layout title="Aggregator Dashboard">
            <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          {/* <div>{searchPane}</div> */}
          <div>
            <Button
              variant="outlined"
              onClick={() => setIsusers(true)}
              style={{ backgroundColor: isUser ? "var(--thirdColor)" : "", color: isUser ? "#fff" : "#000" }}
                >
              <div className="ms-2"> Onboarded Agents</div>
            </Button>
            <Button
              onClick={() => setIsusers(false)}
              className="mx-2"
              variant="outlined"
              style={{ backgroundColor: !isUser ? "var(--thirdColor)": "", color: isUser ? "#000" : "#fff" }}
                >
              <div className="ms-2"> Commission Earned</div>
            </Button>
          </div>
        <div />
        </div>
      </div>
      {isUser ? (
        <AgDashbordInner/>
      ) : (
        <AgCommissionInner/>
      )

      }
            
        </Layout>
    )
}

export default AgDashboard;