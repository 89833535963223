import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import WayaPos from "../../components/TransactionComponents/WayaPos";
import { ReactComponent as Arrow } from "../../assets/icons/arrows.svg";
import WayaAgency from "../../components/TransactionComponents/WayaAgency";
import { apiCall } from "../../utilities/apis";
import { searchTransactionByDate } from "../../plugins/urls";
import { useLocation } from "react-router-dom";

export const transactionsByDate = ({ from, to, success, setLoading }) =>
  apiCall({
    url: `${searchTransactionByDate}?endDate=${to}&startDate=${from}`,
    success,
    setLoading,
    method: "post",
    meta: {
      dataIndex: "respBody",
      codeIndex: "respCode",
      messageIndex: "respDesc",
    },
  });

const Transactions = () => {
  const active = useLocation()?.state?.active;
  const [state, setState] = useState({
    tab: "transactions",
  });
  useEffect(() => {
    if (active) {
      switchTabs("agency");
    }
  }, [active]);

  const switchTabs = (value) => {
    setState((state) => ({
      ...state,
      tab: value,
    }));
  };

  const renderPages = () => {
    switch (tab) {
      case "transactions":
        return <WayaPos />;
      case "agency":
        return <WayaAgency />;
      default:
        return <WayaPos />;
    }
  };

  const { tab } = state;

  return (
    <Layout title="Transactions">
      <div className="bg-white d-flex justify-content-between font-default br-30 width-50 mb-30 cursor-pointer">
        <div
          className={`d-flex align-items-center col-12 col-lg-5  ${
            tab === "transactions" ? "active-option" : "inactive-option"
          }`}
          onClick={() => switchTabs("transactions")}
        >
          <Arrow
            className={`mr-15 mt-05 ${
              tab === "transactions" ? "white-icon" : "grey-icon"
            }`}
          />

          <h4
            className={`fs-16 ${
              tab === "transactions" ? "text-white" : "text-default"
            }`}
          >
            Wayapos Transactions
          </h4>
        </div>

        <div
          className={`d-flex align-items-center col-12  col-lg-5  ${
            tab === "agency" ? "active-option" : "inactive-option"
          }`}
          onClick={() => switchTabs("agency")}
        >
          <Arrow
            className={`mr-15 mt-05 ${
              tab === "agency" ? "white-icon" : "grey-icon"
            }`}
          />
          <h4
            className={`fs-16 ${
              tab === "agency" ? "text-white" : "text-default"
            }`}
          >
            Account Transactions History
          </h4>
        </div>
      </div>
      {renderPages()}
    </Layout>
  );
};

export default Transactions;
