import React, { useState } from "react";
import "./Header.scss";
import NotificationsPopover from "./NotificationsPopover";
import MobileSidebar from "./MobileSidebar";
import { getUser } from "utilities/objectOperations";

const Header = (props) => {
  const user = getUser();
  const [title, setTitle] = useState("");
  return (
    <div className="Header">
      <div className="d-flex justify-content-between">
        <MobileSidebar title={title} setTitle={(name) => setTitle(name)} />
        <h1 className="Pagetitle">{props.title}</h1>

        <div className="user">
          <div className="mx-2" style={{ marginTop: "-5px" }}>
            <NotificationsPopover />
          </div>
          <p className="greet d-none d-lg-flex">
            {" "}
            Hi, <span className="name">{user ? user.firstName : "Admin"}</span>
          </p>
          <div className="userIcon">
            <h1 className="initials">
              {user?.firstName?.charAt(0)?.toUpperCase() || "A"}
            </h1>
            {/* <h1 className={headerStyles.initials}>{ data ? data.firstName.substring(0, 1) : 'A'}</h1> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
