import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const LoadComponent = ({ component, loader = null, loading }) => (
    <>
        {
            loading
            ? (<div className="d-flex justify-content-center">
                    {
                        loader ||  <CircularProgress color="secondary" />
                    }
                </div>)
                : (component)
        }
    </>
);

export default  LoadComponent;
