import React from 'react';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import facebook from '../../../../../assets/icons/facebook.png'
import instagram from "../../../../../assets/icons/Instagram.png";
import Whatsapp from "../../../../../assets/icons/WhatsApp.png";
import Twitter from "../../../../../assets/icons/Twitter.png";
import Modal from '../../../../../components/Modal';

const InviteBySocials = ({ show, onClose }) => {

	return (
		<Modal show={show} onclick={onClose} title='Via Social Media'>
			<div className='fs-14 my-4 text-center text-capitalize'>
				<p>send via social media</p>
			</div>

			<div className='px-4'>
				<div className='d-flex justify-content-center row-cols-4 mt-20'>
					<div className='text-center'>
						<WhatsappShareButton
							url='https://wayapos.ng'
							quote={
								'Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ...'
							}
							hashtag='#WayaPOS'>
							<img src={Whatsapp} alt='WhatsApp logo' />
							<p>WhatsApp</p>
						</WhatsappShareButton>
					</div>

					<div className='text-center'>
						<TwitterShareButton
							url='https://wayapos.ng'
							quote={
								'Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ...'
							}
							hashtag='#WayaPOS'>
							<img src={Twitter} alt='Twitter logo' />
							<p>Twitter</p>
						</TwitterShareButton>
					</div>

					<div className='text-center'>
						<a
							href='https://instagram.com'
							className='text-decoration-none text-dark'>
							<img src={instagram} alt='Instagram logo' />
							<p>Instagram</p>
						</a>
					</div>

					<div className='text-center'>
						<FacebookShareButton
							url='https://wayapos.ng'
							quote={
								'Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ...'
							}
							hashtag='#WayaPOS'>
							<img src={facebook} alt='Facebook logo' />
							<p>Facebook</p>
						</FacebookShareButton>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default InviteBySocials;
