import React, { useContext, useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { Modal, ModalBody, Button, ModalHeader } from 'reactstrap';
import '../update.scss';
import useUser from 'components/Hooks/useUser';
import { wayaUpload } from 'plugins/urls';
import { Slide, toast } from 'react-toastify';
import { apiCall } from 'utilities/apis';
import { TextField } from '@material-ui/core';


const ManualBvn = (props) => {
  const { showModal, hideModal, hideAlert, bvnData, getKycStatus } = props;
  const { user } = useUser();
  const [bvn, setBvn] = useState('');
  const [uploading, setUploading] = useState(false);
  const textRef = useRef();

  
  const handleSubmit = async () => {
    const dataUrl = await toPng(textRef.current);
    const blob = await fetch(dataUrl).then((res) => res.blob());
    const file = new File([blob], 'text-image.png', { type: 'image/png' });
    const newForm = new FormData();
    newForm.append('fileName', 'BVN');
    newForm.append('file', file);
    newForm.append('identificationNumber', bvn);
    newForm.append('id', bvnData?.id);
    newForm.append('userId', user.id);

    apiCall({
        url: `${wayaUpload}${user?.id}`,
        data: newForm,
        method: "post",
        setLoading: uploading,
        success: (resp) => {
          if(resp?.status){
            hideModal(false);
            getKycStatus();
          toast.success( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        } else {
          toast.error( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      });
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <h5 className="modal-header-data"> Manual BVN Submission </h5>
      </ModalHeader>
      <ModalBody>
        <div ref={textRef} className="text-center w-100">
            <TextField
              value={bvn}
              onChange={(e) => setBvn(e.target.value)}
              name="bvn"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  BVN
                </p>
              }
              variant="standard"
            />
        </div>
        {/* <div style={{ padding: '20px', display: 'none' }}>
          <div >
            <h1>BVN: {bvn}</h1>
          </div>
        </div> */}
        <div className="text-center m-4">
          <Button
            style={{ width: '200px' }}
            color="primary"
            onClick={handleSubmit}
          >
            Proceed
          </Button>
        </div>
        {/* <ModalFooter>
          <Button color="secondary" onClick={() => hideModal(false)}>
            Proceed
          </Button>
        </ModalFooter> */}
      </ModalBody>
    </Modal>
  );
};

export default ManualBvn;
