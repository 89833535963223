import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './update.scss';

const NinGuide = (props) => {
  const { showModal, hideModal, mode } = props;
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <h5 className="modal-header-data"> VNINs are replacing NINs! </h5>
      </ModalHeader>
      <ModalBody>
        <div
          id="qr-body"
          style={mode === 'profile' ? { marginBottom: '20px' } : {}}
        >
          <p>
            The Nigerian government is mandating that companies use a 16-digit
            virtual national identification number (VNIN) to verify your
            national identification number (NIN)
          </p>
          <p>
            To successfully verify your NIN on the Wayabank app please carefully
            follow the steps on this screen.
          </p>
        </div>
        <ModalFooter>
          <Button color="secondary" onClick={() => hideModal(false)}>
            Ok
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default NinGuide;
