import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "../../../../../plugins/axios";
import { Card, Button } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import useUser from "../../../../../components/Hooks/useUser";
import UpdateAccount from "../UpdateAccount";
import Loader from "../../../../../components/Loader";
import { settings } from "plugins/urls";

const LoginInformation = ({ setCurrentTab }) => {
  const { user } = useUser();

  const [geolocation, setGeoLocation] = useState();
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [locationInfo, setLocationInfo] = useState();

  const getLocationInfo = async () => {
    const key = process.env.REACT_APP_GEOAPIFY_KEY;
    setLoading(true);
    await axios({
      method: "get",
      url: `https://corsanywhere.herokuapp.com/https://api.geoapify.com/v1/geocode/reverse?lat=${geolocation?.Latitude}&lon=${geolocation?.Longitude}&apiKey=${key}`,
    })
      .then((res) => {
        console.log(res);
        if (res.status) {
          setLocationInfo(res.data.features[0].properties.formatted);
        }
        return res.data;
      })
      .finally(() => {
        setLoading(false);
      });
    return {};
  };

  const getLoginHistory = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${settings}/user/login-histories/${user.userId}`,
    })
      .then((res) => {
        setLoading(false);

        if (res.status) {
          setLoginHistory(res?.data?.respBody);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;
    setGeoLocation({ Latitude: crd.latitude, Longitude: crd.longitude });
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    !geolocation &&
      navigator.geolocation.getCurrentPosition(success, error, options);
    getLoginHistory();
    geolocation && getLocationInfo();
  }, [geolocation?.Latitude]);

  return loading ? (
    <Loader />
  ) : (
    <div className="">
      <Button
        onClick={() => setCurrentTab({ component: <UpdateAccount /> })}
        style={{ width: "10%" }}
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ color: "black" }}
        >
          <KeyboardBackspaceOutlinedIcon />
          <small className="ml-1">Back</small>
        </div>
      </Button>

      <div className="mt-3">
        <Card className="p-2 pb-0 fw-semi-bold mb-3" style={{ width: "85%" }}>
          <h4
            style={{ fontSize: ".9rem", color: "#ccc" }}
            className=""
            // onClick={() => setShowMap(true)}
          >
            Location
          </h4>
          <p style={{ fontSize: ".8rem", color: "#000" }}>{locationInfo}</p>
        </Card>

        <Card className="p-2 pb-0 fw-semi-bold mb-3" style={{ width: "85%" }}>
          <h4
            style={{ fontSize: ".9rem", color: "#ccc" }}
            className=""
            // onClick={() => setShowMap(true)}
          >
            Device
          </h4>

          <p style={{ fontSize: ".8rem", color: "#000" }}>
            {window.navigator.userAgent}
          </p>
        </Card>

        <Card className="p-2 pb-0 fw-semi-bold mb-3" style={{ width: "85%" }}>
          <h4 style={{ fontSize: ".9rem", color: "#ccc" }} className="">
            Time
          </h4>

          <p style={{ fontSize: ".8rem", color: "#000" }}>
            {moment().format("LT")}
          </p>
        </Card>

        <Card className="p-2 pb-0 fw-semi-bold mb-3" style={{ width: "85%" }}>
          <div className="d-flex justify-content-between">
            <div className="col-12 p-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-2">Time Log</h5>
                <Button onClick={() => setSeeAll(!seeAll)}>
                  {seeAll ? "See Less" : "See All"}
                </Button>
              </div>
              {seeAll &&
                loginHistory
                  ?.reverse()
                  ?.map(
                    (
                      { id, device, country, city, province, loginDate, ip },
                      index
                    ) =>
                      seeAll ? (
                        <div className="col-12 py-3" key={id}>
                          <div
                            className="row d-flex justify-content-space-between"
                            style={{ boxShadow: "none" }}
                          >
                            <div className="col-8">
                              <p className="text-muted mb-0">
                                {moment(loginDate).format("ddd Do MMM YYYY")},
                                <span className="text-muted ps-2">
                                  {moment(loginDate).format("LT")}.
                                </span>
                              </p>
                              <span className="text-info ps-2">{device}</span>
                            </div>
                            <div
                              className="col-4"
                              style={{ textAlign: "right" }}
                            >
                              <p className="text-muted my-0">
                                <span className="text-warning ps-1">{ip}</span>
                                {/*{moment(loginDate).format('LT')}*/}
                              </p>
                              <p className="text-muted my-0 small-text">
                                {`
						${city ? `${city},` : ""}
						 ${province ? `${province},` : ""}
						  ${country ? `${country}.` : ""}
						  `}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        index < 5 && (
                          <div className="col-12 py-3" key={id}>
                            <div
                              className="row d-flex justify-content-space-between"
                              style={{ boxShadow: "none" }}
                            >
                              <div className="col-8">
                                <p className="text-muted mb-0">
                                  {moment(loginDate).format("ddd Do MMM YYYY")},
                                  <span className="text-muted ps-2">
                                    {moment(loginDate).format("LT")}.
                                  </span>
                                </p>
                                <span className="text-info ps-2">{device}</span>
                              </div>
                              <div
                                className="col-4"
                                style={{ textAlign: "right" }}
                              >
                                <p className="text-muted my-0">
                                  <span className="text-warning ps-1">
                                    {ip}
                                  </span>
                                  {/*{moment(loginDate).format('LT')}*/}
                                </p>
                                <p className="text-muted my-0 small-text">
                                  {`
						${city ? `${city},` : ""}
						 ${province ? `${province},` : ""}
						  ${country ? `${country}.` : ""}
						  `}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )
                  )}
            </div>
          </div>
        </Card>
      </div>
      {/* {showMap ? <Map showModal={showMap} hideModal={setShowMap} /> : ''} */}
    </div>
  );
};

export default LoginInformation;
