import { ArrowForward, Check, Pending, Upload, UploadFile } from '@mui/icons-material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import UploadModal from './uploadModal';
import { otherFile, wayaKYC } from 'plugins/urls';
import { apiCall } from 'utilities/apis';
import useUser from 'components/Hooks/useUser';
import { Slide, toast } from 'react-toastify';
import ViewDocModal from './viewDoc';


const initial = {
  bankReference1: false,
  bankReference1Url: '',
  bankReference2: false,
  bankReference2Url: '',
  cacCertificate: false,
  cacCertificateUrl: '',
  cacDocument: false,
  cacDocumentUrl: '',
  cacMemart: false,
  cacMemartUrl: '',
  taxDocument: false,
  taxDocumentUrl: '',
};

const Tier4 = ({uploadDoc, postData, setPostData, getDocuments, showModal, hideModal, kycStatus, documents, businessId}) => {
//  const [] = useState(false);
// const [postData, setPostData] = useState({})
const { user } = useUser();
const inputDoc = useRef(null);
  const inputCert = useRef(null);
  const inputDir = useRef(null);
  const inputMem = useRef(null);
  const inputTin = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
const [name, setName] = useState('');
const [cac, setCac] = useState([]);
const [tin, setTin] = useState([]);
const [reference, setReference] = useState([]);
const rejected='';
const pending='';
const check='';
const upload1 = '';
const [data, setData] = useState(initial);
const [doc, setDoc] = useState({});
const [viewData, setViewData] = useState({});
const [viewDocModal, setShowViewDocModal] = useState(false);
const checkProperties = (obj) => {
  for (var key in obj) {
    if (obj[key] === '') return false;
  }
  return true;
};


const {
    userCAC,
    userTIN,
    mermatStatus,
  } = kycStatus;

  const upload = async (file, type) => {
    let newForm = new FormData();
    newForm.append('file', file);
    newForm.append('fileName', 'UTILITY');
    apiCall({
      url: `${otherFile}?fileName=${
      type === 'id' ? 'NATIONAL_ID' : type
    }&userId=${user.id}`,
      data: newForm,
      method: "post",
      // setLoading: uploading,
      success: (resp) => {
        if(resp?.status){
        toast.success( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        if (type === 'CAC_DOCUMENT') {
          setData({ ...data, cacDocument: true, cacDocumentUrl: resp?.data });
        } else if (type === 'CAC_CERTIFICATE') {
          setData({
            ...data,
            cacCertificate: true,
            cacCertificateUrl: resp?.data,
          });
        } else if (type === 'DIRECTOR_LIST') {
          setData({ ...data, directorListUrl: resp?.data });
        } else if (type === 'CAC_MEMART') {
          setData({ ...data, cacMemart: true, cacMemartUrl: resp?.data });
        } else if (type === 'CAC_TIN') {
          setData({ ...data, taxDocument: true, taxDocumentUrl: resp?.data });
          console.log('url', resp?.data);
        } else if (type === 'CAC_REFERENCE1') {
          setData({
            ...data,
            bankReference1: true,
            bankReference1Url: resp?.data,
          });
          console.log('url', resp?.data);
        } else if (type === 'CAC_REFERENCE2') {
          setData({
            ...data,
            bankReference2: true,
            bankReference2Url: resp?.data,
          });
          console.log('url', resp?.data);
        }
      } else {
        toast.error( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        })  
      }     
    },
    });
  };

  const previewImg = (e) => {
    setViewData({ url: e });
    setShowViewDocModal(true);
  };

  const handleSave = async (e) => {
    if (!checkProperties(data))
      return toast.error( 'All document must be uploaded!', {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
    })  
    
    apiCall({
      url: `${wayaKYC}api/v1/kyc/cac/uploadOtherDocument/${businessId}`,
      data,
      method: "put",
      // setLoading: uploading,
      success: (resp) => {
      if(resp?.status){
      toast.success( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
      });
      getDocuments();
      } else {
      toast.error( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
      })  
      }     
  },
  });

  };

  useEffect(() => {
    if (documents?.length > 0) {
      const cacDoc = documents?.filter((e) => e?.docType === 'CAC_DOCUMENT')[0];
      const cert = documents?.filter(
        (e) => e?.docType === 'CAC_CERTIFICATE'
      )[0];
      const mem = documents?.filter((e) => e?.docType === 'CAC_MEMART')[0];
      const tax = documents?.filter((e) => e?.docType === 'TAX_DOCUMENT')[0];
      const ref1 = documents?.filter(
        (e) => e?.docType === 'BANK_REFERENCE_I'
      )[0];
      const ref2 = documents?.filter(
        (e) => e?.docType === 'BANK_REFERENCE_II'
      )[0];
      setDoc({ ...doc, cacDoc, cert, mem, tax, ref1, ref2 });
      setData({
        ...data,
        bankReference1Url: ref1?.docUrl,
        bankReference2Url: ref2?.docUrl,
        cacCertificateUrl: cert?.docUrl,
        cacDocumentUrl: cacDoc?.docUrl,
        cacMemartUrl: mem?.docUrl,
        taxDocumentUrl: tax?.docUrl,
      });
    } else {
      setData(initial);
    }
  }, [documents]);

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    upload(event.target.files[0], event.target.id);
    event.target.value = null;
  };

// useEffect(() => {
//   const cac1 = kycStatus?.tier4?.filter(
//     (e) => e?.requirementType === 'CAC'
//   )[0];
//   const tin1 = kycStatus?.tier4?.filter(
//     (e) => e?.requirementType === 'TIN'
//   )[0];
//   const reference1 = kycStatus?.tier4?.filter(
//     (e) => e?.requirementType === 'REFERENCE'
//   )[0];
//   setCac(cac1);
//   setTin(tin1);
//   setReference(reference1);
// }, [])
    return (
        <div className="tier" id="tier4">
          {/*<div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 4</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  userAddress === true &&
                  kycStatus?.CAC?.status === true &&
                  tinStatus !== null &&
                  mermatStatus !== null
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {userAddress === true &&
                kycStatus?.CAC?.status === true &&
                tinStatus !== null &&
                mermatStatus !== null
                  ? 'Completed'
                  : 'Not Completed'}
              </p>
            </div>
          </div>*/}
          <div >
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr className="">
                  <td className="p-3">CAC Certificate </td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={cac?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('cac');
                      }}
                    >
                      <img src={Upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>
                  </td>
                  <td>
                    <div
                      className={
                        cac.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          cac.status === 'APPROVED'
                            ? check
                            : cac.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {cac.status}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Tax Indentification Number</td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={tin?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('tin');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>
                  
                  </td>
                  <td>
                    <div
                      className={
                        tin.status === 'APPROVED'
                          ? 'status-true'
                          : tin.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          tin.status === 'APPROVED'
                            ? check
                            : tin.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {tin.status}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Reference</td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={reference?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('reference');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>                  
                  </td>
                  <td>
                    <div
                      className={
                        reference?.status === 'APPROVED'
                          ? 'status-true'
                          : reference?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          reference.status === 'APPROVED'
                            ? check
                            : reference.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {reference?.status}
                      </span>
                    </div>
                  </td>
                </tr> */}
                <tr className="">
                  <td className="p-3">CAC Document </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputDoc.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputDoc}
                        type="file"
                        id="CAC_DOCUMENT"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.cacDocumentUrl?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.cacDocumentUrl}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.cacDocumentUrl)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.cacDoc?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.cacDoc?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <span>{doc?.cacDoc?.status}</span>
                      <p>
                        {doc?.cacDoc?.status === 'REJECTED' ? doc?.cacDoc?.reason : ''}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">CAC CERTIFICATE </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputCert.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputCert}
                        type="file"
                        id="CAC_CERTIFICATE"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.cacCertificateUrl?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.cacCertificateUrl}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.cacCertificateUrl)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.cert?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.cert?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          doc?.cert?.status === 'APPROVED'
                            ? check
                            : doc?.cert?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{doc?.cert?.status}</span>
                      <p>{doc?.cert?.status === 'REJECTED' ? doc?.cert?.reason : ''}</p>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">CAC Document-MEMART </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputMem.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputMem}
                        type="file"
                        id="CAC_MEMART"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.cacMemartUrl?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.cacMemartUrl}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.cacMemartUrl)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.mem?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.mem?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          doc?.mem?.status === 'APPROVED'
                            ? check
                            : doc?.mem?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{doc?.mem?.status}</span>
                      <p>{doc?.mem?.status === 'REJECTED' ? doc?.mem?.reason : ''}</p>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">TAX IDENTIFICATION NUMBER (TIN) </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputTin.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputTin}
                        type="file"
                        id="CAC_TIN"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.taxDocumentUrl?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.taxDocumentUrl}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.taxDocumentUrl)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.tax?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.tax?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          doc?.tax?.status === 'APPROVED'
                            ? check
                            : doc?.tax?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{doc?.tax?.status}</span>
                      <p>{doc?.tax?.status === 'REJECTED' ? doc?.tax?.reason : ''}</p>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">BANK REFERENCE 1 </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputRef1.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef1}
                        type="file"
                        id="CAC_REFERENCE1"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.bankReference1Url?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.bankReference1Url}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.bankReference1Url)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.ref1?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.ref1?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          doc?.ref1?.status === 'APPROVED'
                            ? check
                            : doc?.ref1?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{doc?.ref1?.status}</span>
                      <p>{doc?.ref1?.status === 'REJECTED' ? doc?.ref1?.reason : ''}</p>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">BANK REFERENCE 2 </td>
                  <td>
                    <div
                      type="file"
                      className="action btn my-2 border px-3"
                      onClick={() => inputRef2.current.click()}
                    >
                      {/* <img src={upload1} alt="" className="me-2" /> */}
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef2}
                        type="file"
                        id="CAC_REFERENCE2"
                        onChange={handleFileChange}
                      />
                      <span>Upload</span>
                      <UploadFile />
                    </div>
                    <span>
                      {data?.bankReference2Url?.includes('http') ? (
                        <img
                          className="mt-2"
                          src={data?.bankReference2Url}
                          width={30}
                          height={30}
                          onClick={() => previewImg(data?.bankReference2Url)}
                        />
                      ) : (
                        <img src={pending} alt="" />
                      )}
                    </span>
                  </td>
                  <td>
                    <div
                      className={
                        doc?.ref2?.status === 'APPROVED'
                          ? 'status-true'
                          : doc?.ref2?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          doc?.ref2?.status === 'APPROVED'
                            ? check
                            : doc?.ref2?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{doc?.ref2?.status}</span>
                      <p>{doc?.ref2?.status === 'REJECTED' ? doc?.ref2?.reason : ''}</p>
                    </div>
                  </td>
                </tr>
                <div style={{ textAlign: 'right' }}>
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                </div>
                {viewDocModal ? (
                  <ViewDocModal
                    showModal={viewDocModal}
                    hideModal={setShowViewDocModal}
                    data={viewData}
                  />
                ) : (
                  ''
                )}
              </tbody>
              
            </table>
          </div>
          
          {showModal && 
          <UploadModal
            showModal={showModal}
            hideModal={hideModal}
            postData={postData}
            setPostData={setPostData}
            name={name}
            uploadDoc={uploadDoc}
          />}
        </div>
    )
}
export default Tier4