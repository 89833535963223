import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from '../../../../../components/Modal';

const BusinessOwner = ({ show, onClose, email, tel, handleChange, submit }) => {
	return (
		<Modal show={show} onclick={onClose} title='Change Business Owner'>
			<div className='modal-form' style={{ width: '80%' }}>
				<p
					className='text-center fs-12 py-3 mx-auto'
					style={{ width: '90%' }}>
					This action will transfer this business to another
					administrator. You will no longer be the owner of this
					business, and so you can be removed from it.
				</p>

				<Form className='px-1 mx-auto' style={{ width: '85%' }}>
					<Form.Control
						type='email'
						name='email'
						value={email}
						size='lg'
						placeholder='New Business Owner’s Email Address'
						onChange={handleChange}
						className='w-100 fs-12 form-style'
					/>

					<br />

					<Form.Control
						type='tel'
						name='tel'
						value={tel}
						size='lg'
						placeholder='New Business Owner’s  Mobile Number'
						onChange={handleChange}
						className='w-100 fs-12 form-style'
					/>

					<br />
				</Form>

				<Button
					type='submit'
					onClick={submit}
					size='md'
					className='mx-auto py-2 w-75'
					style={{
						display: 'block',
						backgroundColor: '#FF6700',
						border: '0.5px solid #FF6700',
						fontWeight: '500',
						fontSize: '.8rem',
					}}>
					Transfer
				</Button>

				<p
					onClick={onClose}
					style={{
						display: 'block',
						color: '#FF6700',
						fontSize: '.7rem',
						fontWeight: '400',
						cursor: 'pointer'
					}}
					className='m-auto my-3 text-decoration-none text-center'>
					Cancel
				</p>
			</div>
		</Modal>
	);
};

export default BusinessOwner;
