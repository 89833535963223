import { useState, memo } from "react";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import Button from "@material-ui/core/Button";
import BusinessOwner from "../../pages/Settings/pages/manageUsers/component/BusinessOwner";
import ChangeRole from "../../pages/Settings/pages/manageUsers/component/ChangeRole";
import RemoveMember from "../../pages/Settings/pages/manageUsers/component/RemoveMember";
import {
  assignRole,
  editBusinessOwner,
  editRole,
  getAllAdmins,
  removeRole,
} from "../../plugins/urls";
import { useEffect } from "react";
import { getUser } from "utilities/objectOperations";
import { isEmpty } from "lodash";
import Modal from "components/Modal";
import { EditAdminMenu } from "./EditAdminMenu";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    color: "#202020",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    "& tr": {
      border: 0,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: 0,
  "&:last-child td, &:last-child th": {
    border: "none",
  },
}));

const TeamTable = memo(({ roles, getAllInvite }) => {
  const [state, setState] = useState({
    email: "",
    tel: "",
    roleId: "",
    pageSize: 5,
    pageNo: 1,
  });

  const currentUser = getUser();

  const { email, tel, roleId, pageSize, pageNo, submit } = state;

  const [business, setBusiness] = useState(false);
  const [change, setChange] = useState(false);
  const [remove, setRemove] = useState(false);
  const [adminMerchant, setAdminMerchant] = useState([]);
  const [user, setUser] = useState({});
  const {
    posUserProfile: { merchantId },
  } = currentUser;
  const [editUserMenu, setEditUserMenu] = useState({
    edit: false,
    menu: [],
    others: { approval: "", id: "", status: "", menuAssignId: "" },
  });
  const getAdminUser = () => {
    axios
      .get(
        `${getAllAdmins}/${merchantId}?field=all&value=all&pageSize=${pageSize}&pageNo=${pageNo}`
      )
      .then((res) => {
        setAdminMerchant(res.data.data.content);
      });
  };

  const onChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "menuItem") {
      let menuString = editUserMenu.menu ?? [];
      if (checked) {
        if (!menuString?.includes(value)) {
          menuString.push(value);
          setEditUserMenu((s) => ({ ...s, menu: menuString }));
        }
      } else {
        if (menuString?.includes(value)) {
          const index = menuString.indexOf(value);
          menuString.splice(index, 1);
        }
      }
    }
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {
    getAdminUser();
  }, []);

  const onError = (error) => {
    toast.error(`${error}`, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  // change business owner.
  const changeBusinessOwner = (e) => {
    e.preventDefault();

    let reqBody = {
      id: user.id,
      module: "customer",
    };

    axios({
      method: "put",
      url: `${editBusinessOwner}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.status) {
          toast.success(`Business Owner changed successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllInvite();
        }
      })
      .catch((err) => onError(err.response?.data?.message));
  };

  // change role..
  const changeRole = (e) => {
    e.preventDefault();

    let reqBody = {
      id: user.id,
      permissionId: user?.permission?.id,
      roleId: user?.role?.id,
    };

    axios({
      method: "put",
      url: `${editRole}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.status) {
          toast.success(`Role changed successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllInvite();
        }
      })
      .catch((err) => onError(err.response?.data?.message));
  };

  const assignMenuToUser = (e) => {
    e.preventDefault();
    const { unitId } = state;
    const { menu, others } = editUserMenu;
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios
      .put(assignRole, {
        menuString: menu,
        unitId,
        approval: "approved",
        status: "active",
        role: "initiator",

        ...others,
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === true) {
          toast.success(res.data.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.error(res.data.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(`An error occurred,all fields are required`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        setEditUserMenu({});
      });
  };
  // delete role.
  const deleteRole = async (e) => {
    e.preventDefault();

    await axios({
      method: "delete",
      url: `${removeRole}/${user?.id}`,
    })
      .then((res) => {
        if (res.status) {
          toast.success(`User Deleted successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllInvite();
        } else {
          onError("An error occured while deleting user");
        }
      })
      .catch((err) => onError(err.response?.data?.message));
  };

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h4 className="fs-16 font-default">
          Team members - {adminMerchant.length}
        </h4>
      </div>
      <TableContainer component={Box} className="mt-3">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="w-100"
        >
          <TableHead>
            <TableRow className="">
              <StyledTableCell className="text-capitalize fw-normal text-black-50 mx-auto">
                Name:
              </StyledTableCell>

              <StyledTableCell className="text-capitalize fw-normal text-black-50">
                Email:
              </StyledTableCell>

              <StyledTableCell className="text-capitalize fw-normal text-black-50">
                Role:
              </StyledTableCell>

              <StyledTableCell className="text-capitalize fw-normal text-black-50">
                Status:
              </StyledTableCell>

              <StyledTableCell className="text-capitalize fw-normal text-black-50">
                Actions:
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody className="justify-content-between">
            {isEmpty(adminMerchant) ? (
              <StyledTableRow>
                <StyledTableCell
                  className="mx-auto fw-bold fs-16"
                  style={{ color: "#444" }}
                >
                  No Admin added
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              adminMerchant?.map(
                (
                  {
                    surname,
                    firstName,
                    email,
                    roleName,
                    id,
                    menu: { menuString, menuAssignId, status },
                  },
                  index
                ) => (
                  <StyledTableRow
                    key={index}
                    style={{ border: 0 }}
                    className=""
                  >
                    <StyledTableCell scope="row" className="">
                      <div className="bold theme-font font-small fs-12">
                        {`${firstName} ${surname}`}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell scope="row">
                      <div className="bold theme-font font-small fs-12">
                        {email}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <div className="theme-font-2 fs-12">{roleName}</div>
                    </StyledTableCell>

                    <StyledTableCell>
                      {status === "active" ? (
                        <div
                          className="theme-font-2 fs-12 d-flex align-items-center justify-content-between"
                          style={{ width: "65px" }}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="4" cy="4" r="4" fill="#27AE60" />
                            <circle cx="4" cy="4" r="2" fill="#0E8741" />
                          </svg>
                          <span className="mx-1"> Active</span>
                        </div>
                      ) : (
                        <div
                          className="theme-font-2 fs-12 d-flex align-items-center justify-content-between"
                          style={{ width: "65px" }}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="4" cy="4" r="4" fill="#E0E0E0" />
                            <circle cx="4" cy="4" r="2" fill="#C4C4C4" />
                          </svg>
                          <span className="mx-1"> INACTIVE</span>
                        </div>
                      )}
                    </StyledTableCell>

                    <StyledTableCell className="w-25">
                      <div className="d-flex flex-wrap w-100 justify-content-between">
                        {roleName === "Business Owner" ? (
                          <Button
                            variant="outlined"
                            className="fs-12 fw-normal w-100"
                            onClick={() => {
                              setBusiness(true);
                              // setUser(row);
                            }}
                          >
                            Change Business Owner
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              className="fs-12 fw-normal border border-secondary px-0"
                              onClick={() => {
                                setEditUserMenu((s) => ({
                                  ...s,
                                  edit: true,
                                  menu: menuString,
                                  others: {
                                    id,
                                    adminType: roleName,
                                    status,
                                    menuAssignId,
                                  },
                                }));
                              }}
                              style={{
                                letterSpacing: ".7px",
                                width: "48%",
                                display: "block",
                              }}
                            >
                              Manage Role
                            </Button>
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <BusinessOwner
        show={business}
        onClose={() => setBusiness(false)}
        email={email}
        tel={tel}
        handleChange={handleChange}
        user={user}
        submit={(e) => changeBusinessOwner(e)}
      />
      <ChangeRole
        show={change}
        onClose={() => setChange(false)}
        roleId={roleId}
        handleChange={handleChange}
        roles={roles}
        user={user}
        submit={(e) => changeRole(e)}
      />
      <RemoveMember
        show={remove}
        onClose={() => setRemove(false)}
        user={user}
        submit={(e) => deleteRole(e)}
      />
      {editUserMenu.edit && (
        <Modal
          show={editUserMenu.edit}
          onclick={() => setEditUserMenu({ edit: false, menu: [] })}
          title="Edit Menu"
          action="Save Changes"
        >
          <EditAdminMenu
            onChange={onChange}
            modalState={editUserMenu.edit}
            submit={assignMenuToUser}
            loading={submit}
            // role={role}
            roles={{}}
            menu={editUserMenu.menu}
          />
        </Modal>
      )}
    </>
  );
});

export default TeamTable;
