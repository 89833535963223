import React, { createContext, useEffect, useState } from "react";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import { useNavigate } from "react-router-dom";
import BusinessInfo from "../../components/AuthComponent/RegisterForms.js/BusinessInfo";
import axios from "../../plugins/axios";
import { businessTypesList, signUp } from "../../plugins/urls";
import PersonalInfo from "../../components/AuthComponent/RegisterForms.js/PersonalInfo";
import PasswordForm from "../../components/AuthComponent/RegisterForms.js/PasswordForm";
import VerifyOtp from "./VerifyOtp";
import { toast, Slide } from "react-toastify";
import { apiCall } from "../../utilities/apis";
import {
  formatDonation,
  formatDonation2,
} from "../../utilities/stringOperations";

export const RegisterContext = createContext();

const Register = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    step: "business",
    admin: true,
    submit: false,
    orgName: "",
    orgEmail: "",
    businessType: "",
    orgPhone: "",
    referenceCode: "",
    password: "",
    passwordConfirm: "",
    orgType: "",
    city: "",
    stateOfOrigin: "",
    firstName: "",
    officeAddress: "",
    surname: "",
    phoneNumber: "",
    email: "",
    gender: "MALE",
    dateOfBirth: "2010-07-10",
    businessTypes: [],
    states: [],
    cities: [],
  });

  const {
    states,
    step,
    admin,
    email,
    officeAddress,
    orgEmail,
    orgName,
    orgPhone,
    orgType,
    referenceCode,
    stateOfOrigin,
    city,
    password,
    firstName,
    surname,
    gender,
    dateOfBirth,
    phoneNumber,
  } = state;

  useEffect(() => {
    window.location.href=`${process.env.REACT_APP_WAYABANK_WEBSITE}/signup?client=wayapos`
    // axios({
    //   method: "get",
    //   url: `${businessTypesList}?page=0&size=100`,
    // }).then((res) => {
    //   setState((state) => ({
    //     ...state,
    //     businessTypes: res.data.businessTypeList,
    //   }));
    // });

    // axios({
    //   method: "get",
    //   url: "stateandlga.json",
    // }).then((response) => {
    //   setState((state) => ({
    //     ...state,
    //     states: response.data,
    //   }));
    // });
  }, []);

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "phoneNumber" || name === "orgPhone") {
      if (formatDonation2(value).toString()?.length === 11) {
        setState((state) => ({
          ...state,
          [e.target.name]: e.target.value,
        }));
      } else {
        setState((state) => ({
          ...state,
          [e.target.name]: "",
        }));
      }
    } else {
      if (name === "stateOfOrigin") {
        getLgaByState(value);
      }
      setState((state) => ({
        ...state,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const onClickNext = (value) => {
    // e.preventDefault();

    setState((state) => ({
      ...state,
      step: value,
    }));
  };

  const getLgaByState = (name) => {
    // Inside array states, find the state that matches the value of state that was sent from the state input field
    // debugger;
    let value = states.find((state) =>
      state.state.name.toLowerCase().match(name.toLowerCase())
    );

    if (value) {
      setState((state) => ({
        ...state,
        cities: value.state.locals,
      }));
    } else {
      setState((state) => ({
        ...state,
        cities: [],
      }));
    }
  };

  const onCreateAccount = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    //For personal phone number
    let phone = phoneNumber ? phoneNumber.substring(1, 11) : "";
    let fullNumber = "234" + phone;

    let bizPhone = orgPhone ? orgPhone.substring(1, 11) : "";
    let businessPhone = "234" + bizPhone;

    let reqBody = {
      admin,
      businessType: orgType,
      city,
      dateOfBirth,
      email,
      firstName,
      gender,
      officeAddress,
      orgType,
      orgEmail,
      orgName,
      // orgPhone: businessPhone,
      orgPhone: formatDonation2(orgPhone),
      password,
      // phoneNumber: fullNumber,
      phoneNumber: formatDonation2(phoneNumber),
      referenceCode,
      state: stateOfOrigin,
      surname,
    };

    apiCall({
      method: "post",
      url: signUp,
      data: reqBody,
      success: (res) => {
        setState((state) => ({
          ...state,
          submit: false,
          step: "otp",
        }));
        toast.success(`${res.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      },
    });
  };

  const renderPages = () => {
    switch (step) {
      case "business":
        return <BusinessInfo />;
      case "personal":
        return <PersonalInfo />;
      case "password":
        return <PasswordForm />;
      default:
        return <BusinessInfo />;
    }
  };
  return (
    <>
      {step === "otp" ? (
        <VerifyOtp phoneOrEmail={email} redirectUrl="/login" />
      ) : (
        <AuthComponent>
          <AuthContainer>
            <RegisterContext.Provider
              value={{
                onChange,
                onClickNext,
                onCreateAccount,
                state,
              }}
            >
              <div className="login-header">
                <h4 className="fs-16 fw-500 login-text">
                  CREATE YOUR WAYAPOS ACCOUNT
                </h4>
              </div>

              <div className="mt-20 full-width">
                <form>
                  {renderPages()}

                  <div className="text-center mt-20">
                    <h4 className="fs-16 fw-400 text-default">
                      Have an account?{" "}
                      <span
                        className="text-green cursor-pointer"
                        onClick={() => navigate("/login")}
                      >
                        {" "}
                        Sign In{" "}
                      </span>
                    </h4>
                  </div>
                </form>
              </div>
            </RegisterContext.Provider>
          </AuthContainer>
        </AuthComponent>
      )}
    </>
  );
};

export default Register;
