import React, {useContext, useEffect, useState} from 'react';
import {Box, Paper, Grid, InputLabel, Select, MenuItem, FormControl} from '@mui/material';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Layout from '../Layout/Layout';
import {DisputeContext} from "../../pages/Disputes/Disputes";
import {Container} from "@material-ui/core";
import {BiArrowBack} from "react-icons/bi";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import {changeHandler} from "../../utilities/handlers";
import NumberFormat from "react-currency-format";
import AcceptChargeBack from "./resolveWayaDispute/AcceptChargeBack";
import RejectChargeBack from "./resolveWayaDispute/RejectChargeBack";
import {apiCall} from "../../utilities/apis";
import {getWayaDisputeAttachment} from "../../plugins/urls";
import CardImg from "react-bootstrap/CardImg";
import {Slide, toast} from "react-toastify";
import CircularLoader from "../SubmitLoader/CircularLoader";

const ResolveDispute = () => {

	/* ###### CONTEXT ########## */

	const {state, setState} = useContext(DisputeContext);
	const dispute = state?.dispute;

	/* ###### STATE ########## */

	const [attachments, setAttachments] = useState([]);
	const [formData, setFormData] = useState({fullRefund: true});
	const [loading, setLoading] = useState(false);

	/* ###### EFFECTS ########## */

	useEffect(() => {
		apiCall({
			url: `${getWayaDisputeAttachment}${dispute?.id}/attachment`, setLoading, defaultMeta: true,
			success: (data) =>setAttachments(data?.attachments)
		});
	}, []);

	/* ###### HANDLERS ########## */
	const handleChange = (e) => changeHandler({e, setFormData});
	const handleAccept = (data) => {
		toast.error('Dispute resolution successfully initiated!', {
			transition: Slide,
			hideProgressBar: true,
			autoClose: 3000,
		});
		setState({...state, dispute: {}});
	};

	/* ###### DISPLAY ########## */

	return (
		<Container >
			<div className='mb-2 d-flex'>
				<Button className=' ms-2' onClick={() => setState({...state, dispute: {}})}>
					<BiArrowBack  />
				</Button>

				<div className="ms-3">
					{`Dispute Ticket I.D #${dispute.trackingNumber}`}
				</div>
			</div>

			<Grid
				container
				// spacing={2}
				className='mx-auto'
				style={{
					width: '80%',
					height: '80vh',
					marginTop: '80px',
					// boxShadow: '0px 15px 10px -15px #ccc',
				}}>
				<Grid
					item
					xs={12}
					md={5}
					sx={{}}
					className='p-3'
					style={{
						backgroundColor: 'rgba(231, 71, 44, 0.09)',
						borderRadius: '15px',
					}}>
					<p
						style={{
							color: '#000',
							// textDecoration: 'underline solid #FF6700 3px',
							paddingBottom: '7px',
							borderBottom: '4px solid #FF6700',
							wordSpacing: '1px',
						}}
						className='fw-bold mt-2 ml-2'>
						Detail reason for dispute
					</p>
					<Col>
						{
							dispute?.reason
						}
					</Col>
					{
						loading
						? <CircularLoader />
						: (
								<Col className='overflow-auto my-3'>
									{
										attachments?.map(attachment => (
											<Col>
												<h6>{attachment?.fileName}</h6>
												<CardImg src={attachment?.path}/>
											</Col>
										))
									}
								</Col>
							)
					}
				</Grid>

				<Grid
					item
					xs={12}
					md={7}
					sx={{}}
					style={{ boxShadow: '5px 0 3px -5px #ccc' }}>
					<Box
						className='py-3 m-auto'
						style={{
							boxShadow: '0px 4px 4px -4px #ccc',
							borderRadius: '0 0 15px 15px',
						}}>
						<p className='text-center m-auto text-dark'>
							Resolve transaction dispute
						</p>
					</Box>

					<div style={{ width: '80%' }} className='mx-auto px-4'>
						<div
							className='d-flex flex-column'
							style={{ margin: '50px auto' }}>
							<div
								className='d-flex justify-content-between align-items-center w-100 mx-auto mb-2'
								style={{ borderBottom: '0.5px solid #444' }}>
								<span
									style={{
										color: '#444',
										fontSize: '.8rem',
										fontWeight: '300',
										letterSpacing: '.4px',
									}}
									className='text-capitalize py-3'>
									customer name
								</span>

								<span
									style={{
										fontSize: '.9rem',
										letterSpacing: '.4px',
									}}
									className='fw-bold'>
									{dispute?.customer}
								</span>
							</div>

							<div
								className='d-flex justify-content-between align-items-center w-100 mx-auto mb-2'
								style={{ borderBottom: '0.5px solid #444' }}>
								<span
									style={{
										color: '#444',
										fontSize: '.8rem',
										fontWeight: '300',
										letterSpacing: '.4px',
									}}
									className='text-capitalize py-3'>
									created at
								</span>

								<span
									style={{
										fontSize: '.9rem',
										letterSpacing: '.4px',
									}}
									className='fw-bold'>
									{dispute?.dateCreated}
								</span>
							</div>

							<div
								className='d-flex justify-content-between align-items-center w-100 mx-auto mb-2'
								style={{ borderBottom: '0.5px solid #444' }}>
								<span
									style={{
										color: '#444',
										fontSize: '.8rem',
										fontWeight: '300',
										letterSpacing: '.4px',
									}}
									className='text-capitalize py-3'>
									dispute type
								</span>

								<span
									style={{
										fontSize: '.9rem',
										letterSpacing: '.4px',
									}}
									className='fw-bold'>
									{dispute?.disputeType}
								</span>
							</div>

							<div
								className='d-flex justify-content-between align-items-center w-100 mx-auto mb-2'
								style={{ borderBottom: '0.5px solid #444' }}>
								<span
									style={{
										color: '#444',
										fontSize: '.8rem',
										fontWeight: '300',
										letterSpacing: '.4px',
									}}
									className='text-capitalize py-3'>
									Reference ID
								</span>

								<span
									style={{
										fontSize: '.9rem',
										letterSpacing: '.4px',
									}}
									className='fw-bold'>
									{dispute?.trackingNumber}
								</span>
							</div>
						</div>

						<Box>
							<div className='w-100 d-flex justify-content-between'>
								<span
									className={`text-capitalize ${formData?.accept ? 'border-bottom-orange' : 'pointer'}`}
									onClick={() => setFormData({...formData, accept: true})}
									style={{
										fontSize: '.8rem',
										letterSpacing: '.4px',
										fontWeight: '500',
									}}>
									Accept chargeback
								</span>

								<span
									className={`text-capitalize ${!formData?.accept ? 'border-bottom-orange' : 'pointer'}`}
									onClick={() => setFormData({...formData, accept: false})}
									style={{
										fontSize: '.8rem',
										letterSpacing: '.4px',
										fontWeight: '500',
									}}>
									Reject chargeback
								</span>
							</div>
							{
								formData?.accept
								? <AcceptChargeBack formData={formData} handleChange={handleChange} dispute={dispute} handleResolve={handleAccept} />
								: <RejectChargeBack formData={formData} handleChange={handleChange} dispute={dispute} handleResolve={handleAccept}  />
							}
						</Box>
					</div>
				</Grid>
			</Grid>
		</Container>

	);
};

export default ResolveDispute;
