import React, { useEffect, useMemo } from 'react';
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { sentenceCaps } from '../../../../utilities/stringOperations';
import TextField from "@material-ui/core/TextField";
import {isEmpty} from 'lodash';

import StyledAutoComplete from "../../../Settings/pages/updateAccount/components/StyledAutoComplete";
import {Box} from "@mui/material";
import CountryList from "country-list-with-dial-code-and-flag";
import {Search} from "@mui/icons-material";
import InputBase from "@material-ui/core/InputBase";
import { styled } from '@mui/material/styles';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {available} from "../../../../utilities/arrayOperations";

const SelectBanks = (
  {
    label,
    name,
    value,
    className,
    onChange,
    options,
    optionIndex,
    valueIndex,
    titleIndex,
  }
) => {
  const [searchTerm, setSearchterm] = React.useState('');
  const [selectOptions, setSelectOptions] = React.useState(options);
  const [searchResults, setSearchResults] = React.useState([]);
  // const [selectedOption, setSelectedOption] = React.useState(value );
  const [show, setShow] = React.useState(false);


  useEffect(() => {
        searchTerm?.length > 2
        ? setSearchResults(available(options, searchTerm, optionIndex))
            :setSearchResults([])
      },
  [searchTerm, options]);
  useEffect(() => {
    if (searchResults?.length > 0) {
      return setSelectOptions(searchResults);
    } else {
      setSelectOptions(options);
    }
  }, [searchResults, options, searchTerm]);

  // useEffect(() => {
  //     if(!isEmpty(value)) {
  //         setSelectedOption(value)
  //     }
  // }, [value]);

  const handleChange = (e) => {
    setSearchterm(e.target.value);
  };

  const handleSelect = (opt) => {
    setSearchterm('');
    onChange({
      target: {
        name,
        value: opt,
        apiValue: opt
      }
    });
  };
    const clearSelection = () => {
        setSearchterm('');
        onChange({
            target: {
                name,
                value: '',
                apiValue: ''
            }
        });
    };
  const optionsProp = selectOptions?.map((option) => (

        <li
          className="list-unstyled"
          value={option[valueIndex]}
          key={option[valueIndex]}
          title={option[titleIndex]}
        >
          <Button className="btn-plain text-success no-border text-hover-white" onClick={() => handleSelect(option)}>
            {
              (value[optionIndex] === option[optionIndex]
              )
              && <IoCheckmark />
            }
           <div className="d-flex">
             <div className="width-80 align-self-center"><img src={option.logo} alt=""/></div>
             <div className="width-280 ms-2">
                 <div className="text-black fs-18 text-start">{sentenceCaps(option.name)}</div>
                 <div className="text-start">
                     {/*<span className="pe-2 text-muted fs-16">{option.code}</span>*/}
                     {/*<span className="pe-2 text-muted fs-18">.</span>*/}
                     {/*<span className="pe-2 text-muted fs-16">{option.accountNumber}</span>*/}
                 </div>
             </div>
           </div>

          </Button>
        </li>
      ));

  return (
    <div
      className={className}
      onMouseEnter={() => {
        setShow(true);
        // setEdit(true);
      }}
      onMouseLeave={() => setShow(false)}
    >
            <div className="d-flex justify-content-between">
             <TextField value={sentenceCaps(value?.[optionIndex]) || ''} label={label} readOnly />
                {
                    value && (
                        <div>
                            <IconButton onClick={clearSelection}>
                                <IoCloseOutline className="text-danger" />
                            </IconButton>
                        </div>
                    )
                }
            </div>
      <div className={show ? '' : 'd-none'}>
        <Card className="ontop">
          <div className="card-body">
            <div
              className='center-center'
              id={name}
              onChange={onChange}
            >
              <div className="mb-3">
                  <TextField
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <Search />
                              </InputAdornment>
                          ),
                      }}
                      placeholder={sentenceCaps(value) || `Search ${label} here...`}
                      value={searchTerm}
                      onChange={handleChange}
                      type="search"
                  />
              </div>
              <div className="select-2 text-left col-12">

                {optionsProp}

              </div>
            </div>
          </div>

        </Card>
      </div>
    </div>

  );
};
export default SelectBanks;
