import React, { useState} from 'react';
// @mui
import {
  Stack,
} from '@mui/material';

import {uploadBox, uploadModal, viewFileModal} from "../screens/KYC/temps";

export default function KYCCorporateUploadForm({formData, uploadDoc, onChange, requirements, uploading, postData, setPostData}) {

  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [url, setUrl] = useState(false);

  const handlePreview = (uri) => {
      setPreview(true);
      setUrl(uri);
  };

  return (
      <Stack spacing={3} pr={4}>

          {
              requirements.map(({name, label, title}) => uploadBox(name, label, title, formData, uploadDoc, setOpen, uploading, handlePreview))
          }

          {uploadModal(onChange, setOpen, open, (name) => {
              uploadDoc({reqItem: name});
              setOpen(false);
          }, postData, setPostData)}
          {viewFileModal([url], preview, setPreview)}

      </Stack>
  );
}
