import Modal from "../../../../../../../components/Modal";
import React, { useEffect, useState } from "react";
import LinkBvn from "./LinkBvn";
import BvnToken from "./BvnToken";
import SuccessTemp from "../../../components/successTemp";
import useUser from "../../../../../../../components/Hooks/useUser";
import { apiCall } from "../../../../../../../utilities/apis";
import { linkBVN, nipsUrl, validateBvn, wayaDisputesMetrics } from "../../../../../../../plugins/urls";
import { Slide, toast } from "react-toastify";
import { formatDonation2 } from "../../../../../../../utilities/stringOperations";
import axios from "axios";

const VerifyBvn = ({ openBvn, setOpenBvn, bvn }) => {
  const { user } = useUser();

  const [step, setStep] = useState("link");
  const [loading, setLoading] = useState(false);
  const [nibssUrl, setNibssUrl] = useState("");
  const [formData, setFormData] = useState({
    bvn,
    dob: user?.dateOfBirth,
    userId: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    phoneNumber: user?.phoneNumber,
  });

  const validateOtp = () => {
    setLoading(true);
    axios
      .post(`${validateBvn}${user?.id}/${formData.pin}`)
      .then(({ status, data }) => {
        if (status === 200 && data.status === true) {
          setStep("success");
        } else {
          toast.error(data.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNibss = () =>
    apiCall({
      url: nipsUrl,
      method: "get",
      setLoading,
      defaultMeta: true,
      success: (data) => {
        console.log('Nipps', data);
        setNibssUrl(data);
      }, 
    });
  useEffect(() => {
    getNibss();
  }, []);

  if(nibssUrl){
    const url = nibssUrl;
    const title = `BVN Verification`;
    window.open(
      url,
      title,
      `width=${500},
      height=${400},
      left=${200},
      top=${200}`
    );
    setOpenBvn(false)
  }
  useEffect(() => {
    formatDonation2(formData?.pin)?.length === 6 && validateOtp();
    /*    && apiCall({
            url: `${validateBvn}${user?.id}/${formData.pin}`, setLoading, method: 'post',
            success: (data) => {
                setStep('success');
                toast.success( data?.message || 'Pin successfully validated', {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            }
        }) */
  }, [formData]);

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const linkBvn = () =>
    apiCall({
      url: linkBVN,
      method: "post",
      setLoading,
      data: {
        ...formData,
        bvn: formatDonation2(bvn),
        phoneNumber: `234${formatDonation2(formData?.phoneNumber)?.substring(
          3
        )}`,
      },
      success: (data) => {
        toast.success(
          data?.message || "A Token has been sent to your Linked phone number",
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        setStep("token");
      },
    });

  const temp = {
    link: (
      <LinkBvn
        formData={formData}
        bvn={bvn}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        linkBvn={linkBvn}
        loading={loading}
        skip={() => setStep("token")}
      />
    ),
    token: (
      <BvnToken handleChange={handleChange} retry={linkBvn} loading={loading} />
    ),
    success: (
      <SuccessTemp
        close={() => setOpenBvn(false)}
        titleTop="Success"
        titleDown="Congratulations"
        message="BVN successfully linked!"
        okText="Continue"
      />
    ),
  };

  return (
    <Modal show={openBvn} onclick={() => setOpenBvn(false)} title="Verify BVN">
      {//temp[step]
      }
    </Modal>
  );
};
export default VerifyBvn;
