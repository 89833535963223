export const objectToArray = (obj) => {
  const arr = [];
  if (typeof obj === "object") {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(obj)) {
      arr.push({
        key,
        ...value,
      });
    }
  }
  console.log(arr);
  return arr;
};

export const filterItem = (arr, index, indexVal) => {
  const found = arr?.filter((item) => item[index] === indexVal);
  return found?.length > 0 ? found : [];
};

export const available = (data, searchTerm, index) =>
  data?.filter((option) => {
    const temp = option.constructor === Object ? option[index] : option;
    return temp?.toLowerCase()?.includes(searchTerm?.toLowerCase());
  });

export const getUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem("userDetails"))?.user;
  } catch {
    user = null;
  }
  return user;
};
