import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import NumberFormat from "react-currency-format";
import TextField from "@material-ui/core/TextField";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {apiCall} from "../../../utilities/apis";
import {acceptDisputeChargeback} from "../../../plugins/urls";
import {formatDonation2} from "../../../utilities/stringOperations";
const AcceptChargeBack = ({formData, handleChange, dispute, handleResolve}) => {
    const [loading, setLoading] = useState();

    /* ###### API #######*/
    const accept = () => {
        const data = {
            amount: formData?.fullRefund ? dispute?.amount : formatDonation2(formData?.amount),
            comment: formData?.comment,
            disputeId: dispute?.id,
            fileName: formData?.fileName,
            filePath: formData?.filePath,
            merchantId: dispute?.merchantID,
        };
        console.log({data, dispute});
        apiCall({
            url: acceptDisputeChargeback, setLoading, data, defaultMeta: true, success: handleResolve, method: 'put'
        })
    };

    /* ###### DISPLAY #######*/

    return (
        <Box>
            <p
                className='my-4 text-dark fw-normal'
                style={{
                    wordSpacing: '1px',
                    fontSize: '.8rem',
                }}>
                By accepting this chargeback, you’re instructing
                us to process a refund that will be deducted
                from your next settlement.
            </p>
            <FormControl variant='outlined' className='col-12 my-2'>
                <InputLabel id='demo-simple-select-outlined-label'>
                    How much should we refund?
                </InputLabel>
                <Select
                    name='fullRefund'
                    value={formData.fullRefund}
                    onChange={handleChange}
                    label='How much should we refund?'
                >
                    <MenuItem value=''>
                        <em>Select Refund Type</em>
                    </MenuItem>

                    <MenuItem value={true}>
                        Full refund (
                        <span>&#8358;</span>
                        <span>{dispute?.amount}</span>
                        )
                    </MenuItem>
                    <MenuItem value={false}>
                        Part refund (Kindly enter refund amount below)
                    </MenuItem>
                </Select>
            </FormControl>

            {
                !formData?.fullRefund
                && (
                    <NumberFormat className="w-100 my-3" value={formData?.amount} label='Refund Amount' placeholder='Enter refund amount'  onChange={handleChange} name="amount" variant="standard" customInput={TextField} thousandSeparator prefix='&#8358;'  />
                )
            }

            <Form.Control
                as='textarea'
                onChange={handleChange}
                name='comment'
                placeholder='Add a comment [Optional]'
                style={{
                    height: '15px',
                    backgroundColor: 'transparent',
                    color: '#000',
                    resize: 'none',
                }}
                className='w-100 fs-12 form-style mt-2 mb-3'
            />

            <Button
                onClick={accept}
                type='submit'
                size='md'
                className='mx-auto py-2 fw-semi-bold'
                style={{
                    float: 'right',
                    clear: 'all',
                    width: '45%',
                    display: 'block',
                    backgroundColor: '#FF6700',
                    border: '0.5px solid #FF6700',
                    fontSize: '.8rem',
                    letterSpacing: '.6px'
                }}>
                Accept Chargeback
            </Button>
        </Box>
    );
}
export default AcceptChargeBack;
