import { Avatar, Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  makeInitials,
  stringDoesNotExist,
} from "../../../../../utilities/stringOperations";
import BackdropModal from "./BackdropModal";
import { apiCall } from "../../../../../utilities/apis";
import { updateProfileImage } from "../../../../../plugins/urls";
import UploadProfilePicture from "./UploadProfile";
import EllipsisLoader from "../../../../../components/SubmitLoader/ElipseLoader";
import IconButton from "@material-ui/core/IconButton";
import { MdOutlineFlipCameraIos } from "react-icons/md";
import { toast } from "react-toastify";

ProfileAvatarComponent.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  hideName: PropTypes.bool,
  upload: PropTypes.bool,
};

export default function ProfileAvatarComponent({ name, hideName, upload }) {
  const user = JSON.parse(localStorage.getItem("userDetails"))?.user;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [profilePic, setProfilePic] = useState(user.profileImage);

  const uploadProfile = () => {
    const data = new FormData();
    data.append("file", value);
    apiCall({
      url: `${updateProfileImage}${user?.id}`,
      setLoading,
      data,
      success: (data) => {
        toast.success(data?.message);
        setProfilePic(URL.createObjectURL(value));
        setOpen(false);
      },
      method: "post",
    });
  };

  const onChange = useCallback(
    (file) => {
      // setValue(file.target?.value);
    },
    [setValue]
  );

  const uploadPic =(e)=>{
    setValue(e[0]);
  }

  return (
    <Box className="text-center" style={{ height: "20vh" }}>
      {stringDoesNotExist(profilePic) ? (
        <Avatar
          style={{
            display: "inline-flex",
            fontWeight: "bold",
            background: "#FDAF8E",
          }}
          sx={{ height: 74, width: 74 }}
        >
          {makeInitials(name)}
        </Avatar>
      ) : (
        <Avatar
          style={{
            display: "inline-flex",
            fontWeight: "bold",
            background: "#FDAF8E",
          }}
          sx={{ height: 100, width: 100 }}
          src={profilePic || value}
        />
      )}

      {!hideName && <div className="bold"> {name} </div>}
      <div>
        {upload && (
          <IconButton onClick={() => setOpen(true)}>
            <MdOutlineFlipCameraIos />
          </IconButton>
        )}
      </div>
      <BackdropModal
        open={open}
        content={
          <UploadProfilePicture
            loading={loading}
            onChange={onChange}
            uploadPic={uploadPic}
            uploadProfile={uploadProfile}
          />
        }
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
}
