import React from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout'
import ComingSoonTemp from "./ComingSoonTemp";

const ComingSoon = () => {
    const navigate = useNavigate();

  return (
    <Layout title="Activity Log">
       <ComingSoonTemp back={() => navigate('/')} />
    </Layout>
  )
};

export default ComingSoon
