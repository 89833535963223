import axios from "axios";

let authToken = null;
// if (typeof window !== "undefined") {
if (localStorage?.getItem("userDetails")) {
  // console.log(JSON.parse(localStorage.getItem('userDetails')))
  try {
    const { token } = JSON.parse(localStorage.getItem("userDetails")) || {};
    authToken = token;
  } catch {
    authToken = null;
  }
}
// }
const instance = axios;

if (authToken) {
  // console.log(authToken)
  instance.defaults.headers.common["Authorization"] = `${authToken}`;
  instance.defaults.headers.common['Client-Id'] = 'WAYAPOS';
  instance.defaults.headers.common['Client-Type'] = 'CORPORATE';
}

// instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
instance.defaults.headers.common["accept"] =
  "application/x-www-form-urlencoded";
  instance.defaults.headers.common['Client-Id'] = 'WAYAPOS';
  instance.defaults.headers.common['Client-Type'] = 'CORPORATE';
// instance.defaults.headers.common['accept']  = 'application/json';

export default instance;
