import React, { useState } from 'react';
import {
  Box,
  DialogActions,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import CurrencyFormat from 'react-currency-format';
import List from '@material-ui/core/List';
import SuccessTemp from './successTemp';
import Modal from '../../../../../components/Modal';
import {apiCall} from "../../../../../utilities/apis";
import {toggleLock, validatePin} from "../../../../../plugins/urls";
import {formatDonation} from "../../../../../utilities/stringOperations";
import PinInput from "react-pin-input";
import useUser from "../../../../../components/Hooks/useUser";
import EllipsisLoader from "../../../../../components/SubmitLoader/ElipseLoader";

export default function DeleteAccount({ close, show, onClose }) {
  const {user} = useUser();

  const [formData, setFormData] = useState({ date_of_birth: new Date() });
  const [sending, setSending] = useState(false);
  const [tab, setTab] = useState('email');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const closeModal = () => {
    onClose();
    setTab('email');
    setInterval(() => setSending(false), 3000);
  };
  const goNext = (next) => {
    setTab(next);
    setInterval(() => setSending(false), 3000);
  };
  const deleteAccount = () => apiCall({
    url: `${toggleLock}${user?.id}`, method: 'post', setLoading: setSending, success: (data) => goNext('success')
  });

  const confirm = () => apiCall({
      url: `${validatePin}${formatDonation(formData.pin)}` ,
      setLoading: setSending,
      success: (data) => {
        deleteAccount();
      },
      // error: ()=>goNext('error'),
      method: 'get'
    });


  const templates = {
    email: (
      <Modal show={show} onclick={closeModal} title="Delete Account">
        <Stack spacing={3} className="p-4">
          <Box textAlign="center" className="mt-3">
            <svg
              width="45"
              height="39"
              viewBox="0 0 45 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8944 3.8614L2.5182 31.2001C2.18056 31.7848 2.00191 32.4477 2.00002 33.1229C1.99812 33.7981 2.17306 34.4621 2.50742 35.0486C2.84178 35.6352 3.32391 36.1241 3.90583 36.4665C4.48776 36.8089 5.14921 36.993 5.82436 37.0004H38.5767C39.2518 36.993 39.9133 36.8089 40.4952 36.4665C41.0771 36.1241 41.5593 35.6352 41.8936 35.0486C42.228 34.4621 42.4029 33.7981 42.401 33.1229C42.3991 32.4477 42.2205 31.7848 41.8828 31.2001L25.5067 3.8614C25.162 3.29318 24.6767 2.82338 24.0976 2.49733C23.5185 2.17129 22.8651 2 22.2005 2C21.5359 2 20.8826 2.17129 20.3034 2.49733C19.7243 2.82338 19.239 3.29318 18.8944 3.8614V3.8614Z"
                stroke="#FF0000"
                strokeWidth="3.86686"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 13.7988V21.5325"
                stroke="#FF0000"
                strokeWidth="3.86686"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 29.2656H22.0193"
                stroke="#FF0000"
                strokeWidth="3.86686"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>

          <p
            className="delete-modal-sub text-center mt-3"
            style={{ color: '#000', fontSize: '1.1rem' }}
          >
            This action will close your account with Wayabank
          </p>

          <List>
            <ListItemText
              primary={
                <Box display="flex" flexDirection="row" className="mb-2">
                  <Box className="mr-2">
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#FF6700" />
                    </svg>
                  </Box>

                  {/* &nbsp; */}

                  <span
                    className="delete-modal-text ml-2"
                    style={{
                      color: '#adb5bd',
                      letterSpacing: '.3px',
                      marginLeft: '10px',
                    }}
                  >
                    Customers will no longer be able to make payments to this
                    business
                  </span>
                </Box>
              }
              className="mb-3"
            />

            <ListItemText
              primary={
                <Box display="flex" flexDirection="row">
                  <Box>
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#FF6700" />
                    </svg>
                  </Box>

                  <Box
                    className="delete-modal-text ml-2"
                    style={{
                      color: '#adb5bd',
                      letterSpacing: '.3px',
                      marginLeft: '10px',
                    }}
                  >
                    Active subscriptions to this business will be cancelled, and
                    subscribers will be informed
                  </Box>
                </Box>
              }
              className="mb-3"
            />

            <ListItemText
              primary={
                <Box display="flex" flexDirection="row">
                  <Box>
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#FF6700" />
                    </svg>
                  </Box>

                  <Box
                    className="delete-modal-text ml-2"
                    style={{
                      color: '#adb5bd',
                      letterSpacing: '.3px',
                      marginLeft: '10px',
                    }}
                  >
                    No data will be lost (we keep an archive of all data for
                    compliance reasons) and you can retrieve this account by
                    contacting Wayaapaychat Support
                  </Box>
                </Box>
              }
              className="mb-3"
            />
          </List>

          <DialogActions className="d-flex justify-content-between w-100">
            <LoadingButton
              fullWidth
              loading={sending}
              onClick={onClose}
              className="py-2 fw-bold"
              style={{
                color: '#fff',
                backgroundColor: '#6c757d',
                fontSize: '.8rem',
                borderRadius: '3px',
                width: '40%',
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              fullWidth
              loading={sending}
              color="primary"
              onClick={() => goNext('otp')}
              className="py-2 fw-bold"
              style={{
                color: '#fff',
                backgroundColor: '#FF6700',
                fontSize: '.8rem',
                borderRadius: '3px',
                width: '40%',
              }}
            >
              Next
            </LoadingButton>
          </DialogActions>
        </Stack>
      </Modal>
    ),

    otp: (
      <Modal show={show} onclick={closeModal} title="Verfiy OTP">
        <Stack spacing={3}>
          <Box alignSelf="center">
            <Box mb={7} className="text-center mt-3">
              <h4
                className="delete-modal-sub"
                style={{ color: '#000', fontSize: '1.3rem' }}
              >
                Verify Your Account
              </h4>

              <p
                className="delete-modal-sub mt-3"
                style={{ color: '#000', fontSize: '.8rem' }}
              >
                Please input your Secret PIN to proceed in disabling your account
              </p>
            </Box>

            <Box textAlign="center" pl={10} className="w-100">
              {
                sending
                  ? <EllipsisLoader />
                  : (
                        <div className="my-3">
                          <PinInput
                              length={4}
                              initialValue=""
                              secret
                              onChange={(value, index) => {}}
                              type="numeric"
                              style={{padding: '10px'}}
                              inputStyle={{borderColor: 'blue', borderLeft: "none", borderRight: "none", borderTop: "none"}}
                              inputFocusStyle={{borderColor: 'green'}}
                              onComplete={(value, index) => {handleChange({target: {name: 'pin', value}})}}
                          />
                        </div>
                    )
              }
            </Box>
          </Box>

          <DialogActions>
            <LoadingButton
              fullWidth
              size="large"
              loading={sending}
              color="primary"
              onClick={confirm}
              className="fs-12"
            >
              Disable My Account
            </LoadingButton>
          </DialogActions>

          <LoadingButton
            fullWidth
            size="large"
            loading={sending}
            color="primary"
            onClick={closeModal}
            className="mb-3 mt-0 fs-12"
          >
            Cancel
          </LoadingButton>
        </Stack>
      </Modal>
    ),
    success: (
      <Modal show={show} onclick={closeModal} title="Deleted Successfully">
        <SuccessTemp
          titleDown="Success"
          message="Your account has successfully been deactivated"
          close={closeModal}
          loading={sending}
        />
      </Modal>
    ),
  };
  return templates[tab];
}
