import React from "react";
import {Col} from "react-bootstrap";

const BoxTemp = ({title, icon, btnText, content, btnAction}) => (
    <Col className='mx-auto'>
        <div className='text-center support-div transfer-opts'>
            <img src={icon} alt='an icon' />
            <div className="fs-24 fw-bold text-black">{title}</div>
            <div className='fs-18 text-darker mt-20 lh-base w-75 mx-auto h-25'>
               {content}
            </div>
            <button
                className='orange-button mt-20 br-20 fw-700 fs-13 px-4 py-2'
                onClick={btnAction}>
               {btnText}
            </button>
        </div>
    </Col>
);
export default BoxTemp;
