import { Unit } from "pages/Settings/pages/manageUsers/component/Unit";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import useUser from "./components/Hooks/useUser";
import Activity from "./pages/Activity/Activity";
import ForgotPassword from "./pages/AuthPages/ForgotPassword";
import Login from "./pages/AuthPages/Login";
import Register from "./pages/AuthPages/Register";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Dashboard from "./pages/Dashboard/Dashboard";
import Disputes from "./pages/Disputes/Disputes";
import Start from "./pages/GettingStarted/Start";
import AddMerchant from "./pages/Merchants/AddMerchant";
import Merchant from "./pages/Merchants/Merchant";
import Merchants from "./pages/Merchants/Merchants";
import Notification from "./pages/Notifications/Notification";
import Settings from "./pages/Settings/Settings";
import Settlement from "./pages/Settlement/Settlement";
import ViewSettlement from "./pages/Settlement/ViewSettlement";
import Support from "./pages/Support/Support";
import AddTerminal from "./pages/Terminals/AddTerminal";
import Terminal from "./pages/Terminals/Terminal";
import TerminalRequest from "./pages/Terminals/TerminalRequest";
import Transaction from "./pages/Transactions/Transaction";
import Transactions from "./pages/Transactions/Transactions";
import AgDashboard from "pages/AgDashboad";
import AuthLogin from "pages/AuthPages/auth";

const PrivateRoute = ({ children }) => {
  // console.log(JSON.parse(localStorage.getItem('userDetails')))
  return localStorage.getItem("userDetails") ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

function App() {
  const { user } = useUser();

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      // navigate("/login");
    }
    return;
  }, []);
  return (
    // <BrowserRouter>
    <Suspense fallback="f">
      <Routes>
        {/*Checking if logged in  */}
        <Route
          path="/"
          exact
          element={
            <PrivateRoute>
              <Dashboard />{" "}
            </PrivateRoute>
          }
        />

        {/* Login */}
        <Route path="/login" exact element={<Login />} />
        <Route path="/auth" exact element={<AuthLogin />} />

        <Route path="/register" exact element={<Register />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />

        {/* Dashboard Management */}
        <Route
          path="/dashboard"
          exact
          element={
            <PrivateRoute>
              <Dashboard />{" "}
            </PrivateRoute>
          }
        />

        {/* Terminal Management */}
        <Route
          path="/terminals"
          exact
          element={
            <PrivateRoute>
              <Terminal />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/terminal-requests"
          exact
          element={
            <PrivateRoute>
              <TerminalRequest />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/add-terminal"
          exact
          element={
            <PrivateRoute>
              <AddTerminal />{" "}
            </PrivateRoute>
          }
        />

        {/* Transactions */}
        <Route
          path="/transactions"
          exact
          element={
            <PrivateRoute>
              <Transactions />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/aggregator-dashboard"
          exact
          element={
            <PrivateRoute>
              <AgDashboard />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/transaction/:id"
          exact
          element={
            <PrivateRoute>
              <Transaction />{" "}
            </PrivateRoute>
          }
        />

        {/* Disputes */}
        <Route
          path="/disputes"
          exact
          element={
            <PrivateRoute>
              <Disputes />{" "}
            </PrivateRoute>
          }
        />

        {/* Notifications */}
        <Route
          path="/notifications"
          exact
          element={
            <PrivateRoute>
              <Notification />{" "}
            </PrivateRoute>
          }
        />

        {/* Activity Log */}
        <Route
          path="/activity-log"
          exact
          element={
            <PrivateRoute>
              <Activity />{" "}
            </PrivateRoute>
          }
        />

        {/* Settlement */}
        <Route
          path="/settlements"
          element={
            <PrivateRoute>
              <Settlement />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/settlement/:id"
          element={
            <PrivateRoute>
              <ViewSettlement />{" "}
            </PrivateRoute>
          }
        />

        {/* Merchants */}
        <Route
          path="/merchants"
          exact
          element={
            <PrivateRoute>
              <Merchants />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/merchant/:id"
          element={
            <PrivateRoute>
              <Merchant />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/add-merchant"
          element={
            <PrivateRoute>
              <AddMerchant />{" "}
            </PrivateRoute>
          }
        />

        {/* Get started */}
        <Route
          path="/get-started"
          exact
          element={
            <PrivateRoute>
              <Start />{" "}
            </PrivateRoute>
          }
        />

        {/* Settings */}
        <Route
          path="/settings"
          exact
          element={
            <PrivateRoute>
              <Settings />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/:page"
          exact
          element={
            <PrivateRoute>
              <Settings />{" "}
            </PrivateRoute>
          }
        />

        {/* Support */}
        <Route
          path="/support"
          exact
          element={
            <PrivateRoute>
              <Support />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path="/team-members/unit"
          exact
          element={
            <PrivateRoute>
              <Unit />{" "}
            </PrivateRoute>
          }
        />

        {/* Coming soon */}
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
    </Suspense>
    // </BrowserRouter>
  );
}

export default App;
