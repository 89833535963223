import {
  Container,
  Box,
  Stack,
  Paper,
  Switch,
  FormControlLabel,
  Grid,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SettingsMenus, { updateMenu } from "../../components/settingsMenus";
import TransferLimitComponent from "./components/TransferLimitComponent";
import InviteEarnComponent from "./components/InviteEarnComponent";
import ProfileAvatarComponent from "./components/ProfileAvatarComponent";
import ChangePasswordComponent from "./components/ChangePasswordForm";
import UpdateProfileModal from "./components/UpdateProfileModal";
import UpdateProfileForm from "./components/UpdateProfileForm";
import ChangePinModal from "./components/ChangePinModal";
import ChangePinForm from "./components/ChangePinForm";
import DeleteAccount from "./components/DeleteAccount";
import { apiCall, getTwoFaStatus, toggleTwoFa, updateProfile } from "../../../../utilities/apis";
import { useLocation, useNavigate } from "react-router-dom";
import UpgradeKYC from "./screens/KYC/UpgradeKYC";
import { makeFullName } from "../../../../utilities/stringOperations";
import useUser from "../../../../components/Hooks/useUser";
import useUserDetails from "../../../../components/Hooks/useUserDetails";
import SettlementAccount from "./components/SettlementAccount";
import { get2FaStatus, getKycStatus1 } from "plugins/urls";
import axios from "axios";

export default function UpdateAccount() {
  const navigate = useNavigate();
  const { user, token } = useUser();
  const { getUserDetails } = useUserDetails();

  const { search } = useLocation();

  const [currentTab, setCurrentTab] = useState({});

  const [twoFactor, setTwoFactor] = useState(false);
  const [hideBalance, setHideBalance] = useState(false);
  //const [beginDelete, setBeginDelete] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingWal, setLoadingWal] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [changePin, setChangePin] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [authUser, setAuthUser] = useState(user);
  const [skipped, setSkipped] = useState(true);
  const [twoFAStatus, set2FAStatus] = useState(false);
  const [level,setLevel] = useState(1)

  useEffect(() => {
    if (!skipped) {
      closeUpdateProfile();
    }
  }, [skipped]);

  const get2Fa = () => {
    axios
    .get(get2FaStatus({userId: user?.id}))
    .then((res) => {
      if (res.status) {
        console.log('sssss', res?.data?.data['2fa']);
        set2FAStatus(res?.data?.data['2fa'])
        //  updateSuccess(userId);
      }
    })
  }
  const getKycStatus = () => {
    apiCall({
      url: `${getKycStatus1}${user?.id}`,
      method: "get",
      setLoading,
      success: (resp) => {
        const data = resp.data;
        setLevel(resp?.data?.kycTier?.orderLevel ?? 1)
          console.log('dataa', data);
      },
    });
  };

  useEffect(() => {
    if (search === "?kyc") {
      setCurrentTab({
        component: <UpgradeKYC setCurrentTab={setCurrentTab} />,
      });
    }
    if (search === "?update-profile") {
      setOpen(true);
    }
  }, []);
  useEffect(() => {
    get2Fa();
    getKycStatus();
  }, []);
  const updateMenu2 = [
    {
      name: "Hide Balance",
      class: "align-items-center py-1 mt-4",
      element: (
        <FormControlLabel
          control={<Switch />}
          label=""
          checked={hideBalance}
          onChange={() => setHideBalance(!hideBalance)}
        />
      ),
    },

    {
      name: "Change Password",
      // class: 'py-2',
      component: <ChangePasswordComponent />,
      click: () => navigate("/forgot-password/?change-password"),
    },
    {
      name: "Change Settlement Account",
      // class: 'py-2',
      component: <SettlementAccount back={() => setCurrentTab(null)} />,
      // click: () => navigate('/forgot-password/?change-password'),
    },
  ];

  const closeUpdateProfile = () => {
    if (skipped)
      updateProfile({
        data: authUser?.edited || authUser,
        setUpdating,
      });
    else {
      getUserDetails(user?.id, (res) =>
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            token,
            user: { ...user, ...res, id: user.id },
          })
        )
      );
    }
    if (search === "?update-profile") {
      navigate("/add-terminal");
    }
    setOpen(false);
    setInterval(() => setUpdateProfileLoading(false), 3000);
  };
  const toggle2fa = () => {
      toggleTwoFa({
        userId: user.id,
      });
      get2Fa();
    setInterval(() => setUpdateProfileLoading(false), 3000);
  };

  const startUpdateProfile = (update = true) =>
    update ? setOpen(true) : setChangePin(true);

  return (
    <div title="User: Account Settings">
      <Container>
        {currentTab?.component || (
          <Paper className="rounded-0 px-3">
            <Grid container className="">
              <Grid item xs={12} md={6} pr={4} className="">
                <Stack direction="column" mt={5} spacing={2}>
                  <ProfileAvatarComponent
                    name={makeFullName([authUser.firstName, user.lastName])}
                    acct={authUser.debitAccount}
                    upload
                    loadingWal={loadingWal}
                  />

                  <Box pt={5}>
                    <SettingsMenus
                      setCurrent={setCurrentTab}
                      // menu={updateMenu({ setCurrentTab})}
                      menu={updateMenu({
                        click: startUpdateProfile,
                        setCurrentTab,
                      })}
                      clss={{
                        main: "pointer",
                        name: "",
                        icon: "mt-0",
                      }}
                    />

                    <Box alignSelf="flex-start" sx={{ mt: 5, pb: 10 }}>
                      <div style={{ display: "inline" }} className="mt-5 me-4">
                        <span className="me-2">
                          <svg
                            width="18"
                            height="21"
                            viewBox="0 0 18 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H6L9 21L12 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10 16H8V14H10V16ZM12.07 8.25L11.17 9.17C10.45 9.9 10 10.5 10 12H8V11.5C8 10.4 8.45 9.4 9.17 8.67L10.41 7.41C10.78 7.05 11 6.55 11 6C11 4.9 10.1 4 9 4C7.9 4 7 4.9 7 6H5C5 3.79 6.79 2 9 2C11.21 2 13 3.79 13 6C13 6.88 12.64 7.68 12.07 8.25Z"
                              fill="black"
                              fillOpacity="0.4"
                            />
                          </svg>
                        </span>

                        <span className="fw-bold fs-14">Two-factor Auth</span>
                      </div>

                      <FormControlLabel
                        className="fs-12"
                        control={<Switch />}
                        label={twoFAStatus ? "Enabled" : "Disabled"}
                        checked={twoFAStatus}
                        onClick={toggle2fa}
                        labelPlacement="end"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} pr={4} className="">
                <Stack direction="column" spacing={2}>
                  <Box>
                    <TransferLimitComponent
                    tier={level}
                      limit={10000}
                      setCurrentTab={setCurrentTab}
                    />

                    <InviteEarnComponent
                      setCurrentTab={setCurrentTab}
                      amount={"0.00"}
                      hide={hideBalance}
                      code={authUser.referenceCode}
                    />
                  </Box>

                  <Box pt={4}>
                    <SettingsMenus
                      menu={updateMenu2}
                      setCurrent={setCurrentTab}
                      clss={{
                        main: "mt-3 pointer",
                        name: "",
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>

            <p
              className="text-center pointer"
              style={{ fontSize: ".8rem", color: "red" }}
              onClick={() => setDeleteModal(true)}
            >
              Delete My Account
            </p>

            {/* update profile */}
            <UpdateProfileModal
              state={open}
              scroll="body"
              title="Update Profile"
              loading={updating}
              onClose={closeUpdateProfile}
              leftAction={
                <DialogActions>
                  <LoadingButton
                    loading={updating}
                    fullWidth
                    size="large"
                    onClick={closeUpdateProfile}
                  >
                    <div className="text-orange fs-14">Skip</div>
                  </LoadingButton>
                </DialogActions>
              }
              rightAction={
                <DialogActions>
                  <LoadingButton
                    fullWidth
                    size="large"
                    color="primary"
                    loading={updating}
                    onClick={() =>
                      updateProfile({
                        data: authUser,
                        setUpdating,
                        updateSuccess: () => {
                          setSkipped(false);
                        },
                      })
                    }
                  >
                    <div className="text-orange"> Save</div>
                  </LoadingButton>
                </DialogActions>
              }
            >
              <DialogContent
                sx={{
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UpdateProfileForm
                  formData={authUser}
                  setFormData={setAuthUser}
                  loadingWal={loadingWal}
                  updating={updating}
                />
              </DialogContent>
            </UpdateProfileModal>

            <ChangePinModal state={changePin}>
              <ChangePinForm close={setChangePin} />
            </ChangePinModal>

            <DeleteAccount
              show={deleteModal}
              onClose={() => setDeleteModal(false)}
            />
          </Paper>
        )}
      </Container>
    </div>
  );
}
