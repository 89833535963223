import { ArrowForward, Check, Pending } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import VerifyBvn from './bvn/VerifyBvn';
import KYCUploadMode from './bvn/uploadMode';
import ManualBvn from './bvn/uploadManualBvn';


const Tier2 = ({uploadDoc, postData, getKycStatus, showModal, hideModal, kycStatus}) => {
const [bvn, setBvn] = useState("");
const [openBvn, setOpenBvn] = useState(false);
const [uploadMode, setUploadMode] = useState('auto');
const [ showMode, setShowMode] = useState(false);
const [showManual, setShowManual] = useState(false);
const bvnStat = []
const level2 = []

useEffect(()=>{
  const bvn1 = kycStatus?.tier2?.filter(
    (e) => e?.requirementType === 'BVN'
  )[0];
  setBvn(bvn1);
 
}, [kycStatus])

const handleContinue = async () => {
  if (uploadMode === 'auto') {
    setOpenBvn(true);
    setShowMode(false);
  } else {
    setShowManual(true);
    setShowMode(false);
  }
}
    return (
        <div className="tier" id="tier2">
          {/*<div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 2</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  bvnStat?.validated === true
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {bvnStat?.validated === true ? 'Completed' : 'Not Completed'}
              </p>
            </div>
              </div>*/}
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">BVN Validation</td>
                  <td>
                    <button
                      disabled ={ bvn?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                       //setShowAlertType('bvn');
                        setShowMode(true);
                      }}
                    >
                      <img src={ArrowForward} alt="" className="me-2" />
                      <span>
                        {bvn?.status === 'APPROVED' ? 'Verified' : 'Verify'}
                      </span>
                    </button>
                  </td>
                  <td>
                    <div
                      className={
                        bvn?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={bvn?.status === 'APPROVED' ? Check : Pending}
                        alt=""
                        className={`me-2 ${
                          bvn?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>
                        {bvn?.status}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*<div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- External (
              {level2?.maxNumberOfTxnAllowed}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit- External Transfer (N
              {level2?.singleTransferLimit})
            </p>
            <p className="limit-info">
              Daily Number of Trasfers - Internal (
              {level2?.maxNumberOfTxnAllowed}){' '}
            </p>
            <p className="limit-info">
              Daily limit - Internal Transfer -wallet to wallet: (N
              {level2?.maximumLimit})
            </p>
            <p className="limit-info">
              Single Transfer limit- Internal Transfer -wallet to wallet : (N
              {level2?.singleTransferLimit})
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
        
          </div>*/}
          <div style={{ height: 100 }} />
          {openBvn && <VerifyBvn
            openBvn={openBvn}
            setOpenBvn={setOpenBvn}
          />}
          {showMode && <KYCUploadMode
            showModal={showMode}
            hideModal={setShowMode}
            uploadMode={uploadMode}
            setUploadMode={setUploadMode}
            handleContinue={handleContinue}
          />}
          {showManual && <ManualBvn
            showModal={showManual}
            hideModal={setShowManual}
            bvnData={bvn}
            getKycStatus={getKycStatus}
            />}
        </div>
    )
}
export default Tier2