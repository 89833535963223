import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { changeHandler } from "../../utilities/handlers";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import { apiCall } from "../../utilities/apis";
import { createWayaDispute } from "../../plugins/urls";
import useUser from "../Hooks/useUser";
import { Slide, toast } from "react-toastify";

const CreateWayaDispute = ({ close, setLoadingCre }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => changeHandler({ e, setFormData });

  const handleLoading = (curStat) => {
    setLoadingCre(curStat);
    setLoading(curStat);
  };

  const submit = () => {
    const data = { ...formData, merchantID: user?.merchantId };

    apiCall({
      url: createWayaDispute,
      data,
      defaultMeta: true,
      method: "post",
      setLoading: handleLoading,
      success: (data) => {
        toast.success("Dispute successfully logged", {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
          onClose: close,
        });
      },
    });
  };

  return (
    <div
      className="px-2 mx-auto pb-4"
      style={{
        width: "80%",
        fontFamily: "Lato",
        fontStyle: "normal",
        color: "#4F4F4F",
      }}
    >
      <FormControl variant="outlined" className="col-12 my-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Dispute Type
        </InputLabel>
        <Select
          name="disputeType"
          value={formData.disputeType}
          onChange={handleChange}
          label="Dispute Type"
        >
          <MenuItem value="">
            <em>Select Type of Dispute</em>
          </MenuItem>

          <MenuItem value="Chargeback">Charge back</MenuItem>
          <MenuItem value="Fraud">Fraud</MenuItem>
          <MenuItem value="Complaint">Complaint</MenuItem>
          <MenuItem value="Unsettled_transaction">
            Unsettled Transaction
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        className="col-12 my-2"
        value={formData.customer}
        onChange={handleChange}
        name="customer"
        label="Customer Name"
        variant="outlined"
      />

      <TextField
        className="col-12 my-2"
        value={formData.customerEmail}
        onChange={handleChange}
        name="customerEmail"
        type="email"
        label="Customer Email"
        variant="outlined"
      />

      <TextField
        className="col-12 my-2"
        value={formData.transactionReference}
        onChange={handleChange}
        name="transactionReference"
        label="Transaction Reference"
        variant="outlined"
      />

      <TextField
        className="col-12 my-2"
        value={formData.reason}
        onChange={handleChange}
        name="reason"
        multiline
        rows={5}
        label="Dispute description"
        variant="outlined"
      />

      <div className="modalfooter">
        <button
          type="submit"
          className="orange-button extra-padding"
          onClick={submit}
        >
          {loading ? <SubmitLoader /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default CreateWayaDispute;
