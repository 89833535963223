import { createContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import Forgot from "../../components/AuthComponent/ForgotPassword/Forgot";
import Pin from "../../components/AuthComponent/ForgotPassword/Pin";
import ResetPassword from "../../components/AuthComponent/ForgotPassword/ResetPassword";
import axios from "../../plugins/axios";
import {
  resetPassword,
  sendOtp,
  sendOtpChangePassword,
} from "../../plugins/urls";
import ChangePasswordComponent from "../Settings/pages/updateAccount/components/ChangePasswordForm";

export const ForgotPasswordContext = createContext();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    step: "email",
    phoneOrEmail: "",
    newPassword: "",
    password: "",
    submit: false,
  });
  const [otp, setOtp] = useState("");
  const { step, phoneOrEmail, newPassword } = state;

  const changePasswordSuccess = (data) => {
    toast.success(
      "Password updated successfully, kindly login with new password",
      { transition: Slide, hideProgressBar: true, autoClose: 3000 }
    );
    navigate("/login");
  };

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const renderPages = () => {
    switch (step) {
      case "email":
        return <Forgot />;
      case "otp":
        return <Pin />;
      case "reset":
        return <ResetPassword />;
      case "change":
        return (
          <ChangePasswordComponent
            dat={{ phoneOrEmail, otp }}
            success={changePasswordSuccess}
          />
        );
      default:
        return <Forgot />;
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChange = (ref, val) => {
    if (val === "") {
      ref1.current.value = "";
      ref2.current.value = "";
      ref3.current.value = "";
      ref4.current.value = "";
      ref5.current.value = "";
      ref6.current.value = "";
      setOtp("");
    } else {
      setOtp(`${otp}${val}`);
      ref.current.focus();
    }
  };
  const { search } = useLocation();

  const isChnage = search === "?change-password";

  const handleSubmit = (val) => {
    setOtp(`${otp}${val}`);
  };

  const onSendOtp = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "get",
      url: `${isChnage ? sendOtpChangePassword : sendOtp}`,
      params: {
        email: phoneOrEmail,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          toast.success(`An OTP has been sent to the provided email`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            submit: false,
            step: "otp",
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onClickNext = (e) => {
    e.preventDefault();

    setState((state) => ({
      ...state,
      step: isChnage ? "change" : "reset",
    }));
  };

  const onResetPassword = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      newPassword,
      otp,
      phoneOrEmail,
    };
    axios({
      method: "post",
      url: `${resetPassword}`,
      data: reqBody,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        if (res.data.status === true) {
          toast.success(
            `Password ${
              isChnage ? "updated" : "reset"
            } successfully, kindly login with new password`,
            { transition: Slide, hideProgressBar: true, autoClose: 3000 }
          );
          navigate("/login");
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  return (
    <AuthComponent>
      <AuthContainer>
        <ForgotPasswordContext.Provider
          value={{
            onChange,
            onSendOtp,
            onClickNext,
            onResetPassword,
            handleChange,
            handleSubmit,
            state,
            ref1,
            ref2,
            ref3,
            ref4,
            ref5,
            ref6,
            otp,
          }}
        >
          {renderPages()}
        </ForgotPasswordContext.Provider>
      </AuthContainer>
    </AuthComponent>
  );
};

export default ForgotPassword;
