import React, { createContext, useState } from 'react'
import {isEmpty} from 'lodash';
import AuthDispute from '../../components/DisputeComponents/AuthDispute'
import DisputeNav from '../../components/DisputeComponents/DisputeNav'
import OtherDispute from '../../components/DisputeComponents/OtherDispute'
import WayaDispute from '../../components/DisputeComponents/WayaDispute'
import Layout from '../../components/Layout/Layout'
import ResolveDispute from "../../components/DisputeComponents/ResolveDispute";
import ResolveAuthDispute from "../../components/DisputeComponents/ResolveAuthDispute";
import ResolveOtherDispute from "../../components/DisputeComponents/ResolveOtherDispute";

export const DisputeContext = createContext();

const Disputes = () => {
    const[state, setState] = useState({
        route: 'waya'
    });

    const {route} = state;

    const onRouteChange = (value)=>{
        setState(state=>({
            ...state,
            route: value,
            dispute: {}
        }))
    };


    const renderPages = ()=>{
        switch(route){
          case 'waya':
              return <WayaDispute />;
          case 'auth':
              return <AuthDispute />;
          case 'other':
              return <OtherDispute />;
          default:
              return <WayaDispute />
        }
    }
    const resolveTemp = {
        waya: <ResolveDispute />,
        auth: <ResolveAuthDispute/>,
        other: <ResolveOtherDispute/>
    };
  return (
    <Layout title="Dispute">
        <DisputeContext.Provider value={{
            onRouteChange,
            state,
            setState
        }}>
            {
                isEmpty(state.dispute)
                ? (
                    <>
                        <DisputeNav />
                        {renderPages()}
                    </>
                    )
                    : resolveTemp[state.route]
            }
        </DisputeContext.Provider>
    </Layout>
  )
};

export default Disputes
