import React, { createContext, useState } from "react";
import { PaymentPreference } from "./PaymentPrefence";
import SettlementPreferenceNav from "./SettlementPrefenceNav";
import { SettlementPrefenceTable } from "./SettlementPreferenceTable";

export const SettlementContext = createContext({
  route: "waya",
});
export const SettlementPreference = () => {
  const [state, setState] = useState({
    route: "waya",
  });

  const { route } = state || {};
  const renderPages = () => {
    switch (route) {
      case "waya":
        return <SettlementPrefenceTable />;
      case "auth":
        return <PaymentPreference />;
      default:
        return <SettlementPrefenceTable />;
    }
  };

  const onRouteChange = (value) => {
    setState((state) => ({
      ...state,
      route: value,
    }));
  };
  return (
    <SettlementContext.Provider
      value={{
        onRouteChange,
        ...state,
        setState,
      }}
    >
      <SettlementPreferenceNav />
      {renderPages()}
    </SettlementContext.Provider>
  );
};
