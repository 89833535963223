import { Container } from "@mui/material";
import { useState } from "react";
import SettingsMenus from "../components/settingsMenus";

export default function Transparacy() {
  const [formData, setFormData] = useState({});
  const website = process.env.REACT_APP_WAYAPOS_WEBSITE;

  const settings = [
    {
      name: "Terms & Conditions",
      class: "pt-2 align-items-center",
      description: "Read about our terms & conditions",
      click: () => window.open(`${website}/terms`),
    },
    {
      name: "Privacy Policy",
      class: "pt-2 align-items-center",
      description: "Read about our privacy & policy",
      click: () => window.open(`${website}/privacy-policy`),
    },
    {
      name: "About Us",
      class: "pt-2 align-items-center",
      description: "Who we are",
      click: () => window.open(`https://www.wayalinks.com/about`),
    },
  ];

  return (
    <div title="User: Account Settings">
      <Container>
        <SettingsMenus menu={settings} clss={{ main: "mb-2", name: "" }} />
      </Container>
    </div>
  );
}
