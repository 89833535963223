import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import useUser from "../../components/Hooks/useUser";
import useUserDetails from "../../components/Hooks/useUserDetails";
import Loader from "../../components/Loader";
import SubmitLoader from "../../components/SubmitLoader/SubmitLoader";
import { signIn, twoFA, walletDetails } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import ChangePinModal from "../Settings/pages/updateAccount/components/ChangePinModal";
import PinModal from "./twoFa";
import VerifyOtp from "./VerifyOtp";
const Login = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    emailOrPhoneNumber: "",
    password: "",
    otp: false,
    message: "",
    twoFa: "",
  });
  const [refreshing, setRefreshing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState({ dateOfBirth: new Date() });
  const [showPassword, setShowPassword] = useState(false);
  const [show2Fa, setShow2Fa] = useState(false);
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsloading] = useState(true);

  const { getUser } = useUser();
  const { getUserDetails } = useUserDetails();

  const { emailOrPhoneNumber, password, message, otp } = state;

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onLogin = (e) => {
    // e.preventDefault();

    let reqBody = {
      emailOrPhoneNumber,
      otp: OTP,
      password,
    };

    apiCall({
      data: reqBody,
      url: signIn,
      method: "post",
      setLoading,
      error: (err) => setState({ ...state, message: err }),
      success: (data) => {
        setRefreshing(true);
        const { token, user } = data.data;
        const userCopy = { ...user };
        delete userCopy.address;

        setAuthUser({ ...userCopy, dateOfBirth: new Date(user?.dateOfBirth) });

        const secondSuccess = async (res) => {
          const completeDetails = {
            ...user,
            ...res,
            // id: res?.userId,
            officeAddress: res?.organizationAddress,
            dateOfBirth: new Date(user.dateOfBirth),
          };

          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              token,
              user: completeDetails,
            })
          );

          getUser(completeDetails, token).then((r) =>
            window.location.assign("/get-started")
          );
        };

        getUserDetails(user?.id, secondSuccess, token);
      },
    });
  };

  const onOTP = (e) => {
    e.preventDefault();

    let reqBody = {
      emailOrPhoneNumber,
      otp,
      password,
    };


    axios({
      method: "post",
      url: `${twoFA}/${state.emailOrPhoneNumber}`,
      data: reqBody,
    })
      .then((res) => {
        
        if (res.data.status === true) {
          setShow2Fa(true);

        } else {
          if(res.data?.message ===" 2FA not active for this user"){
            onLogin();
          }
        }
      })
      .catch((err) => {
        onLogin();
        console.log(err);
      
      });

    /*  apiCall({
      data: reqBody,
      url: `${twoFA}${state.emailOrPhoneNumber}`,
      method: "post",
      setLoading,
      error: (err) => setState({ ...state, message: err }),
      success: (data) => {
        if (data.status) {
          setShow2Fa(true);
        } else {
          if (data?.message == " 2FA not active for this user") {
            onLogin();
          } else {
            toast.error(data?.message);
          }
        }
      },
    }).catch((err) => {
      console.log(err);
      onLogin();
    
    });;*/
  };
  useEffect(() => {
     window.location.href=`${process.env.REACT_APP_WAYABANK_WEBSITE}/login?client=wayapos`
    //  window.location.href='http://localhost:3001/login?client=wayapos'
}, []);
  const onOTP1 = () => {
    axios.post(`${twoFA}${state.emailOrPhoneNumber}`).then((res) => {
      console.log(res);
      setState((state) => ({
        ...state,
        twoFa: true,
      }));
    });
  };
  return (
    <>
      {show2Fa ? (
        <PinModal
          handleSubmit={onLogin}
          setOTP={setOTP}
          OTP={OTP}
          showModal={show2Fa}
          hideModal={setShow2Fa}
        />
      ) : (
        ""
      )}
      {refreshing ? (
        <div className="row h-100vh">
          <div className="d-flex justify-content-center align-items-center">
            <Box>
              <Loader />
            </Box>
          </div>
        </div>
      ) : (
        <div>
          {otp ? (
            <VerifyOtp phoneOrEmail={emailOrPhoneNumber} />
          ) : (
            <AuthComponent>
              <AuthContainer>
                <div className="login-header">
                  <h4 className="fs-24 fw-500 login-text">Welcome Back</h4>
                </div>

                <div className="mt-40 full-width">
                  <div className="d-flex justify-content-center">
                    <form onSubmit={onOTP}>
                      <div className="input-div full-width">
                        <label className="text-default fs-14">
                          Email or Phone number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="inputnoBorder input-container">
                          <input
                            className="input py-3 ps-2"
                            type="text"
                            onChange={onChange}
                            name="emailOrPhoneNumber"
                            required
                          />
                        </div>
                      </div>

                      <div className="input-div full-width">
                        <label className="text-default fs-14">
                          Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="d-flex inputnoBorder input-container">
                          <input
                            className="input ps-2"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            required
                            onChange={onChange}
                          />
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </IconButton>
                        </div>
                      </div>

                      <button className="mt-20 orange-button full-width">
                        {loading ? (
                          <SubmitLoader className="left-43" />
                        ) : (
                          "Log in"
                        )}
                      </button>

                      <div className="text-center mt-20">
                        {message === "Account not Verified" ? (
                          <h4 className="fs-16 fw-400 text-default">
                            <span className="mx-1">
                              {" "}
                              Have not Verify your account?
                            </span>
                            <span
                              className="text-orange cursor-pointer"
                              onClick={() =>
                                setState((state) => ({
                                  ...state,
                                  otp: true,
                                }))
                              }
                            >
                              Verify Now
                            </span>
                            <div className="text-center">
                              <span className="mx-1">Or</span>
                              <span
                                className="text-orange cursor-pointer"
                                onClick={() => navigate("/register")}
                              >
                                {" "}
                                Start Afresh{" "}
                              </span>
                            </div>
                          </h4>
                        ) : (
                          <h4 className="fs-16 fw-400 text-default">
                            Don't have an account?{" "}
                            <span
                              className="text-orange cursor-pointer"
                              onClick={() => navigate("/register")}
                            >
                              {" "}
                              Sign up instead{" "}
                            </span>
                          </h4>
                        )}
                        {/*<h4 className="fs-16 fw-400 text-default">Don't have an account? <span className="text-orange cursor-pointer" onClick={()=>navigate('/register')}> Sign up instead </span></h4>*/}
                        <h4
                          className="mt-20 fs-16 text-orange fw-400 cursor-pointer"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot your password?
                        </h4>
                      </div>
                    </form>
                  </div>
                </div>
              </AuthContainer>

              {/* expired credentials */}

              <ChangePinModal state={authUser?.isCredentialsExpired}>
                <div className="row justify-content-center text-center">
                  <div className="fs-24 text-info">Please update Password</div>
                  <p>
                    Your password is more than 3 months old, kindly update your
                    password to enable us protect your account
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="orange-button mt-5"
                    onClick={() =>
                      navigate("/forgot-password/?change-password")
                    }
                  >
                    Okay
                  </button>
                </div>
              </ChangePinModal>
            </AuthComponent>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
