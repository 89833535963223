import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import React, { useState } from 'react';
// @mui
import {
  Box,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import MenuPopover from '../../MenuPopover';
import { IconButtonAnimate } from '../../Animate';
import Iconify from "../../Iconify";

// ----------------------------------------------------------------------

const _notifications = [...Array(5)].map((_, index) => ({
    id: index,
    title: ['Your order is placed', 'Sylvan King', 'You have new message', 'You have new mail', 'Delivery processing'][
        index
        ],
    description: [
        'waiting for shipping',
        'answered to your comment on the Minimal',
        '5 unread messages',
        'sent from Guido Padberg',
        'Your order is being shipped'
    ][index],
    avatar: [null, null, null, null][index],
    createdAt: new Date(),
    isUnRead: [true, true, false, false, false][index]
}));

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState(_notifications);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const nav = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
        notifications.map((notification) => ({
          ...notification,
          isUnRead: false
        }))
    );
  };

  return (
      <>
        <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="eva:bell-outline" width={20} height={20} />
          </Badge>
        </IconButtonAnimate>

        <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                You have {totalUnRead} unread notifications
              </Typography>
            </Box>

            {totalUnRead > 0 && (
                <Tooltip title=" Mark all as read">
                  <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                    <Iconify icon="eva:done-all-fill" width={20} height={20} />
                  </IconButtonAnimate>
                </Tooltip>
            )}
          </Box>


          <Box sx={{ p: 1 }}>
            <Button fullWidth disableRipple onClick={()=>{
              handleClose()
              nav('/notifications')
            }}>
              View All
            </Button>
          </Box>
        </MenuPopover>
      </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    // type: PropTypes.string,
    avatar: PropTypes.any
  })
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
      <ListItemButton
          sx={{
            py: 1.5,
            px: 2.5,
            mt: '1px',
            ...(notification.isUnRead && {
              bgcolor: 'action.selected'
            })
          }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={title}
            secondary={
              <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.disabled'
                  }}
              >
                <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                {fToNow(notification.createdAt)}
              </Typography>
            }
        />
      </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
      <Typography variant="subtitle2">
        {notification.title}
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp; {noCase(notification.description)}
        </Typography>
      </Typography>
  );

  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //         <img
  //             alt={notification.title}
  //             src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
  //         />
  //     ),
  //     title
  //   };
  // }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title
  };
}
