import React from "react";

const MerchantForm = () => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSep-qSZsydUfmBikvuERqjD34_g2g-DgxG4pMPGuhUx1pNRzA/viewform?embedded=true"
      width="800"
      height="900"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    />
  );
};

export default MerchantForm;
