// @mui
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NumberFormat from "react-currency-format";

import Loader from "../../../../../components/Loader";
import { makeFullName } from "../../../../../utilities/stringOperations";
import ProfileAvatarComponent from "./ProfileAvatarComponent";
import { useEffect, useState } from "react";
import useUser from "components/Hooks/useUser";
import { getBusinessList, getBusinessTypes } from "plugins/urls";
import { apiCall } from "utilities/apis";

export default function UpdateProfileForm({ formData, setFormData, updating }) {
  const [page, setPage] = useState(1);
  const [businessTypes, setBusinessTypes] = useState([]);
  const { user } = useUser();
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfBirth: date,
      edited: {
        ...formData.edited,
        dateOfBirth: date,
      },
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      edited: {
        ...formData.edited,
        [name]: value,
      },
    });
  };

  const getBusinessType = () => {
      apiCall({
        url: `${getBusinessList}?page=0&size=100`,
        method: "get",
        // setLoading,
        success: (resp) => {
          const data = resp.businessTypeList;
          setBusinessTypes(data);
        },
      });
    };
  useEffect(() => {
    getBusinessType()
  }, []);
  return updating ? (
    <Stack spacing={3}>
      <Loader />
    </Stack>
  ) : (
    <Stack spacing={3}>
      <ProfileAvatarComponent
        name={makeFullName([formData.firstName, formData.surname])}
        hideName
        upload
      />
      {page === 1 ? (<>
        <TextField
          value={formData.firstName}
          onChange={handleChange}
          name="firstName"
          label={
            <p
              style={{
                fontSize: ".8rem",
                textTransform: "uppercase",
                color: "#FF6700",
                fontWeight: "700",
              }}
            >
              first name
            </p>
          }
          variant="standard"
        />

      <TextField
        value={formData.surname}
        onChange={handleChange}
        name="lastName"
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            last name
          </p>
        }
        variant="standard"
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={
            <p
              style={{
                fontSize: ".8rem",
                textTransform: "uppercase",
                color: "#FF6700",
                fontWeight: "700",
              }}
            >
              date of birth
            </p>
          }
          inputFormat="MM/dd/yyyy"
          name="dateOfBirth"
          value={formData.dateOfBirth}
          onChange={handleDateChange}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          date={new Date(formData.dateOfBirth)}
        />
      </LocalizationProvider>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">
          {
            <p
              style={{
                fontSize: ".8rem",
                textTransform: "uppercase",
                color: "#FF6700",
                fontWeight: "700",
              }}
            >
              gender
            </p>
          }
        </InputLabel>
        <Select
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          label="Gender"
        >
          <MenuItem value="">
            <em>Select gender</em>
          </MenuItem>
          <MenuItem value="MALE">Male</MenuItem>
          <MenuItem value="FEMALE">Female</MenuItem>
        </Select>
      </FormControl>
      <NumberFormat
        value={formData.phoneNumber}
        onChange={handleChange}
        name="phoneNumber"
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            phone no
          </p>
        }
        variant="standard"
        customInput={TextField}
        format="+ (###) - (0)## - #### - ####"
        mask="_"
      />
      <TextField
        value={formData.officeAddress}
        onChange={handleChange}
        name="officeAddress"
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            address
          </p>
        }
        variant="standard"
      />
      </>) : (
        <>
            {user?.cacBusiness ? (
              <>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {
                  <p
                    style={{
                      fontSize: ".8rem",
                      textTransform: "uppercase",
                      color: "#FF6700",
                      fontWeight: "700",
                    }}
                  >
                    Business Type
                  </p>
                }
              </InputLabel>
              <Select
                name="businessType"
                value={formData.businessType ?? user?.otherDetails?.businessType}
                onChange={handleChange}
                label="organisationType"
              >
                <MenuItem value="">
                  <em>Select business type</em>
                </MenuItem>
                <MenuItem value="RC">RC</MenuItem>
                <MenuItem value="BN">BN</MenuItem>
                <MenuItem value="IT">IT</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={formData.cacRegistrationNumber ?? user?.cacRegistrationNumber}
              onChange={handleChange}
              name="cacRegistrationNumber"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  CAC Number
                </p>
              }
              variant="standard"
            /> 
            </>) : (
              ''
            )}
            <TextField
              value={formData.organisationName ?? user?.otherDetails?.organisationName}
              onChange={handleChange}
              name="organisationName"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business Name
                </p>
              }
              variant="standard"
            />
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {
                  <p
                    style={{
                      fontSize: ".8rem",
                      textTransform: "uppercase",
                      color: "#FF6700",
                      fontWeight: "700",
                    }}
                  >
                    Organization Type
                  </p>
                }
              </InputLabel>
              <Select
                name="organisationType"
                value={formData.organisationType ?? user?.otherDetails?.organisationType}
                onChange={handleChange}
                label="organisationType"
              >
                <MenuItem value="">
                  <em>Select Org Type</em>
                </MenuItem>
                {businessTypes?.map((e) =>
                  <MenuItem key={e.id} value={e?.businessType}>
                    {e?.businessType}
                  </MenuItem>
                )}
                <MenuItem value="FEMALE">Female</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={formData.organisationEmail ?? user?.otherDetails?.organisationEmail}
              onChange={handleChange}
              name="organisationEmail"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business Email
                </p>
              }
              variant="standard"
            />
            <TextField
              value={formData.organisationPhone ?? user?.otherDetails?.organisationPhone}
              onChange={handleChange}
              name="organisationPhone"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business Phone
                </p>
              }
              variant="standard"
            />
            <TextField
              value={formData.organizationState ?? user.otherDetails?.organizationState}
              onChange={handleChange}
              name="organizationState"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business State
                </p>
              }
              variant="standard"
            />
            <TextField
              value={formData.organizationCity ?? user.otherDetails?.organizationCity}
              onChange={handleChange}
              name="organizationCity"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business City
                </p>
              }
              variant="standard"
            />
            <TextField
              value={formData.organizationAddress ?? user.otherDetails?.organizationAddress}
              onChange={handleChange}
              name="organizationAddress"
              label={
                <p
                  style={{
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "#FF6700",
                    fontWeight: "700",
                  }}
                >
                  Business Office Address
                </p>
              }
              variant="standard"
            />
        </>
      )}
      {page === 1 ? (
        <button onClick={() => setPage(2)}>Next {` >>`}</button>
      ): (
        <button onClick={() => setPage(1)}>{`<< `}Previous</button>
      )}
    </Stack>
  );
}
