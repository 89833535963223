import {apiCall} from "../../../utilities/apis";
import {getUserById} from "../../../plugins/urls";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import EllipsisLoader from "../../../components/SubmitLoader/ElipseLoader";
import CardImg from "react-bootstrap/CardImg";
import {FaEye} from "react-icons/fa";

const NotificationDetails = ({notification, deleteNow, close, load}) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});


//  useEffect(() => {
//      const id = parseInt(notification?.initiator, 10) < 1 ? notification?.recipient : notification?.initiator;
//      notification?.initiator && apiCall({
//         url: `${getUserById}${id}`, method: 'get', setLoading, success: (data) => setUser(data?.data)
//     });
// }, [notification]);
    return (
        <div className="">
            {
                loading
                ? <Loader />
                : (
                    <div>
                        {
                            load
                            ? <EllipsisLoader/>
                            :  (
                                    <div>
                                        <div className='fs-16 text-center py-3 text-orange'>{notification?.message?.replace('account, click to view details.', 'account with the details below:')}</div>
                                        <div className='fs-16 text-center py-3 text-orange'>
                                            <CardImg src={notification?.initiatorImage} alt='Initiator image' />
                                        </div>
                                        <div className='fs-16 text-center py-3 text-orange'>
                                            {notification?.content}
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <div>
                                                    <p><span className="text-muted">Sender:</span> <span className="text-darker">{notification?.initiatorUsername}</span></p>
                                                    <p> <span className="text-muted">Transaction Ref:</span> <span className="text-darker">{notification?.tranid}</span></p>
                                                    <p> <span className="text-muted">Notification Ref:</span> <span className="text-darker">{notification?.notificationId}</span></p>
                                                    <p><span className="text-muted"> Remarks:</span> <span className="text-darker">{notification?.narration}</span></p>
                                                </div>

                                                <div className="mt-5">
                                                    <div className='text-danger'>Are you sure you want to delete this notification?</div>
                                                    <div className="mt-3 d-flex justify-between">
                                                        <div className="d-flex">
                                                            <button className="button-success" onClick={close}>Cancel</button>
                                                            <button className="orange-button ml-4" onClick={() => deleteNow(notification.notificationId)}>Yes, Delete Now</button>
                                                        </div>
                                                        <div className='float-end' title='Seen'> <FaEye /></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                        }
                    </div>
                    )
            }
        </div>
    )
};

export default NotificationDetails;
