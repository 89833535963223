import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Slide, toast } from "react-toastify";
import useUser from "../../../../../../components/Hooks/useUser";
import {
  checkKycStatus,
  getTierLimits,
  getTierRequirements,
  getUserKyc,
  getUserKycStatus,
  saveUserKyc,
  uploadUserFile,
  wayaKYC,
  wayaKycStatus,
  wayaPassUpload,
  wayaUpload,
} from "../../../../../../plugins/urls";
import { apiCall } from "../../../../../../utilities/apis";
import { arrayToObject } from "../../../../../../utilities/arrayOperations";
import {
  form1Requirements,
  form2Requirements,
  form3Requirements,
  form4Requirements,
} from "../../../../../../utilities/dummyData";
import {
  formatDonation,
  formatDonation2,
  sentenceCaps,
  slugify,
  slugToString,
  stringToCamel,
} from "../../../../../../utilities/stringOperations";
import KYCCorporateForm5 from "../../components/KYCCorporateForm5";
import KYCCorporateInputForm from "../../components/KYCCorporateInputForm";
import KYCCorporateUploadForm from "../../components/KYCCorporateUploadForm";
import Tier2 from "./tier2";
import Tier3 from "./tier3";
import Tier4 from "./tier4";
import Tier1 from "./tier1";
import UploadShareHolder from "./uploadShareHolders";
import UploadDirectors from "./uploadDirectors";

const useKyc = ({ level }) => {
  const { user } = useUser();

  const [formData, setFormData] = useState(user.otherDetails);
  const [tier, setTier] = useState(level || 1);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingBvn, setLoadingBvn] = useState(false);
  const [uploading, setUploading] = useState({});
  const [limits, setLimits] = useState({});
  const [disabledBvn, setDisabledBvn] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [status, setStatus] = useState({});
  const [openBvn, setOpenBvn] = useState(false);
  const [bvn, setBvn] = useState("");
  const [bvnData, setBvnData] = useState("");
  const [postData, setPostData] = useState({});
  const [file, setFile] = useState('');
  const [showModal, hideModal] = useState(false);
  const [kycStatus, setKycStatus] = useState([]);
  const [docs, setDocs] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [passport, setPassport] = useState([]);
  const [id, setId] = useState([]);
  const [utility, setUtility] = useState([]);
  const [cac, setCac] = useState([]);
  const [tin, setTin] = useState([]);
  const [reference, setReference] = useState([]);
  const [viewShareholders, setViewShareholders] = useState(true);
  const [cacTier1, setCacTier1] = useState({});
  const [cacTier2, setCacTier2] = useState({});
  const [cacTier3, setCacTier3] = useState({});
  const [cacTier4, setCacTier4] = useState({});
  const [directors, setDirectors] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [shareHolders, setShareHolders] = useState([]);
  const [sPage, setSPage] = useState(0);
  const [dirStatus, setDirStatus] = useState({});
  const [dPage, setDPage] = useState(0);
  const [showBusReg, setShowBusReg] = useState(false);
  const [cacData, setCacData] = useState([]);

  // api calls
  const getDocuments = () => {
    apiCall({
      url: `${wayaKYC}api/v1/kyc/cac/fetchAllBusinessDocuments/${user.id}/${user?.profileSubscription.profileId}`,
      method: "get",
      setLoading,
      success: (resp) => {
        const data = resp.data;
        setDocuments(data);
      },
    });
  };
  // api calls
  const getKycStatus = () => {
    apiCall({
      url: `${wayaKycStatus}${user?.id}`,
      method: "post",
      setLoading,
      success: (resp) => {
        const data = resp.data;
          setDocs(data);
          const email1 = data?.tier1?.filter(
            (e) => e?.requirementType === 'EMAIL'
          )[0];
          const phone1 = data?.tier1?.filter(
            (e) => e?.requirementType === 'PHONE_NUMBER'
          )[0];
          const bvn1 = data?.tier2?.filter(
            (e) => e?.requirementType === 'BVN'
          )[0];
          const passport1 = data?.tier3?.filter(
            (e) => e?.requirementType === 'PHOTO'
          )[0];
          const id1 = data?.tier3?.filter(
            (e) => e?.requirementType === 'ID'
          )[0];
          const utility1 = data?.tier3?.filter(
            (e) => e?.requirementType === 'UTILITY'
          )[0];
          const cac1 = data?.tier4?.filter(
            (e) => e?.requirementType === 'CAC'
          )[0];
          const tin1 = data?.tier4?.filter(
            (e) => e?.requirementType === 'TIN'
          )[0];
          const reference1 = data?.tier4?.filter(
            (e) => e?.requirementType === 'REFERENCE'
          )[0];          
          setEmail(email1);
          setPhone(phone1);
          setBvn(bvn1);
          setPassport(passport1);
          setId(id1);
          setUtility(utility1);
          setCac(cac1);
          setTin(tin1);
          setReference(reference1);
          setKycStatus(data);
      },
    });
  };
  const getCacKyc = async () => {
    axios
      .get(`${wayaKYC}api/v1/kyc/cac/fetchAllBusinessKycData/${user?.id}/${user?.profileSubscription?.profileId}`)
      .then((res) => {
        if (res?.data?.status) {
          if (res?.data?.data?.length < 1 && user?.cacBusiness) {
            setShowBusReg(true);
          }
          if (res?.data?.data) {
            const tier1 = res?.data?.data?.filter(
              (e) => e?.kycTier?.name === 'TIER_1'
            )[0];
            const tier2 = res?.data?.data?.filter(
              (e) => e?.kycTier?.name === 'TIER_2'
            )[0];
            const tier3 = res?.data?.data?.filter(
              (e) => e?.kycTier?.name === 'TIER_3'
            )[0];
            setCacTier1(tier1);
            setCacTier2(tier2);
            setCacTier3(tier3);
            setCacData(res?.data?.data)
          } else {
            console.log(res);
          }
        }
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log(err, "kyc err");
      });
  };
  const getBvnStatus = () => {
    apiCall({
      url: `${checkKycStatus}${user?.id}`,
      method: "get",
      setLoading,
      success: (resp) => {
        const data = resp.data;
        console.log('bvndata', data)
        setBvnData(data);
        setBvn(data?.bvn ?? data);
      },
    });
  };


  const getShareHolders = () => {
    axios
      .get(`${wayaKYC}api/v1/kyc/cac/fetchAllShareHolders/${cacTier3?.businessRequirementId}`)
      .then((res) => {
        if (res?.data?.status) {
          if (res?.data?.data) {
            setShareHolders(res?.data?.data);
          } else {
            console.log(res);
          }
        }
        
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log(err, "kyc err");
      });
  };

  const getDirectors = () => {
    axios
      .get(`${wayaKYC}api/v1/kyc/cac/fetchAllDirectors/${cacTier3?.businessRequirementId}`)
      .then((res) => {
        if (res?.data?.status) {
          if (res?.data?.data) {
            setDirectors(res?.data?.data);
          } else {
            console.log(res);
          }
        }
        
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log(err, "kyc err");
      });
  };
  const getCurrentTierKyc = () => {
    apiCall({
      url: `${getTierRequirements}?customerId=${user?.id}&tierName=tier${tier}`,
      // data: {tierName: `tier${tier}`, customerId: user.id},
      // data: {tierName: `tier${tier}`, customerId: 41},
      method: "post",
      setLoading,
      success: (resp) => {
        const data = resp.data;
        // transform to object
        const result = arrayToObject({ array: data, key: "reqItem" });
        // transform to stringified json to object
        result.allData.reqValue = JSON.parse(result.allData.reqValue);
        // use result
        setFormData({
          ...result,
        });
      },
    });
  };

  const saveKyc = () => {
    const item =
      tier === 2
        ? { reqItem: "BVN", reqValue: formatDonation(formData.BVN) }
        : {
            ...formData.allData,
            reqValue: JSON.stringify(formData.allData.reqValue),
          };

    const data = {
      customerId: user.id,
      reqItem: item.reqItem,
      reqValue: item.reqValue,
      tierName: `tier${tier}`,
      status: "Under Review",
    };

    apiCall({
      url: saveUserKyc,
      data,
      method: "post",
      setLoading,
      success: (resp) => {
        const data = resp.data;
        // console.log(data);
      },
    });
  };

  const uploadDoc = async (e) => {
    const reqItem = []
    console.log('posss', postData,)
     console.log('titlee', postData.title);
    const data = new FormData();
    //data.append(stringToCamel(reqItem, "_"), formData[reqItem]);
    data.append('file', e);
    // data.append('fileName', reqItem);
    // data.append('userId', user.id);

    const uploading = (status) =>
      setUploading({ ...loading, [reqItem]: status });
    const switchUrl = () => {
      let queryItem = "";
      switch (reqItem) {
        case "NATIONAL_IDENTITY":
          queryItem = "nin";
          break;

        case "CAC_DOCUMENT":
          queryItem = "cac-certificate";
          break;
        default:
          queryItem = reqItem;
      }
      return queryItem;
    };
   
    if (postData.title === 'ID'){
      console.log('idddddddddd')
      // data.append('fileName', postData?.idType==='interPass'? 'INTERNATIONAL_PASSPORT'
      // : postData?.idType==='nationalId'? 'NIN'
      // : postData?.idType==='votersCard'? 'VOTERS_CARD'
      // : postData?.idType==='driversLicence'? 'DRIVER_LICENSE'
      // : '');
      data.append('fileName', 'NIN');
      data.append('id', id?.id);
      data.append('identificationNumber', postData?.identificationNumber)
      apiCall({
        url: `${wayaUpload}${user?.id}`,
        method: "post",
        data,
        setLoading: uploading,
        success: (resp) => {
          if(resp?.status){
            hideModal(false);
            getKycStatus();
          toast.success( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        } else {
          toast.error( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      });

    } else if (postData.title == 'utility') {
      let d = new Date();
        data.append('fileName', 'UTILITY');
        data.append('identificationNumber', d.getTime());
        data.append('id', utility?.id);
        apiCall({
          url: `${wayaUpload}${user?.id}`,
          method: "post",
          data,
          setLoading: uploading,
          success: (resp) => {
            if(resp?.status){
              hideModal(false);
              getKycStatus();
            toast.success( resp?.message ?? resp?.message, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            })  
          } else {
            toast.error( resp?.message ?? resp?.message, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            })  
          }     
        },
        });  
    } else if (postData.title === 'photograph') {
      let d = new Date();
      data.append('fileName', 'PHOTO');
      data.append('identificationNumber', d?.getTime());
      data.append('id', passport?.id);

      apiCall({
        url: `${wayaUpload}${user?.id}`,
        method: "post",
        data,
        setLoading: uploading,
        success: (resp) => {
          if(resp?.status){
            hideModal(false);
            getKycStatus();
          toast.success( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        } else {
          toast.error( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      });  
  }else if (postData.title === 'tin') {
    data.append('fileName', 'TIN');
    data.append('id', tin?.id);
    data.append('identificationNumber', postData?.identificationNumber)
    apiCall({
      url: `${wayaUpload}${user?.id}`,
      method: "post",
      data,
      setLoading: uploading,
      success: (resp) => {
        if(resp?.status){
          hideModal(false);
          getKycStatus();
        toast.success( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        })  
      } else {
        toast.error( resp?.message ?? resp?.message, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        })  
      }     
    },
    });  
}else if (postData.title === 'cac') {
  data.append('fileName', 'CAC');
  data.append('id', cac?.id);
  data.append('identificationNumber', postData?.identificationNumber)
  apiCall({
    url: `${wayaUpload}${user?.id}`,
    method: "post",
    data,
    setLoading: uploading,
    success: (resp) => {
      if(resp?.status){
        hideModal(false);
        getKycStatus();
      toast.success( resp?.message ?? resp?.message, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      })  
    } else {
      toast.error( resp?.message ?? resp?.message, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      })  
    }     
  },
  });  
}
else if (postData.title === 'reference') {
  data.append('fileName', postData?.idType);
  data.append('id', reference?.id);
  data.append('identificationNumber', postData?.identificationNumber)
  apiCall({
    url: `${wayaUpload}${user?.id}`,
    method: "post",
    data,
    setLoading: uploading,
    success: (resp) => {
      if(resp?.status){
        hideModal(false);
        getKycStatus();
      toast.success( resp?.message ?? resp?.message, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      })  
    } else {
      toast.error( resp?.message ?? resp?.message, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      })  
    }     
  },
  });  
}
/* else {
    apiCall({
      url: `${uploadUserFile}${user?.id}/${slugify(
        slugToString(switchUrl())?.toLowerCase(),
        "-"
      )}?fileName=${stringToCamel(reqItem, "_")}`,
      method: "post",
      data,
      setLoading: uploading,
      success: (resp) => {
        setFormData({
          ...formData,
          [reqItem]: {
            reqValue: `${sentenceCaps(
              slugToString(reqItem?.toLowerCase())
            )} saved`,
          },
        });
      },
    });
  }*/
  };

  // components logic
  const onChange = (file) => {
    setFile(file?.target?.value);
    console.log('file',file?.target?.value);
    //set
    const { name, value } = file.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], reqValue: value },
    });
  };
  const handleChange2 = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      allData: {
        ...formData.allData,
        reqValue: {
          ...formData.allData.reqValue,
          [name]: value,
        },
      },
    });
  };

  const previousTier = () => {
    tier > 0 ? setTier(tier - 1) : setError({ tier: " This is the base tier" });
    // todo: call kyc for the current tier.
  };

  const nextTier = () => {
    tier < 5
      ? setTier(tier + 1)
      : setError({ tier: " This is the highest tier" });
    // todo: call kyc for the current tier.
  };

  const verify = (name) => {
    // setOpenBvn(true)
    const callStacks = {
      BVN: () => setOpenBvn(true),
      // BVN: () => apiCall({
      //     url: `${bvnToken}${formatDonation(formData.BVN?.reqValue)}`, method: 'post', setLoading: setLoadingBvn, success: (data) => setOpenBvn(true)
      // })
    };
    callStacks[name]();
  };
  const getUserKYCTierLevel = useCallback(() => {
    apiCall({
      method: "get",
      url: `${getUserKycStatus}${user.id}`,
      success: (data) => {
        const index = tier - 1 >= 0 ? tier - 1 : 0;
        const processed = data?.data[index]?.processFlg;
        const level = data?.data[index]?.tiers?.id;
        let tiers = data.data;
        let tierEnts = {
          [`tier${tiers[0]?.tiers?.id}`]: tiers[0]?.processFlg,
          [`tier${tiers[1]?.tiers?.id}`]: tiers[1]?.processFlg,
          [`tier${tiers[2]?.tiers?.id}`]: tiers[2]?.processFlg,
          [`tier${tiers[3]?.tiers?.id}`]: tiers[3]?.processFlg,
        };
        setStatus({ ...tierEnts });
        if (level < 4 && !processed) {
          setDisableNext(true);
        } else {
          setDisabledBvn(false);
        }
      },
      setLoading,
    });
  }, []);
  useEffect(() => {
    formatDonation2(formData?.BVN).length === 11
      ? setDisabledBvn(false)
      : setDisabledBvn(true);
  }, [formData]);

  useEffect(() => {
    setBvn(formData?.BVN?.reqValue);
  }, [formData?.BVN, openBvn]);

  useEffect(() => {
    // getCurrentTierLimits();
    // getBvnStatus();
    getKycStatus();
    // getCurrentTierKyc();
    // kycStatus();
    // getUserKYCTierLevel();
    // getCurrentKyc();
  }, [tier]);

  useEffect(() => {
    getCacKyc();
  }, [])
  useEffect(() => {
    if (user?.cacBusiness && cacTier3?.businessRequirementId) {
    getShareHolders();
    getDirectors();
    getDocuments();
    }
  }, [cacTier3])
  const displayForm = [
    "beyond reach",
    /*<KYCCorporateInputForm
      formData={formData}
      requirements={form1Requirements}
      verify={verify}
      handleChange={handleChange}
      loading={loading}
    />,*/
    <Tier1
    formData={formData}
    requirements={form1Requirements}
    verify={verify}
    handleChange={handleChange}
    loading={loading}
    kycStatus={kycStatus}
    getKycStatus={getKycStatus}
    />,
    /*<KYCCorporateInputForm
      formData={formData}
      requirements={form2Requirements}
      verify={verify}
      handleChange={handleChange}
      loadingBvn={loadingBvn}
      loading={loading}
      setOpenBvn={setOpenBvn}
      openBvn={openBvn}
      bvn={bvn}
    />,*/
    <Tier2    
    kycStatus={kycStatus}
    getKycStatus={getKycStatus}
    />,
    !user.cacBusiness ? <Tier3
    uploadDoc={uploadDoc}
    postData={postData}
    setPostData={setPostData}
    showModal={showModal}
    hideModal={hideModal}
    kycStatus={kycStatus}
    /> : <>
    <div style={{ flexDirection: 'row', textAlign: 'left' }}>
      <button
        type="button"
        className="btn"
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
          background: viewShareholders ? '#FF6700' : '',
          borderWidth: '2px',
          borderColor: viewShareholders ? '#FF6700' : 'gray',
          color: viewShareholders ? '#fff' : '#000',
        }}
        onClick={() => setViewShareholders(true)}
      >
        SHAREHOLDERS
      </button>
      <button
        type="button"
        className="btn"
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
          background: viewShareholders ? '' : '#FF6700',
          borderWidth: '2px',
          borderColor: viewShareholders ? 'gray' : '#FF6700',
          color: viewShareholders ? '#000' : '#fff',
        }}
        onClick={() => setViewShareholders(false)}
      >
        DIRECTORS
      </button>
    </div>
    {viewShareholders ? (
      <UploadShareHolder
        sData={
          shareHolders?.length > 0 ? shareHolders[sPage] : {}}
        shareHolders={shareHolders}
        userId={user.id}
        businessId={cacTier3?.businessRequirementId}
        sPage={sPage}
        setSPage={setSPage}
        getShareHolders={getShareHolders}
        documents={documents}
        getDocuments={getDocuments}
      />
    ) : (
      <UploadDirectors
        sData={directors?.length > 0 ? directors[dPage] : {}}
        directors={directors}
        userId={user?.id}
        businessId={cacTier3?.businessRequirementId}
        sPage={dPage}
        setSPage={setDPage}
        getDirectors={getDirectors}
        documents={documents}
        getDocuments={getDocuments}
        setDirStatus={setDirStatus}
        dirStatus={dirStatus}
      />
    )}
  </>
,
    <Tier4
    uploadDoc={uploadDoc}
    postData={postData}
    setPostData={setPostData}
    showModal={showModal}
    hideModal={hideModal}
    kycStatus={kycStatus}
    getDocuments={getDocuments}
    documents={documents}
    businessId={cacTier3?.businessRequirementId}
    />,
    <></>,
    <></>,
    <Tier2
    />,
    /*<KYCCorporateInputForm
      formData={formData}
      requirements={form2Requirements}
      verify={verify}
      handleChange={handleChange}
      loadingBvn={loadingBvn}
      loading={loading}
      setOpenBvn={setOpenBvn}
      openBvn={openBvn}
      bvn={bvn}
  />,*/
    <KYCCorporateUploadForm
      formData={formData}
      uploadDoc={uploadDoc}
      setPostData={setPostData}
      postData={postData}
      onChange={onChange}
      requirements={form3Requirements}
      loading={loading}
      uploading={uploading}
    />,
    <KYCCorporateUploadForm
      formData={formData}
      uploadDoc={uploadDoc}
      onChange={onChange}
      setPostData={setPostData}
      postData={postData}
      requirements={form4Requirements}
      loading={loading}
      uploading={uploading}
    />,
    <KYCCorporateForm5
      formData={formData}
      handleChange={handleChange2}
      loading={loading}
    />,
  ];

  return {
    tier,
    previousTier,
    nextTier,
    saveKyc,
    displayForm,
    loading,
    loadingBvn,
    limits,
    disabledBvn,
    status,
    disableNext,
    kycStatus,
    bvnData,
    cacData,
    getCacKyc,
  };
};
export default useKyc;
