import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import WayaPos from "../../components/TransactionComponents/WayaPos";
import { ReactComponent as Arrow } from "../../assets/icons/arrows.svg";
import WayaAgency from "../../components/TransactionComponents/WayaAgency";
// import { apiCall } from "../../utilities/apis";
// import { searchTransactionByDate } from "../../plugins/urls";
import { useLocation, useNavigate } from "react-router-dom";
import FadeMenu from "pages/Settings/pages/updateAccount/components/FadeMenu";
import { apiCall } from "utilities/apis";
import { allTransactions, revenueStats, searchTransactionByParam, agStats, agCustomerStats, getAggregators, getAggCommission } from "plugins/urls";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import EllipsisLoader from "components/SubmitLoader/ElipseLoader";
import Cards from "components/Cards/Cards";
import TransactionCard from "components/Cards/TransactionCard";
import useSimpleTable from "components/Hooks/useSimpleTable";
import { aggCommissionTableCols, aggregatorFilters, aggregatorTableCols } from "utilities/dummyData";
import { AiFillEye } from "react-icons/ai";
import SimpleSearchBox from "components/UI/SimpleSearchBox";
import SimpleDateFilter from "components/UI/DateFilter";
import { camelToString, sentenceCaps } from "utilities/stringOperations";
import { IoFilterOutline } from "react-icons/io5";
import { ArrowBack } from "@mui/icons-material";

const AgDashbordInner = () => {
  const active = useLocation()?.state?.active;
  const navigate = useNavigate()
  const [menuEl, setMenuEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [loadingStats, setLoadingStats] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customerStats, setCustomerStats] = useState([]);
  const [state, setState] = useState({
    transactions: [],
    refunded: "",
    failed: "",
    abandoned: "",
    successful: "",
    gross_revenue: "",
    net_revenue: "",
    tab: "transactions",
  });
  const tableAction = async (e) => {
    console.log(e);
    // const statRes = httpGet await httpGet(`${bankReferral}/api/v1/fetchCustomerTransactionCommission/stats/${params?.row?.userId}`)
    apiCall({
      url: `${agCustomerStats}/${e.userId}`,
      method: "get",
      noError: true,
      defaultMeta: true,
      setLoading: setLoadingStats,
      success: (res) => {
        if (res.status) {          
          setCustomerStats(res?.data);
          setSelectedCustomer(e?.name);
          setShowPopup(true);
          console.log(res?.data);
        }
      },
    });
  }
  const { tableData, simpleTable, exportButton, searchPane } = useSimpleTable({
    setLoading,
    url: getAggregators,
    method: "get",
    includeMeta: true,
    filters: aggregatorFilters,
    filterUrl: searchTransactionByParam,
    //  filterCallMethod: "meth1",
    showSearchIcon: true,
    csvName: "Aggregators_",
    filterMethod: "get",
    dateFilterMenuName: "date",
    cols: aggregatorTableCols(
      tableAction,
      'View More')
    ,
    
    startCb: () => {
      getAgStats();
      getCustomerAggregator();
    },
  });

  const getAgStats = () =>
    apiCall({
      url: agStats,
      method: "get",
      noError: true,
      defaultMeta: true,
      setLoading: setLoadingStats,
      success: (res) => {
        if (res.status) {
          const {
            countFail,
            countSuccess,
            totalFail,
            totalReferred,
            totalRevenueGenerated,
            totalSuccess,
          } = res?.data;
          setState((state) => ({
            ...state,
            countFail,
            countSuccess,
            totalFail,
            totalReferred,
            totalRevenueGenerated,
            totalSuccess,
          }));
        }
      },
    });
  const getCustomerAggregator = () =>
    apiCall({
      url: getAggregators,
      method: "get",
      noError: true,
      defaultMeta: true,
      success: (res) => {
        if (res) {
          const { content } = res.data;
          setState((state) => ({
            ...state,
            aggreators: content,
          }));
        }
      },
    });


  const { tab } = state;

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <>
    {showPopup ? (
      <div style={{ width: '650px' }} className="px-5">
        <div className="btn" onClick={() => setShowPopup(false)} >
          <ArrowBack /> Back
        </div>
          <div className="grid grid-cols-1 md:grid-cols-2 h-auto px-5">
              <div className="bg-white card w-1/2 h-auto border px-6 border-gray-200 rounded-xl py-5">
              <div className="flex justify-around items-start px-8 py-3">
                      <div className="px-5">
                          <span className="text-xs text-gray-500">Customer</span>
                          <h3 className="text-sm font-semibold">{selectedCustomer}</h3>
                      </div>
                  </div>
                  
                  <div className="flex justify-between items-start px-5">
                      <span className="text-xs text-gray-500">Total Commission Generated</span>
                      <span className="text-xs font-semibold flex items-center gap-2 cursor-pointer" >
                          {customerStats?.totalReferred ?? 0}
                      </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-5 py-2">
                      <span className="text-xs text-gray-500">Total Agents Onboarded</span>
                      <span className="text-xs font-semibold flex items-center gap-2 cursor-pointer" >
                        NGN {customerStats?.totalRevenueGenerated}
                      </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-5 py-2">
                      <span className="text-xs text-gray-500">Total Transaction Count</span>
                      <span className="text-xs font-semibold flex items-center gap-2">
                          {customerStats?.countFail + customerStats?.countSuccess }
                      </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-5 ">
                      <span className="text-xs text-gray-500">Total Pending Commission</span>
                      <span className="text-xs font-semibold flex items-center gap-2">
                        NGN {customerStats?.totalFail ?? 0}</span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-5">
                      <span className="text-xs text-gray-500">Total Settled Commission</span>
                      <span className="text-xs font-semibold flex items-center gap-2">
                      NGN {customerStats?.totalSuccess ?? 0}                                </span>
                  </div>
                  <hr />
              </div>                            
          </div>
      </div> 
    ) : (
    <>
      <FadeMenu
        temp={
          <div className="p-3">
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                Download
              </Button>
            </div>
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                <a>Email</a>
              </Button>
            </div>
          </div>
        }
        open={Boolean(menuEl)}
        handleClose={() => setMenuEl(null)}
        anchorEl={menuEl}
      />

      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          {/* <div>{searchPane}</div> */}
        <div />

          {tableData?.content?.length > 0 && exportButton}
        </div>
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Onboarded Agents"
                value={state?.totalReferred ?? 0}
                color="text-orange"
                textColor="text-darker"
                noCurrency
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Transaction Count"
                value={state?.countFail + state?.countSuccess ?? 0}
                color="text-orange"
                textColor="text-darker"
                noCurrency
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Commission Generated"
                value={state.totalRevenueGenerated ?? 0}
                color="text-orange"
                textColor="text-darker"
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Pending Commission"
                value={state.totalFail ?? 0}
                color="text-white"
                textColor="text-white"
                bg="bg-orange"
              />
            </Col>
            <Col xs={12} md={6} xl={2} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Total Settled Commission"
                value={state.totalSuccess ?? 0}
                color="text-white"
                textColor="text-white"
                bg="bg-orange"
              />
            </Col>
          </Row>
        )}

        <div className="mt-5">
          {loading ? (
            <div className="align-items-center d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="success" />
            </div>
          ) : (
            <Container fluid>{simpleTable}</Container>
          )}
        </div>
      </Container>
    </>
    )}
  </>
  );
};

export default AgDashbordInner;
