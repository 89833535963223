import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {changeHandler} from "../../utilities/handlers";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import {apiCall} from "../../utilities/apis";
import {createAuthDisputes, createOtherDispute, createWayaDispute} from "../../plugins/urls";
import useUser from "../Hooks/useUser";
import {uploadModal} from "../../pages/Settings/pages/updateAccount/screens/KYC/temps";
import {Slide, toast} from "react-toastify";
import NumberFormat from "react-currency-format";
import {formatDonation2} from "../../utilities/stringOperations";

const CreateOtherDispute = ({close}) => {
	const user = useUser();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const handleChange = (e) => changeHandler({e, setFormData});

	const submit = () => {
		const data = new FormData();
			data.append('attachment', formData?.attachment);
		apiCall({
			url: `${createOtherDispute}?category=${formData?.category}&amount=${formatDonation2(formData?.amount)}&description=${formData?.description}&disputeType=${formData?.disputeType}&thirdPartyType=${formData?.thirdPartyType}`,
			data, defaultMeta: true,
			method: 'post', setLoading, success: (data) => {
				toast.success('Dispute successfully logged', {
					transition: Slide,
					hideProgressBar: true,
					autoClose: 3000, onClose: close
				})
			}
		});
	};
	return (
		<div
			className='px-2 mx-auto pb-4'
			style={{
				width: '80%',
				fontFamily: 'Lato',
				fontStyle: 'normal',
				color: '#4F4F4F',
			}}>

			Payments
			Social
			Auth & Notifications
			Others

			<FormControl variant='outlined' className='col-12 my-2'>
				<InputLabel id='demo-simple-select-outlined-label'>
					Dispute Category
				</InputLabel>
				<Select
					name='category'
					value={formData.category}
					onChange={handleChange}
					label='Dispute Category'
				>
					<MenuItem value=''>
						<em>Select Category of this Dispute</em>
					</MenuItem>

					<MenuItem value='Payments'>Payments</MenuItem>
					<MenuItem value='Social'>Social</MenuItem>
					<MenuItem value='Auth & Notifications'>Auth & Notifications</MenuItem>
					<MenuItem value='Others'>Others</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant='outlined' className='col-12 my-2'>
				<InputLabel id='demo-simple-select-outlined-label'>
					Dispute Type
				</InputLabel>
				<Select
					name='disputeType'
					value={formData.disputeType}
					onChange={handleChange}
					label='Dispute Type'
				>
					<MenuItem value=''>
						<em>Select Type of Dispute</em>
					</MenuItem>

					<MenuItem value='Chargeback'>Charge back</MenuItem>
					<MenuItem value='Fraud'>Fraud</MenuItem>
					<MenuItem value='Complaint'>Complaint</MenuItem>
					<MenuItem value='Unsettled_transaction'>Unsettled Transaction</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant='outlined' className='col-12 my-2'>
				<InputLabel id='demo-simple-select-outlined-label'>
					Third-Party Type
				</InputLabel>
				<Select
					name='thirdPartyType'
					value={formData.thirdPartyType}
					onChange={handleChange}
					label='Third-Party Type'
				>
					<MenuItem value=''>
						<em>Select Third-Party Type</em>
					</MenuItem>

					<MenuItem value='UP'>UP</MenuItem>
					<MenuItem value='NIBBS'>NIBBS</MenuItem>
					<MenuItem value='OTHERS'>OTHERS</MenuItem>
				</Select>
			</FormControl>
			<NumberFormat className="w-100 my-3" value={formData?.amount}  onChange={handleChange} name="amount" label="Disputed Amount" variant="outlined" customInput={TextField} thousandSeparator prefix='&#8358;'  />
			<TextField
				className='col-12 my-2'
				value={formData.attachment?.path}
				// onChange={handleChange}
				onClick={() => setOpen('attachment')}
				name='attachment'
				label='Attachment'
				variant='outlined'
			/>


			<TextField
				className='col-12 my-2'
				value={formData.description}
				onChange={handleChange}
				name='description'
				multiline
				rows={5}
				label='Dispute description'
				variant='outlined'
			/>

			<div className="modalfooter">
				<button type="submit" className="orange-button extra-padding" onClick={submit}>
					{
						loading ?
							<SubmitLoader />
							:
							'Submit'
					}
				</button>
			</div>
			{uploadModal(handleChange, setOpen, open, () => setOpen(false))}
		</div>
	);
};

export default CreateOtherDispute;
