import React from 'react'
import Divider from '../Divider/Divider'
import Address from './Address'
import SelectTerminal from './SelectTerminal'
import IconButton from "@material-ui/core/IconButton";
import {useNavigate} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";

const TerminalAddress = () => {
  const navigate = useNavigate();

  return (
    <>
    <div className="d-flex align-items-center justify-content-between">
        <h4 className="fs-20 text-grey fw-700 font-default col-md-8 justify-content-end d-flex">Select your preffered POS Terminal Device</h4>
      <IconButton onClick={() => navigate('/terminals')} title='Go back to terminals'><AiOutlineArrowLeft /></IconButton>

    </div>
    <SelectTerminal />
    <Address />
    <Divider />
    </>
  )
}

export default TerminalAddress
