import { NavLink as RouterLink, useParams } from 'react-router-dom';
// @mui
import {
  OutlinedInput,
  FormControl
} from '@mui/material';
// hooks
// components
import React, {useEffect, useState} from "react";
import Layout from "../../components/Layout/Layout";
import {apiCall} from "../../utilities/apis";
import {
  allTerminals,
  deleteUserNotifications, filterNotificationByDate,
  filterOtherDispute2,
  getUserNotifications, searchAllTerminalsByParam
} from "../../plugins/urls";
import Loader from "../../components/Loader";
import DatePicker from "react-datepicker";
import isEmpty from "lodash/isEmpty";
import {IoSearch} from "react-icons/io5";
import {fDate, formatDate} from "../../utils/formatTime";
import {SVG} from "../../assets/svg";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import {
  notificationFilters, notificationsTableCols,
  terminalTableCols
} from "../../utilities/dummyData";
import {AiFillEye} from "react-icons/ai";
import Modal from "../../components/Modal";
import NotificationDetails from "./temps/Details";
import SuccessTemp from "../Settings/pages/updateAccount/components/successTemp";
import EllipsisLoader from "../../components/SubmitLoader/ElipseLoader";
// ----------------------------------------------------------------------
const {notification, surebet } = SVG;

export default function Notification() {
  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [cur, setCur] = useState({});
  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const statusCount = (data, status) =>
      data?.filter((item) => item?.status === status)?.length;

  const tableAction = (noti) => {
    setOpen(true);
    setCur(noti);
  };

  const getData = res => {
    const respData = res.data?.notifications;
    const content = respData.map(item => {
      item.content = item.content?.toLowerCase();
      const conts = item.content.split('\n')?.map(con => {
        const contArr = con.split(':');
        // console.log();

        return {[contArr[0].trim().replace('"', '')]: contArr[1]}
      });
      let contObj = {};
      for(let i = 0; i < conts.length; i++){
        contObj = {...contObj, ...conts[i]};
      }
      contObj.message = contObj.message?.replace('account see details below', 'account, click to view details.');
      for(const [key, value] of Object.entries(item)){
        if (value === null){
          item[key] = ''
        }
      }
      return {...item, ...contObj}
    });
    return ({content});
  };

  const { tableData, simpleTable, setTableData } = useSimpleTable({
    setLoading, url: `${getUserNotifications}${user.id}`, method: 'get', includeMeta: false, filters: notificationFilters, filterUrl: searchAllTerminalsByParam,
    showSearchIcon: true, isRange: false,
    cols: notificationsTableCols(tableAction, <AiFillEye size={20} color="#FF6700" />),
    getData, deps: [loadingDel]
  });

  // delete notification
  const deleteNotification = (id) => {
    apiCall({
    url: `${deleteUserNotifications}${id}`, setLoading: setLoadingDel,
    method: 'delete', success: (data) => {
        setOpenDel(true);
        setOpen(false);
      }
  });
  };


  const onFilterDateChange = (dates)=>{
    if(dates){
      const [start, end] = dates;
      setStartDate(start);
      end && setEndDate(end);
      if (start && end) {
        const data = {startDate: start, endDate: end};
        apiCall({
          url: `${filterNotificationByDate}${user?.id}?page=0&size=20`,
          data, method: 'post', setLoading, success: (data) => setTableData(getData(data))
        })
      }
    }
  };

  const toolbar = (
      <div className="d-flex flex-wrap justify-content-between pt-3 pe-4 ps-3 ">
        <div className=" ">
          {/*{searchPane}*/}
         Filter by date range
        </div>
        {
          startDate !== '' && endDate !== ''
          ? (
          <div className=" ">
          <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
          <OutlinedInput
          id="outlined-adornment-password"
          inputProps={{
          style: {padding: 9},
          autoComplete: 'new-password' // disable autocomplete and autofill
        }}
          onClick={() => setEndDate('')}
          value={`From ${formatDate(startDate, 'do-MMM-yyyy')} To ${formatDate(endDate, 'do-MMM-yyyy')}`}
          className="custom-input"
          sx={{'& .css-hdw1oc': { display: 'none'} }}
          />
          </FormControl>
          </div>
          )
              : (
                  <div className=" filter-search ml-22" style={{width: '30ch'}}>
                    <div className="input-container">
                      <DatePicker
                          dateFormat="dd-MM-yyyy"
                          placeholderText="From dd-mm-yyyy - To dd-mm-yyyy"
                          onChange={date => onFilterDateChange(date)}
                          isClearable
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          className="input"
                          wrapperClassName="datePicker"
                          selectsRange
                      />

                    </div>
                  </div>
              )
        }
      </div>
  );
  return (
    <Layout title="Notification">
      <div>

          {
            loading
                ? (<div className="row" style={{marginTop: '33vh'}}>
                  <div className="text-center align-items-center justify-content-center d-flex h-100vh">
                    <Loader />
                  </div>
                </div>)
                : (<div>

                  {
                    isEmpty(tableData)
                      ?  (<div className="text-center align-items-center justify-content-center d-flex h-80vh">
                          <div>
                            <img src={notification} alt=""/>
                            <div className="fw-bold fs-16 mb-2">No Notifications yet!</div>
                            <div className="text-muted">Any new notification will appear here</div>
                          </div>
                        </div>)
                        :
                        (
                            <div>
                              {  toolbar }
                              <div className="row">
                               {simpleTable}
                              </div>
                            </div>
                        )
                  }

                </div>)
          }
        <Modal title={`Notification ${cur?.notificationId}`} onclick={() => setOpen(false)} show={open}>
          <NotificationDetails notification={cur} close={() => setOpen(false)} deleteNow={deleteNotification} load={loadingDel} />
        </Modal>
        <Modal title='Success' onclick={() => setOpenDel(false)} show={openDel}>
          <SuccessTemp
              close={() => setOpenDel(false)}
              loading={loading}
              message='Notification successfully deleted' circle={true}
              okText='Close'
          />
        </Modal>
      </div>
    </Layout>
  );
}
