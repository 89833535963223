import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Chat } from "../../assets/icons/card_chat.svg";
import { ReactComponent as Finger } from "../../assets/icons/card_finger.svg";
import { ReactComponent as User } from "../../assets/icons/card_user.svg";
import "./start.scss";

const QuickLinks = () => {
  const navigate = useNavigate();

  return (
    <Row className="font-default mt-40 d-flex justify-content-between w-100">
      <Col className="mx-auto">
        <div className="text-center link-div">
          <User />
          <h4 className="fs-13 text-darker mt-20 lh-base w-75 mx-auto h-25">
            Submit a few KYC details to start accepting payments and receive
            settlement in your account
          </h4>
          <button
            className="orange-button mt-20 w-75 fw-bolder fs-13 px-4 py-2"
            onClick={() =>
              navigate("/settings/?kyc", { state: { enableTeamMember: true } })
            }
          >
            Activate Account
          </button>
        </div>
      </Col>

      <Col className="">
        <div className="text-center link-div">
          <Finger />
          <h4 className="fs-13 text-darker mt-20 lh-base w-75 mx-auto h-25">
            You can do a quick walkthrough of WayaPOS and how it can help your
            business by exploring the dashboard.
          </h4>
          <button
            className="orange-button mt-20 w-75 fw-bolder fs-13 px-4 py-2"
            onClick={() => navigate("/dashboard")}
          >
            Explore the Dashboard
          </button>
        </div>
      </Col>

      <Col className="">
        <div className="text-center link-div">
          <Chat />
          <h4 className="fs-13 text-darker mt-20 lh-base w-75 mx-auto h-25">
            Find answers to questions you might have or get in touch
          </h4>
          <button
            className="orange-button mt-20 w-75 fw-bolder fs-13 px-4 py-2"
            onClick={() => navigate("/support")}
          >
            Contact for help
          </button>
        </div>
      </Col>
    </Row>
  );
};

export default QuickLinks;
