import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { Button, Input, Upload } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import NinGuide from './ninGuide';
import {EventOutlined, UploadOutlined } from '@mui/icons-material';
import validateImage from 'utils/validate';
import Camera from '../../../../../../assets/img/camera.svg';
import event from '../../../../../../assets/img/backdrop.png';

import './update.scss';

const UploadModal = (props) => {
  const [imageFile, setImageFile] = useState({});
  const {
    showModal,
    hideModal,
    name,
    setPostData,
    postData,
    loading,   
    uploadDoc,   
  } = props;


  
  const [disable, setDisable] = useState(true);
  const [showNinGide, setShowNinguide] = useState(false);
  const [showGuide, setShowguide] = useState(false);
  const [idType, setIdType] = useState("");
  const [copied, setCopied] = useState(false);
  const [previewImage, setPreviewImage]= useState('')
  const handlePreview = (e) => {
    const imageData = e.target.files[0];
    console.log('imda', imageData);
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
        console.log('imda2', imageData);
      setDisable(false);
      setImageFile(imageData);
      setPostData({ ...postData, file: imageData, });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  /*const handleUploadDoc = async (type, image) => {
   
    const newForm = new FormData();
    newForm.append('file', imageFile);
    newForm.append('userId', userDetails?.userId);
    newForm.append('identificationNumber', postData?.identificationNumber);

    if (type === 'utility') {
     
      newForm.append('fileName', 'Utility_bill');
      newForm.append('identificationNumber', 'nill');

      const res = await httpPost(
        `/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'photograph') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/create/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'Address') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/address/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'cac') {
      newForm.append('fileName', 'CAC');
      const res = await httpPost(
        `/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'tin') {
      newForm.append('fileName', 'TIN');
      const res = await httpPost(
        `/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'MERMAT') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/upload-any-document/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'ID') {
      if (idType === 'interPass') {
        newForm.append('fileName', 'INTERNATIONAL_PASSPORT');
        const res = await httpPost(
          `/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'nationalId') {
        newForm.append('fileName', 'NATIONAL_ID');
        const res = await httpPost(
          `/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'votersCard') {
        newForm.append('fileName', 'VOTERS_CARD');
        const res = await httpPost(
          `/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'driversLicence') {
        newForm.append('fileName', 'LICENSE');
        const res = await httpPost(
          `/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
    }
    setLoading(false);
    hideLoader();
  };

  */
  return (
    <Modal isOpen={showModal} id="" toggle={() => hideModal(false)} centered>
      <ModalHeader className="centre">Upload {name}</ModalHeader>
      <ModalBody>
     
        <div className="centre ml-0 mr-0">
          {//previewImage ? <img src={previewImage} width={300} height={300} alt="event" />: ""
          }
          <img src={previewImage || event} alt="event" width='100%' height={300} />

          <img src={Camera} className="upload-banner-input" alt="camera" />
          <Input
            type="file"
            title='select file'        
            className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"

            onChange={(e) => {
                handlePreview(e)
                setPostData({...postData, title: name})
            }}
          />
          
        </div>  
        <div style={{marginBottom:20}}>      
        {/* <input
            type="file"
            style={{height:40}}
            title='select file'        
            className="upload-banner-input2 input border border-gray-900 h-20"
            onChange={(e) => {
                handlePreview(e)
                setPostData({...postData, title: name})
            }}
          />*/}
          </div>
          {name === 'reference' && (
          <div className="my-3">
            <select
              className="border border-2 border-radius-2"
              onChange={(e) => {
                setPostData({...postData, idType: e.target.value })
              }}
            >
              <option value="" disabled selected>
                Select reference Type
              </option>
              <option value="ACCOUNT_STATEMENT">ACCOUNT STATEMENT</option>
              <option value="REFERENCE">REFERENCE</option>
            </select>
            <div className="my-3">
              <Input
                placeholder="reference id"
                value={postData?.identificationNumber}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    identificationNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        {name === 'ID' && (
          <div className="my-3">
            {/* <select
              className="border border-2 border-radius-2"
              onChange={(e) => {
                setIdType(e.target.value);
                setPostData({...postData, idType: e.target.value })
                // if (e.target.value === 'nationalId') {
                //   setShowNinguide(true);
                //   setShowguide(true);
                // } else {
                //   setShowguide(false);
                // }
              }}
            >
              <option value="" disabled selected>
                Select Identification Type
              </option>
              <option value="interPass">International Passport</option>
              <option value="nationalId">National Identification Card</option>
              <option value="votersCard">Voters Card</option>
              <option value="driversLicence">Drivers Licence</option>
            </select> */}
            {idType === 'interPass' ? (
              <div className="my-3">
                <Input
                  placeholder="International Passport Number"
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
              </div>
            ) : idType === 'nationalId' ? (
              <div>
                <Input
                  placeholder="NIN"
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
              </div>
            ) : idType === 'votersCard' ? (
              <div>
                <Input
                  placeholder="Voter Id"
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
              </div>
            ) : idType === 'driversLicence' ? (
              <div>
                <Input
                  placeholder="License"
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {name === 'tin' && (
          <div>
            <Input
              placeholder="TIN"
              value={postData?.identificationNumber}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  identificationNumber: e.target.value,
                })
              }
            />
          </div>
        )}
        {name === 'cac' && (
          <div>
            <Input
              placeholder="CAC"
              value={postData?.identificationNumber}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  identificationNumber: e.target.value,
                  fileName: "CAC",
                })
              }
            />
          </div>
        )}
        {showGuide && (
          <div>
            <p>
              Steps to generate a 16-digit virtual national identification
              number (VNIN) using the NIMC app:
            </p>
            <p>1. Download the NIMC App </p>
            <p>2. Follow the steps to generate VNIN on the NIMC app</p>
            <p>
              3. Select ‘Input Enterprise Short-Code’ and enter 951641
              <CopyToClipboard
                text={951641}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                <div className="d-flex">
                  <img src={CopyOutlined} alt="" className="me-2" />
                  <div>{copied === false ? '' : 'Copied'}</div>
                </div>
              </CopyToClipboard>
            </p>
            <p>
              4. Copy your generated virtual NIN and paste it in the input field
              above.
            </p>
          </div>
        )}
        {!disable && (
          <div className="w-100 my-5 text-center">
            <Button
              style={{}}
              type="button"
              loading={loading}
              /*onClick={() => {
                uploadDoc()
                //handleUploadDoc(name, imageFile);
              }}*/
              onClick={()=>uploadDoc(imageFile)}
              content="Upload"
              className="bg-primary w-50 p-2 rounded-1 text-white border-0"
            >Upload </Button>
          </div>
        )}
      </ModalBody>
      {showNinGide && (
        <NinGuide showModal={showNinGide} hideModal={setShowNinguide} />
      )}
    </Modal>
  );
};

export default UploadModal;
