import React from "react";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import "./modal.css";
import IconButton from "@material-ui/core/IconButton";

const Modal = ({ show, onclick, title, clsx, children }) => {
  return (
    <div className={`modal-modal ${show ? "show" : ""}`}>
      <div className={`roles-modal rounded-3 py-2 `} style={clsx}>
        <div
          className="roles-modal-header text-center py-3 w-100 rounded-top"
          style={{ backgroundColor: "#F1F7FA" }}
        >
          <h4
            className="fs-16 w-50 mx-auto"
            style={{ color: "#064A72", lineHeight: "2" }}
          >
            {title}
          </h4>

          <IconButton
            onClick={onclick}
            className="mr-2"
            style={{
              position: "absolute",
              top: "20px",
              right: "30px",
              cursor: "pointer",
            }}
          >
            <Close />
          </IconButton>
        </div>

        {children}
      </div>
    </div>
  );
};

export default Modal;
