import { useContext, useEffect, useState } from "react";
import Nexgo from "../../assets/img/nexgo.svg";
import Topwise from "../../assets/img/topwise.svg";
import { AddTerminalContext } from "../../pages/Terminals/AddTerminal";
import EllipsisLoader from "../SubmitLoader/ElipseLoader";
import Controls from "./Controls";
import "./select.scss";
import SlideContent from "./SlideContent";

const SelectTerminal = () => {
  const {
    onAddItem,
    subtractQuantity,
    addQuantity,
    selectedPOS,
    loading,
    types,
  } = useContext(AddTerminalContext);
  const [content, setContent] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState([]);

  const itemPerPage = 3;

  useEffect(() => {
    const conten = types?.map((type) => {
      const num = selectedPOS.find((item) => item.id === type.id)?.qty;
      return { ...type, num };
    });
    setContent(conten);
  }, [selectedPOS, types]);

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="slider-div mt-20">
      <div className="mt-5 flex w-full flex-col">
        <div className="flex w-full relative">
          <div className="w-full h-full overflow-hidden">
            <div
              className={`flex transition duration-300 ease-linear carousel-content show-${itemPerPage}`}
              style={{ transform: `translateX(-${carouselIndex * 100}%)` }}
            >
              {content?.map((type, i) => {
                return (
                  <SlideContent
                    key={type.id}
                    img={i % 2 === 0 ? Topwise : Nexgo}
                    content={type}
                    click={onAddItem}
                    subtractQuantity={subtractQuantity}
                    addQuantity={addQuantity}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Controls
        index={carouselIndex}
        setIndex={setCarouselIndex}
        itemPerPage={itemPerPage}
        totalItems={types?.length}
      />
    </div>
  );
};

export default SelectTerminal;
