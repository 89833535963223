/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Dropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { MdErrorOutline } from 'react-icons/md';
import DocumentPreviewer from "./docPreviewer";


 const animatedCheck = (icon) => (
    <div className="animated-check-wrapper">
      <svg className="animated-check" viewBox="0 0 24 24">
        {icon}
      </svg>
    </div>
);

const DragNDropFileInput = ({ onChange, name, uploadPic, examples, label, dotted, uploaded,postData, setPostData, title, noBtn, text = 'Drop or Browse', supportedFiles = '.csv, .xls, .xlxs, JPG, Jpeg' }) => {
  const [fileNames, setFileNames] = useState([]);
  const [files, setFiles] = useState('');
  const handleDrop = (acceptedFiles) => {
    console.log('drp', acceptedFiles)
    console.log('title', title);
    uploadPic(acceptedFiles);
    setFiles(acceptedFiles);
    setPostData({...postData, title});
    setFileNames(acceptedFiles.map((file) => file.name));
    const ev =  {target: {name, value: acceptedFiles?.length > 1 ? acceptedFiles : acceptedFiles[0]}};
    onChange(ev);
  };

  return (
      <div className={`dropzone ${dotted ? 'dotted-border' : ''}`}>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className='justify-content-center d-flex'>
                  {
                    isEmpty(fileNames)
                        ? (
                            <div className="font-regular" style={{width: '300px'}}>
                              <div className={noBtn ? 'd-none' : 'justify-content-center d-flex'}>
                                <button type="button" className="btn-plain">
                                  <AiOutlineFileAdd className="font-black font-title-small" />
                                </button>
                              </div>
                              <p className='px-2 text-center'>
                                {text}
                              </p>
                              <p className="bold text-dark mr-2 text-center">{label}</p>
                              <small className="text-muted mr-2 text-center">{examples?.length > 0 && `[${examples}]`}</small>
                              <p className="ml-2 text-center">
                                <small>supports: <span className='text-info'>{supportedFiles}</span></small>
                              </p>
                            </div>
                        )
                        : (
                            <ul>
                              {fileNames.map((fileName, index) => (
                                  <li key={fileName}>
                                    <span>{fileName}</span>
                                    <DocumentPreviewer doc={files[index]} />
                                    <span>{uploaded === 'success' && animatedCheck(<path className="path" d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />)}</span>
                                    <span>{uploaded === 'failed' && <MdErrorOutline className="text-danger" />}</span>
                                    <span>{uploaded === 'pending' && <CircularProgress />}</span>
                                  </li>
                              ))}
                            </ul>
                        )
                  }
                </div>
              </div>
          )}
        </Dropzone>
      </div>
  );
};
export default DragNDropFileInput;
