import { HiUserGroup } from "react-icons/hi";
import { ReactComponent as Logout } from "../assets/icons/logout.svg";
import { ReactComponent as SideActivities } from "../assets/icons/side_activities.svg";
import { ReactComponent as SideDespute } from "../assets/icons/side_despute.svg";
import { ReactComponent as SideOverview } from "../assets/icons/side_overview.svg";
import { ReactComponent as SideSettings } from "../assets/icons/side_settings.svg";
import { ReactComponent as SideSettlements } from "../assets/icons/side_settlements.svg";
import { ReactComponent as SideStarted } from "../assets/icons/side_started.svg";
import { ReactComponent as SideSubmit } from "../assets/icons/side_submit.svg";
import { ReactComponent as SideSupport } from "../assets/icons/side_support.svg";
import { ReactComponent as SideTerminals } from "../assets/icons/side_terminals.svg";
import { ReactComponent as SideTransactions } from "../assets/icons/side_transactions.svg";
import { ReactComponent as SideWayaBank } from "../assets/icons/side_wayabank.svg";
import { ReactComponent as SideWayaGram } from "../assets/icons/side_wayagram.svg";
import { ReactComponent as SideWayaWeb } from "../assets/icons/side_web.svg";
import { SVG } from "../assets/svg";

/**
 * api model data
 * backend resources
 */

export const naira = "&#8358;";
// export const {user} = {name: 'amber'};
// export const user = JSON.parse(localStorage.getItem('userDetails'))?.user;

const {
  transfer,
  card,
  network,
  phoneDot,
  tv,
  walletOutlined,
  transferOutlined,
  gotv,
  startimes,
  dstv,
  airtel,
  mtn,
  ninemobile,
  glo,
  betway,
  nairabet,
  surebet,
  guide,
  faqGuide,
  dashGuide,
  feedback,
  api,
} = SVG;
export const quickActions = [
  { name: "transfer", icon: transfer },
  { name: "withdrawal", icon: transfer },
  { name: "airtime", icon: phoneDot },
  { name: "data", icon: network },
  { name: "utility", icon: transfer },
  { name: "cable", icon: tv },
  { name: "betting", icon: card },
];
export const transferTopMenu = [
  { name: "Pay to Email", id: 1 },
  // {name: 'Pay to Waya ID', id: 2},
  { name: "Pay to Name", id: 2 },
  // {name: 'Scan to Pay', id: 3},
  { name: "Pay to Phone", id: 4 },
];
export const fundTopMenu = [
  { name: "Bank Card", id: "card" },
  { name: "Bank Account", id: "fundTransferMenu" },
  { name: "Bank Transfer", id: "account" },
  // {name: 'USSD Code', id: 'ussd'}
];
export const ussdMenu = [
  { name: "Top up Wallet", value: "internal" },
  { name: "External Transfer", value: "external" },
];
export const wayaIds = [
  { name: "seyitan ibille", id: 12345678 },
  { name: "rashida amilo", id: 23456781 },
  { name: "fauz amusa", id: 34567812 },
  { name: "makosa pasuma", id: 456789123 },
];
export const utilityProviders = [
  { name: "DSTV", icon: dstv, id: 1, billerId: "dstv", categoryId: "cabletv" },
  {
    name: "Startimes",
    icon: startimes,
    id: 2,
    billerId: "startimes",
    categoryId: "cabletv",
  },
  { name: "GOTV", icon: gotv, id: 3, billerId: "gotv", categoryId: "cabletv" },
];
export const utilityPlans = [
  { name: "Prepaid", id: 1 },
  { name: "Postpaid", id: 2 },
];
export const airtime = [
  { name: "MTN", icon: mtn, id: 1, billerId: "mtn", categoryId: "airtime" },
  { name: "GLO", icon: glo, id: 2, billerId: "glo", categoryId: "airtime" },
  {
    name: "9mobile",
    icon: ninemobile,
    id: 3,
    billerId: "9mobile",
    categoryId: "airtime",
  },
  {
    name: "Airtel",
    icon: airtel,
    id: 4,
    billerId: "airtel",
    categoryId: "airtime",
  },
];
export const electricity = [
  { name: "KEDCO", id: 1, billerId: "kedco", categoryId: "electricity" },
  { name: "PHCN POSPAID", id: 2, billerId: "phcn", categoryId: "electricity" },
];

export const data = [
  { name: "MTN", icon: mtn, id: 1, billerId: "mtn", categoryId: "databundle" },
  { name: "GLO", icon: glo, id: 2, billerId: "glo", categoryId: "databundle" },
  {
    name: "9mobile",
    icon: ninemobile,
    id: 3,
    billerId: "9mobile",
    categoryId: "databundle",
  },
  {
    name: "Airtel",
    icon: airtel,
    id: 4,
    billerId: "airtel",
    categoryId: "databundle",
  },
];

export const banks = [
  { logo: airtel, name: "zenith bank", code: "", codeName: "znth" },
  { logo: mtn, name: "wema bank", code: "", codeName: "Wema" },
  { logo: betway, name: "united bank for africa", code: "", codeName: "uba" },
  { logo: dstv, name: "first bank", code: "", codeName: "fbn" },
  { logo: nairabet, name: "access bank", code: "", codeName: "access" },
];
export const bettingProviders = [
  {
    name: "NairaBet",
    icon: nairabet,
    id: 1,
    billerId: "nairabet",
    categoryId: "betting",
  },
  {
    name: "Betway",
    icon: betway,
    id: 2,
    billerId: "betway",
    categoryId: "betting",
  },
  {
    name: "Surebet",
    icon: surebet,
    id: 3,
    billerId: "surebet",
    categoryId: "betting",
  },
];
export const bettingPlans = [
  { name: "Wallet Funding", icon: nairabet, id: 1 },
  { name: "Payment", icon: betway, id: 2 },
];
export const dataPlans = [
  { name: "90 MB for 1 day", amount: 100, id: 1 },
  { name: "900 MB for 7 days", amount: 300, id: 1 },
  { name: "1 GB for 14 day", amount: 500, id: 1 },
  { name: "20 GB for 1 Month", amount: 5000, id: 1 },
];
export const transferMenu = [
  {
    name: "Transfer to own Account",
    desc: "Transfer to own Account",
    icon: walletOutlined,
    id: 1,
  },
  {
    name: "Transfer to other Account",
    desc: "Transfer to another Account",
    icon: transferOutlined,
    id: 2,
  },
];
export const fundMenu = [
  {
    name: "Fund via Existing",
    desc: "Bank Account",
    icon: walletOutlined,
    id: "transferSaved",
  },
  {
    name: "Fund via New",
    desc: "Bank Account",
    icon: transferOutlined,
    id: "transferNew",
  },
];

// deposit.
export const fundWalletAccount = [
  { name: "Bank Card", id: 1 },
  { name: "Bank Account", id: 2 },
  { name: "Bank Transfer", id: 3 },
  { name: "USSD Code", id: 4 },
];

// bank account.
export const topUpViaBankAccount = [
  {
    name: " Fund via existing bank account",
    desc: " Fund via existing bank account",
    icon: walletOutlined,
    id: 1,
  },
  {
    name: " Fund via new bank account",
    desc: " Fund via new bank account",
    icon: transferOutlined,
    id: 2,
  },
];

///kyc

// businessLogo

/* TODO:  create and show business logo in tier 1*/
export const form1Requirements = [
  {
    name: "organisationName",
    label: "Business Name",
  },
  // {
  //     name: 'BUSINESS_DESCRIPTION',
  //     label: 'Business Description'
  // },

  // {
  //   name: "organisationPhone",
  //   label: "Business Phone Number",
  //   numberInput: { isPhone: true },
  // },
  // {
  //   name: "businessType",
  //   label: "Business Type",
  // },
  // {
  //   name: "organisationEmail",
  //   label: "Business Email Address",
  // },
];
export const form2Requirements = [
  {
    name: "BVN",
    label: "Bank Verification Number (BVN)",
    numberInput: false,
    canVerify: true,
  },
];

export const form3Requirements = [
  {
    name: "PASSPORT",
    label: "Passport",
  },
  {
    name: "PROOF_ADDRESS",
    label: "Utility Bill on the name",
    title: "Proof_of_address",
  },
  {
    name: "NATIONAL_IDENTITY",
    label: "National ID/Voters Card/Drivers License/NIN",
  },
  // {
  //     name: 'BANK_REFERENCE',
  //     label: 'Reference From 2 Banks'
  // },
  // {
  //     name: 'ADDRESS_PHOTOGRAPH',
  //     label: 'Address verification',
  //     desc: 'photo should capture the street, house number, left and right'
  // }
];

export const form4Requirements = [
  {
    name: "CAC_DOCUMENT",
    label: "CAC Document - Certificate",
  },
  {
    name: "MERMART",
    label: "CAC Document - Mermart",
  },
  {
    name: "TIN",
    label: "Taxpayer Identification Number (TIN)",
  },
];

export const statusColors = {
  PENDING: "warning",
  UNDER_REVIEW: "warning",
  OPEN: "warning",
  COMPLETED: "success",
  APPROVE: "success",
  APPROVED: "success",
  DONE: "success",
  CLOSED: "success",
  REJECTED: "danger",
};

export const supportMenu = [
  {
    title: "Setup Guide",
    content: "Learn how to integrate WayaPOS with your business",
    btnText: "SHOW ME",
    icon: guide,
  },
  {
    title: "API Documentation",
    content: "Learn how to build amazing things with the WayaPOS API",
    btnText: "VIEW DOC",
    icon: api,
  },
  {
    title: "Dashboard Guide",
    content: "Learn about the features of the WayaPOS Web POS Dashboard",
    btnText: "EXPLORE",
    icon: dashGuide,
  },
  {
    title: "FAQs",
    content: "Get quick answers to any questions about WayaPOS",
    btnText: "FIND ANSWERS",
    icon: faqGuide,
  },
  {
    title: "Share Your Feedback",
    content: "If you spot something that can be better, let us know",
    btnText: "SHARE",
    icon: feedback,
  },
  {
    title: "Get in touch",
    content: "Send us a message or chat when someone is available",
    btnText: "CONTACT US",
    icon: faqGuide,
  },
];

export const aggregatorTableCols =(clickFn, actionTemp)=> [
  {
    value: "name",
    name: "Username",
  },
  {
    value: "email",
    name: "User Email",
  },
  {
    value: "phoneNumber",
    name: "User Phone",
  },
  {
     value: 'createdAt',
     name: 'Sign up Date',
     type: "date",
},
{
  value: "businessType",
  name: "Business Type",
},
{
  value: "organizationName",
  name: "Organization Name",
},
{
  type: "action",
  name: "Action",
  value: actionTemp,
  click: clickFn,
},
];

export const aggCommissionTableCols =(clickFn, actionTemp)=> [
  {
    value: "referralName",
    name: "Referral Name",
  },
  {
    value: "customerName",
    name: "Customer Name",
  },
  {
    value: "customerEmail",
    name: "Customer Email",
  },
  {
    value: "customerPhone",
    name: "Customer Phone",
  },
  {
    value: "referralCommissionAmount",
    name: "Commission Amount",
  },
  {
    value: "transactionCount",
    name: "Transaction Count",
  },
  {
    value: "customerOrganizationName",
    name: "Organization Name",
  },
];

export const transactionTableCols =(clickFn, actionTemp)=> [
  {
    value: "de37",
    name: "Reference Id",
  },
 
  {
       value: 'terminalType',
       name: 'Terminal Type'
  },
  {
    value: "paymentMethod",
    name: "Payment Method",
  },
  {
    value: "de4",
    name: "Amount",
    type: "number1",
  },
  {
    type: 'subValue',
     value: 'terminals',
     name: 'Terminal Type',
     sub2: 'actualTerminalName',
     sub1: 'terminals',
},
{
  type: 'subValue',
   value: 'terminals',
   name: 'Terminal Type',
   sub2: 'terminalSerialNumber',
   sub1: 'terminals',
},
  {
    value: "createdAt",
    name: "Created At",
    type: "date",
  },
  {
    value: "paymentStatus",
    name: "Payment Status",
  },{
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  },
];

export const accountHistoryTabCol = [
  {
    value: "referenceNumber",
    name: "Reference Id",
  },
  {
    value: "sourceName",
    name: "Source Account",
  },
  {
    value: "paymentMethod",
    name: "Payment Method",
  },
  {
    value: "amount",
    name: "Amount",
  },
  {
    value: "createdAt",
    name: "Created At",
    type: "date",
  },
  {
    value: "transactionType",
    name: "Transaction Type",
    cls: {
      credit: "tabactive",
      debit: "tabdanger",
      other: "",
    },
    clsIndex: "transactionType",
  },
];
export const terminalTableCols = (clickFn, actionTemp) => [
  {
    value: "terminalId",
    name: "terminal id",
  },
  {
    value: "actualTerminalName",
    name: "terminal name",
  },
  {
    value: "terminalAmount",
    name: "terminal amount",
  },
  {
    value: "amountPaid",
    name: "amount paid",
  },
  /*   {
        value: 'amountLeft',
        name: 'amount left'
    }, */
  {
    value: "status",
    name: "status",
    cls: {
      approved: "tabactive",
      rejected: "tabdanger",
      faulty: "tabdamaged",
      pending: "tabpending",
      other: "",
    },
    clsIndex: "status",
  },
  {
    value: "dateCreated",
    name: "issued date",
    type: "date",
  },
  /*   {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  }, */
];
export const terminalReqTableCols = (clickFn, actionTemp) => [
  {
    value: "terminalName",
    name: "terminal name",
  },
  {
    value: "amount",
    name: "terminal cost",
  },
  {
    value: "amount",
    name: "amount paid",
  },
  /*   {
        value: 'amountLeft',
        name: 'amount left'
    }, */
  {
    value: "status",
    name: "status",
    cls: {
      approved: "tabactive",
      rejected: "tabdanger",
      faulty: "tabdamaged",
      pending: "tabpending",
      other: "",
    },
    clsIndex: "status",
  },
  {
    value: "dateCreated",
    name: "issued date",
    type: "date",
  },
  /*   {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  }, */
];
export const settlementTableCols = (clickFn, actionTemp) => [
  {
    value: "referenceID",
    name: "Reference Id",
  },
  {
    value: "beneficiary",
    name: "Beneficiary",
  },
  {
    value: "actualAmount",
    name: "Actual Amount",
    type: "currency",
  },
  {
    value: "settledAmount",
    name: "Settled Amount",
    type: "currency",
  },
  {
    value: "createdAt",
    type: "date",
    name: "Created At",
  },
  {
    value: "status",
    name: "status",
    cls: {
      settled: "text-sharp-green",
      failed: "text-red",
      pending: "text-yellow",
      other: "",
    },
    clsIndex: "status",
  },
  {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  },
];
export const disputesTableCols = (clickFn, actionTemp) => [
  {
    value: "status",
    name: "status",
    cls: {
      closed: "text-sharp-green",
      open: "text-red",
      pending: "text-yellow",
      other: "",
    },
    clsIndex: "status",
  },
  {
    value: "reason",
    name: "Dispute Type",
  },
  {
    value: "tranType",
    name: "Transaction",
  },
  {
    value: "customer",
    name: "Customer",
  },
  {
    value: "dateCreated",
    type: "date",
    name: "Date Created",
  },
  {
    value: "dueWhen",
    name: "Due When",
    type: "date",
  },
  {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  },
];

export const otherDisputesTableCols = (clickFn, actionTemp) => [
  {
    value: "trackingNumber",
    name: "Ticket ID",
  },
  {
    value: "username",
    name: "Username",
  },
  {
    value: "subject",
    name: "Dispute Subject",
  },
  {
    value: "initiationDate",
    type: "date",
    name: "Duration of Initiation",
  },
  {
    value: "status",
    name: "Status",
    cls: {
      closed: "text-sharp-green",
      open: "text-red",
      pending: "text-yellow",
      other: "",
    },
    clsIndex: "status",
  },
  {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  },
];
export const notificationsTableCols = (clickFn, actionTemp) => [
  {
    value: "category",
    name: "Activity",
  },
  {
    value: "message",
    name: "Message",
  },
  {
    value: "amount",
    type: "currency",
    name: "Amount",
  },
  {
    value: "dateSent",
    type: "date",
    name: "Date",
  },
  {
    value: "status",
    name: "Status",
    cls: {
      closed: "text-sharp-green",
      open: "text-red",
      pending: "text-yellow",
      other: "",
    },
    clsIndex: "status",
  },
  {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  },
];
export const activityLogTableCols = (clickFn, actionTemp) => [
  {
    value: "duration",
    type: "date",
    name: "Duration",
  },
  {
    value: "activity",
    name: "Activity",
  },
  // {
  //     value: 'message',
  //     name: 'Message',
  // },
  // {
  //     value: 'amount',
  //     type: 'currency',
  //     name: 'Amount',
  // },
  //
  // {
  //     value: 'status',
  //     name: 'Status',
  //     cls: {
  //         closed: 'text-sharp-green',
  //         open: 'text-red',
  //         pending: 'text-yellow',
  //         other: ''
  //     },
  //     clsIndex: 'status'
  // },
  /* {
    type: "action",
    name: "Action",
    value: actionTemp,
    click: clickFn,
  }, */
];

export const transactionFilters = [
  "referenceId",
  "transactionCategory",
  "paymentMethod",
  "amount",
  "paymentStatus",
  "date",
];
export const aggregatorFilters = [
  "All",
];
export const terminalFilters = [
  "terminalName",
  "terminalAmount",
  "terminalType",
  "Status",
  "date",
];
export const settlementFilters = [
  "referenceID",
  "actualAmount",
  "date",
  "status",
];
export const disputesFilters = ["status", "reason", "date", "customer"];
export const otherDisputesFilters = [
  "type",
  "status",
  "resolution",
  "trackingNumber",
  "date",
];
export const notificationFilters = ["category", "status", "notificationId"];
export const activityLogFilters = ["duration"];

export const sidebarMenu = (title) => [
  {
    name: "Getting Started",
    icon: (
      <SideStarted
        className={title === "Get Started" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/get-started",
  },
  {
    name: "Submit Kyc",
    icon: (
      <SideSubmit
        className={title === "Kyc" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/settings/?kyc",
  },
  {
    name: "Overview",
    icon: (
      <SideOverview
        className={title === "Overview" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/dashboard",
  },
  {
    name: "Terminals",
    icon: (
      <SideTerminals
        className={title === "Terminals" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/terminals",
  },
  {
    name: "Transactions",
    icon: (
      <SideTransactions
        className={title === "Transactions" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/transactions",
  },
  {
    name: "AG Dashboard",
    icon: (
      <SideWayaBank
        className={title === "Aggregator Dashboard" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/aggregator-dashboard",
  },
  {
    name: "Merchants",
    icon: (
      <HiUserGroup
        className={
          title === "Merchants" || title === "Add Merchant"
            ? "activeIcon"
            : "sideIcon"
        }
        size={20}
      />
    ),
    link: "/merchants",
  },
  {
    name: "Settlements",
    icon: (
      <SideSettlements
        className={title === "Settlements" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/settlements",
  },
  {
    name: "Dispute",
    icon: (
      <SideDespute
        className={title === "Dispute" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/disputes",
  },
  {
    name: "Notifications",
    icon: (
      <SideActivities
        className={title === "Notifications" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/notifications",
  },
  {
    name: "Settings",
    icon: (
      <SideSettings
        className={title === "Settings" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/settings",
  },
  {
    name: "Support",
    icon: (
      <SideSupport
        className={title === "Support" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/support",
  },
  {
    name: "Activity Log",
    icon: (
      <SideActivities
        className={title === "Activity Log" ? "activeIcon" : "sideIcon"}
        size={20}
      />
    ),
    link: "/activity-log",
  },
];
export const externalLinks = [
  {
    name: "Wayabank",
    icon: <SideWayaBank className="sideIcon" size={20} />,
    link: "",
  },
  {
    name: "Wayagram",
    icon: <SideWayaGram className="sideIcon" size={20} />,
    link: "",
  },
  {
    name: "Wayaquick",
    icon: <SideWayaWeb className="sideIcon" size={20} />,
    link: "",
  },
  {
    name: "Logout",
    icon: <Logout className="sideIcon" size={20} />,
  },
];
