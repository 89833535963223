import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  allTerminals,
  searchTransactionByParam,
  accountHistories,
} from "../../plugins/urls";
import useSimpleTable from "../Hooks/useSimpleTable";
import Spinner from "react-bootstrap/Spinner";
import {
  accountHistoryTabCol,
  transactionFilters,
} from "../../utilities/dummyData";
import { apiCall } from "../../utilities/apis";

const WayaAgency = () => {
  /* localStorage */
  const { user } = JSON.parse(localStorage.getItem("userDetails"));

  /* state */
  const [loading, setLoading] = useState(null);
  const [loadingWal, setLoadingWal] = useState(null);
  const [terminals, setTerminals] = useState([]);
  const [terminalNo, setTeminalNo] = useState(null);

  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: accountHistories,
    method: "get",
    includeMeta: true,
    filters: transactionFilters,
    filterUrl: searchTransactionByParam,
    showSearchIcon: true,
    csvName: "WAYA_POS_transactions_",
    dateFilterMenuName: "date",
    cols: accountHistoryTabCol,
    filterMethod: "get",
    dataAddOn: {
      terminalId: terminalNo,
    },
    deps: [terminalNo],
    startCb: () => {
      userTerminals();
    },
  });

  /* API calls */
  const userTerminals = () =>
    apiCall({
      url: `${allTerminals}`,
      data: {
        pageSize: 100,
        pageNo: 1,
        paramList: [
          {
            userid: user?.id,
          },
        ],
      },
      defaultMeta: true,
      setLoading: setLoadingWal,
      success: (data) => {
        setTerminals(data.content);
        setTeminalNo(data?.content[0]?.accountNo);
      },
      method: "post",
    });

  /* logic */
  const handleWalletNo = (no) => setTeminalNo(no);

  return (
    <>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>

          {tableData?.content?.length > 0 && exportButton}
        </div>
      </div>

      <Container fluid>
        {/*  <Row className="mt-40">
          {loadingWal ? (
            <EllipsisLoader />
          ) : (
            terminals.map((wallet, index) => (
              <Col>
                <AccountCard
                  accountNumber={wallet.accountNo}
                  value={
                    <span>
                      <span>&#8358;</span>
                      <span className="ms-1">{wallet.clr_bal_amt}</span>
                    </span>
                  }
                  number={index + 1}
                  count={terminals?.length}
                  click={handleWalletNo}
                  walletNo={terminalNo}
                />
              </Col>
            ))
          )}
        </Row> */}

        <div className="mt-5">
          {loading ? (
            <div className="align-items-center d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="success" />
            </div>
          ) : (
            <Container fluid>{simpleTable}</Container>
          )}
        </div>
      </Container>
    </>
  );
};

export default WayaAgency;
