import React, { useState, useEffect, useContext } from 'react';
// import swal from 'sweetalert';
// import { customBaseUrl, httpPost, httpPut } from '../../../action/http';
import moment from 'moment';
// import Swal from 'sweetalert2';
// import { notification } from 'antd';
import ViewDocModal from './viewDoc';
import { otherFile, wayaKYC } from 'plugins/urls';
import { Slide, toast } from 'react-toastify';
import { apiCall } from 'utilities/apis';
import useUser from 'components/Hooks/useUser';

const initial = {
  address: '',
  bvn: '',
  dob: '',
  email: '',
  firstName: '',
  idCard: false,
  idCardType: '',
  idCardUrl: '',
  lastName: '',
  lga: '',
  nationality: '',
  nextOfKinAddress: '',
  nextOfKinEmail: '',
  nextOfKinFirstName: '',
  nextOfKinLastName: '',
  nextOfKinPhone: '',
  otherName: '',
  ownershipCut: 0,
  passport: false,
  passportUrl: '',
  phone: '',
  state: '',
  utilityHouse: false,
  utilityHouseUrl: '',
  utilityOffice: false,
  utilityOfficeUrl: '',
  idNumber: '',
  idCardType: 'NIN',
};
export default function UploadShareHolder(props) {
  const {
    shareHolders,
    setSPage,
    sPage,
    sData,
    userId,
    businessId,
    getShareHolders,
    documents,
    getDocuments,
  } = props;
  const { user } = useUser();
  const [data, setData] = useState(initial);
  const [imageFile, setImageFile] = useState('');
  const [isNew, setIsNew] = useState(true);
  const [files, setFiles] = useState({});
  const [shareStatus, setShareStatus] = useState({});
  const [viewData, setViewData] = useState({});
  const [viewDocModal, setShowViewDocModal] = useState(false);
  const [shareHolderId, setShareHolderId] = useState(null);

  const handleSave = async (e) => {
    e.preventDefault();
    
    if (!isNew) {
        apiCall({
            url: `${wayaKYC}api/v1/kyc/cac/updateBusinessShareholder/${shareHolderId}`,
            data,
            method: "put",
            // setLoading: uploading,
            success: (resp) => {
            if(resp?.status){
            toast.success( resp?.message ?? resp?.message, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            });
            getShareHolders();
            getDocuments();
            } else {
            toast.error( resp?.message ?? resp?.message, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            })  
            }     
        },
        });

    } else {
        apiCall({
            url: `${wayaKYC}api/v1/kyc/cac/createBusinessShareHolder/${businessId}/${user.id}/${user.profileSubscription?.profileId}`,
            data: {
                ...data,
                utilityOffice: data?.utilityHouse,
                utilityOfficeUrl: data?.utilityHouseUrl,
            },
            method: "post",
            // setLoading: uploading,
            success: (resp) => {
            if(resp?.status){
            toast.success( resp?.message ?? resp?.message, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            });
            getShareHolders();
            getDocuments();
            } else {
            toast.error( resp?.message ?? resp?.message, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            })  
            }     
        },
        });
    }
  };

  const handlePreview = (e, type) => {
    const imageData = e.target.files[0];
      setImageFile(imageData);
      setData({ ...data, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = async () => {
        upload(imageData, type);
        return;
      };
      reader.readAsDataURL(imageData);
    
  };
  const handlePreviewId = (e, type) => {
    const imageData = e.target.files[0];
      setImageFile(imageData);
      setData({ ...data, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = async () => {
        upload(imageData, type);
        return;
      };
      reader.readAsDataURL(imageData);
    
  };
  const previewImg = (e) => {
    setViewData({ url: e });
    setShowViewDocModal(true);
  };

  const upload = async (file, type) => {
    const newForm = new FormData();
    newForm.append('file', file);
    newForm.append('fileName', 'UTILITY');
    apiCall({
        url: `${otherFile}?fileName=${
        type === 'id' ? 'NATIONAL_ID' : type
      }&userId=${user.id}`,
        data: newForm,
        method: "post",
        // setLoading: uploading,
        success: (resp) => {
          if(resp?.status){
          toast.success( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          if (type === 'id') {
            setData({ ...data, idCard: true, idCardUrl: resp?.data });
          } else if (type === 'PASSPORT') {
            setData({ ...data, passport: true, passportUrl: resp?.data });
          } else if (type === 'UTILITY_OFFICE') {
            setData({ ...data, utilityOffice: true, utilityOfficeUrl: resp?.data });
          } else if (type === 'UTILITY_HOUSE') {
            setData({ ...data, utilityHouse: true, utilityHouseUrl: resp?.data });
          }
        } else {
          toast.error( resp?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      });
  };
  useEffect(() => {
    setFiles({});
    if (sData?.businessRequirementId) {
      setIsNew(false);
      setShareHolderId(sData?.id);
      setShareStatus({
        status: sData?.status,
        reason: sData?.reason,
      });
      setData(shareHolders[sPage]);
      console.log('docss', documents);
      const id = documents?.filter(
        (e) =>
          e?.shareHolder?.id === shareHolders[sPage]?.id &&
          (e?.docType === 'VOTERS_CARD' ||
            e?.docType === 'NATIONAL_ID' ||
            e?.docType === 'NIN' ||
            e?.docType === 'DRIVER_LICENCE' ||
            e?.docType === 'INTERNATIONAL_PASSPORT')
      )[0];
      const photo = documents?.filter(
        (e) =>
          e?.shareHolder?.id === shareHolders[sPage]?.id &&
          e?.docType === 'PHOTO'
      )[0];
      const uHouse = documents?.filter(
        (e) =>
          e?.shareHolder?.id === shareHolders[sPage]?.id &&
          e?.docType === 'UTILITY-HOUSE'
      )[0];
      const uOffice = documents?.filter(
        (e) =>
          e?.shareHolder?.id === shareHolders[sPage]?.id &&
          e?.docType === 'UTILITY-OFFICE'
      )[0];
      setData({
        ...data,
        ...shareHolders[sPage],
        idCardUrl: id?.docUrl,
        passportUrl: photo?.docUrl,
        utilityHouseUrl: uHouse?.docUrl,
        utilityOfficeUrl: uOffice?.docUrl,
        idCardType: id?.docType,
      });
      setFiles({ ...files, id, uHouse, uOffice, photo });
      console.log('direct2', shareHolders[sPage]);
    } else {
      setData({ ...initial });
      setIsNew(true);
      setShareHolderId(null);
      setShareStatus({
        status: '',
        reason: '',
      });
      console.log('direct1', shareHolders[sPage]);
    }
  }, [sData, shareHolders]);
  return (
    <>
      <div>
        <div style={{ flexDirection: 'row', textAlign: 'right' }}>
          {/* <button
            type="button"
            className="btn text-right"
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              background: sPage === 0 ? '#FF6700' : '#fff',
            }}
            onClick={() => (sPage > 0 ? setSPage(sPage - 1) : setSPage(0))}
          >
            Share Holder 1
          </button> */}
          {/* <button
            type="button"
            className="btn text-right"
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              background: sPage === 1 ? '#FF6700' : '#fff',
            }}
            onClick={() => (sPage < 1 ? setSPage(sPage + 1) : setSPage(1))}
          >
            Share Holder 2
          </button> */}
          <div>
            <label>
              <p
                style={{
                  color:
                    shareStatus?.status === 'APPROVED'
                      ? 'green'
                      : shareStatus?.status === 'REJECTED'
                      ? 'red'
                      : 'yellow',
                }}
              >
                {shareStatus?.status}
              </p>
              {shareStatus?.status === 'REJECTED' ? shareStatus?.reason : ''}
            </label>
          </div>
        </div>
        <form onSubmit={handleSave}>
          <div className="col-md-6">
            <label className="form-label">First Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.firstName}
              onChange={(e) => setData({ ...data, firstName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Other Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              value={data?.otherName}
              onChange={(e) => setData({ ...data, otherName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.lastName}
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className="form-control"
              value={data?.email}
              required
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Ownership Cut</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.ownershipCut}
              onChange={(e) =>
                setData({ ...data, ownershipCut: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">BVN</label>
            <input
              type="text"
              disabled={!isNew}
              className="form-control"
              required
              value={data?.bvn}
              onChange={(e) => setData({ ...data, bvn: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              required
              value={data?.dob}
              onChange={(e) =>
                setData({
                  ...data,
                  dob: moment(e.target.value).format('YYYY-MM-DD'),
                })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Nationality</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nationality}
              onChange={(e) =>
                setData({ ...data, nationality: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">State</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.state}
              onChange={(e) => setData({ ...data, state: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Local Government</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.lga}
              onChange={(e) => setData({ ...data, lga: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Full Address</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.address}
              onChange={(e) => setData({ ...data, address: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
         
          {/* {data?.idCardType && ( */}
          <div className="col-md-6">
            <label className="form-label">NIN</label>
            
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                //  disabled={!isNew}
                className="form-control"
                required={isNew}
                value={data?.idNumber}
                onChange={(e) => setData({ ...data, idNumber: e.target.value })}
              />
            </div>
          </div>
          {/* )} */}
          <div className="col-md-6">
            {/* <label className="form-label">Upload Id</label> */}
            <input
              hidden
              id="uploadId"
              //  disabled={data?.idCardType && data?.idNumber ? false : true}
              type="file"
              name="id"
              className="form-file"
              onChange={(e) => {
                // if (data?.idCardType) {
                //   handlePreviewId(e, 'id');
                // }
                handlePreviewId(e, 'id');
                e.target.value = null;
              }}
            />
            <label
              htmlFor="uploadId"
              className="btn col-md-12"
              //  disabled={files?.id?.status !== 'APPROVED' ? false : true}
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload ID
            </label>
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label text-right">
              <div
                style={{
                  display: data?.idCardUrl ? 'flex' : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.idCardUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.idCardUrl)}
                />
                <div
                  className={
                    files?.id?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <span>{files?.id?.status}</span>
                </div>
              </div>
            </label>
          </div>
          <div className="col-md-6">
            {/* <label className="form-label">Passport Photograph</label> */}
            <label
              htmlFor="uploadPass"
              className="btn col-md-12"
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload Passport
            </label>
            <input
              hidden
              id="uploadPass"
              type="file"
              // required={isNew}
              className="form-file"
              // onChange={(e) => setData({ ...data, passport: e.target.value })}
              onChange={(e) => {
                handlePreview(e, 'PASSPORT');
                e.target.value = null;
              }}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 10 }}>
              <div
                style={{
                  display: data?.passportUrl ? 'flex' : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.passportUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.passportUrl)}
                />
                <div
                  className={
                    files?.photo?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <span style={{ fontSize: 10 }}>{files?.photo?.status}</span>
                </div>
              </div>
            </label>
          </div>
          <div className="col-md-6">
            {/* <label className="form-label">Utility House</label> */}
            <label
              htmlFor="uH"
              className="btn col-md-12"
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload Utility Bill
            </label>
            <input
              hidden
              id="uH"
              type="file"
              //  required={isNew}
              className="form-file"
              onChange={(e) => {
                handlePreview(e, 'UTILITY_HOUSE');
                e.target.value = null;
              }}
              /* onChange={(e) =>
                // setData({ ...data, utilityHouse: e.target.value })
              } */
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 10 }}>
              <div
                style={{
                  display:
                    data?.utilityHouseUrl || files?.uHouse?.status
                      ? 'flex'
                      : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.utilityHouseUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.utilityHouseUrl)}
                />
                <div
                  className={
                    files?.uHouse?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <span style={{ fontSize: 10 }}>{files?.uHouse?.status}</span>
                </div>
              </div>
            </label>
          </div>
          {/* <div className="col-md-6">
            {/* <label className="form-label">Utility Office</label>
            <label
              htmlFor="uO"
              className="btn col-md-12"
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload Utility Office
            </label>
            <input
              hidden
              type="file"
              title=" "
              //  required={isNew}
              className="form-file col-md-12"
              onChange={(e) => {
                handlePreview(e, 'UTILITY_OFFICE');
                e.target.value = null;
              }}
              id="uO"
              /* onChange={(e) =>
                // setData({ ...data, utilityOffice: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 8 }}>
              {isNew && data?.utilityOfficeUrl ? (
                <img
                  className="mt-2"
                  src={data?.utilityOfficeUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.utilityOfficeUrl)}
                />
              ) : (
                <div
                  className={
                    files?.uOffice?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                >
                  <img
                    src={
                      files?.uOffice?.status === 'APPROVED'
                        ? check
                        : files?.uOffice?.status === 'REJECTED'
                        ? rejected
                        : pending
                    }
                    alt=""
                    className="me-2"
                  />
                  <span style={{ fontSize: 10 }}>{files?.uOffice?.status}</span>
                </div>
              )}
            </label>
          </div> */}
          <div className="col-md-6">
            <label className="form-label">Next of Kin First Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinFirstName}
              onChange={(e) =>
                setData({ ...data, nextOfKinFirstName: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Last Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinLastName}
              onChange={(e) =>
                setData({ ...data, nextOfKinLastName: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Email Address</label>
            <input
              type="email"
              className="form-control"
              required
              value={data?.nextOfKinEmail}
              onChange={(e) =>
                setData({ ...data, nextOfKinEmail: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Phone Number</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinPhone}
              onChange={(e) =>
                setData({ ...data, nextOfKinPhone: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Address</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinAddress}
              onChange={(e) =>
                setData({ ...data, nextOfKinAddress: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div style={{ textAlign: 'center' }}>
            {/* {isNew ? (
              <button className="btn btn-primary" type="submit">
                Save and continue
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Update
              </button>
            )} */}
            <button className="btn btn-primary" type="submit">
              Save and continue
            </button>
          </div>
        </form>
        {viewDocModal ? (
          <ViewDocModal
            showModal={viewDocModal}
            hideModal={setShowViewDocModal}
            data={viewData}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
