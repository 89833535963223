import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import useUser from 'components/Hooks/useUser';
import { wayaKYC } from 'plugins/urls';
import { apiCall } from 'utilities/apis';
import { Slide, toast } from 'react-toastify';

const initialData = {
  contactPersonAddress: '',
  contactPersonEmail: '',
  contactPersonName: '',
  designationOfContactPerson: '',
  companyType: 'RC',
};
const checkProperties = (obj) => {
  for (var key in obj) {
    if (obj[key] === '') return false;
  }
  return true;
};
const BusinessReg = (props) => {
  const { showModal, hideModal, getCacKyc, reload, setReload } = props;
  const { user } = useUser();
  const [data, setData] = useState(initialData);
  const [companyDetails, setCompanyDetails] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!checkProperties(data)) return;
    
        apiCall({
          url: `${wayaKYC}api/v1/kyc/cac/createKyc/${user.id}/${user?.profileSubscription?.profileId}`,
          method: "post",
          data,
          success: (resp) => {
            const data = resp.data;
            toast.success( resp?.message ?? resp?.message, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              })
            getCacKyc();
            if (resp.status) {
                hideModal(false);
            }
          },
        });
  };

  useEffect(() => {
    setData({
      ...data,
      companyType: data?.companyType ?? user?.posUserProfile?.businessType,
      cac: data?.cac ?? user?.cacRegistrationNumber,
      businessEmail:
        data?.businessEmail ?? user?.email,
      businessPhone:
        data?.businessPhone ?? user?.phoneNumber,
    });
    setCompanyDetails({
      ...companyDetails,
      companyName:
        companyDetails?.companyName ?? user?.posUserProfile?.orgName,
      address:
        companyDetails?.address ?? user?.posUserProfile?.officeAddress,
    });
  }, [user]);
  return (
    <Modal isOpen={showModal} id="" toggle={() => hideModal(false)} centered>
      <ModalHeader
        toggle={() => {
          hideModal(false);
          window.location.href = '/settings/account';
        }}
        className="text-left"
      >
        Cac Business Registration
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="inputbox-with-one-input">
            <label>Company Type</label>
            <select
              value={data?.companyType}
              onChange={(e) => {
                setData({ ...data, companyType: e.target.value });
              }}
            >
              <option value="RC">RC </option>
              <option value="BN">BN </option>
              <option value="IT">IT </option>
            </select>
          </div>
          <div>
            <label className="form-label">CAC Number</label>
            <div style={{ position: 'relative' }}>
              <Input
                type="number"
                //  disabled={!isNew}
                min={0}
                className="form-control"
                placeholder="1066277"
                required
                value={data?.cac}
                onChange={(e) => setData({ ...data, cac: e.target.value })}
              />
              <button
                type="button"
                disabled={!data?.cac}
                className={data?.cac ? 'btn-primary' : ''}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '0px',
                  height: '100%',
                  border: 'none',
                }}
              >
                validate
              </button>
            </div>
          </div>
          <div className="my-3">
            <label>Company Name</label>
            <Input
              required
              placeholder="Company Name"
              value={companyDetails?.companyName}
              style={{ color: 'black' }}
              onChange={(e) => setCompanyDetails({ ...companyDetails, companyName: e.target.value})}
            />
          </div>
          <div className="my-3">
            <label>Company Address</label>
            <Input
              required
              placeholder="Company Address"
              value={companyDetails?.address}
              onChange={(e) => setCompanyDetails({ ...companyDetails, address: e.target.value})}
              style={{ color: 'black' }}
            />
          </div>
          <div className="my-3">
            <label>Business Email</label>
            <Input
              required
              placeholder="Contact Person Name"
              value={data?.businessEmail}
              onChange={(e) =>
                setData({
                  ...data,
                  businessEmail: e.target.value,
                })
              }
            />
          </div>
          <div className="my-3">
            <label>Business Phone</label>
            <Input
              required
              placeholder="Contact Person Name"
              value={data?.businessPhone}
              onChange={(e) =>
                setData({
                  ...data,
                  businessPhone: e.target.value,
                })
              }
            />
          </div>
          {/* <div className="my-3">
            <label>Contact Email</label>
            <Input
              required
              placeholder="Contact Person Name"
              value={data?.contactPersonName}
              onChange={(e) =>
                setData({
                  ...data,
                  contactPersonName: e.target.value,
                })
              }
            />
          </div> */}
          <div className="my-3">
            <label>Contact Person Name</label>
            <Input
              required
              placeholder="Contact Person Name"
              value={data?.contactPersonName}
              onChange={(e) =>
                setData({
                  ...data,
                  contactPersonName: e.target.value,
                })
              }
            />
          </div>
          <div className="my-3">
            <label>Contact Person Email</label>
            <Input
              required
              type="email"
              placeholder="Contact Person Email"
              value={data?.contactPersonEmail}
              onChange={(e) =>
                setData({
                  ...data,
                  contactPersonEmail: e.target.value,
                })
              }
            />
          </div>
          <div className="my-3">
            <label>Contact Person Address</label>
            <Input
              required
              placeholder="Contact Address"
              value={data?.contactPersonAddress}
              onChange={(e) =>
                setData({
                  ...data,
                  contactPersonAddress: e.target.value,
                })
              }
            />
          </div>
          <div className="my-3">
            <label>Contact Designation</label>
            <Input
              required
              placeholder="Contact Designation"
              value={data?.designationOfContactPerson}
              onChange={(e) =>
                setData({
                  ...data,
                  designationOfContactPerson: e.target.value,
                })
              }
            />
          </div>
          <div className="my-3">
            <label>Business Registration Date</label>
            <Input
              required
              type="date"
              // disabled
              placeholder="Business Registration Date"
              value={data?.incorporationDate}
              style={{ color: 'black' }}
              onChange={(e) =>
                setData({
                  ...data,
                  incorporationDate: e.target.value,
                })
              }
            />
          </div>
          <div className="w-100 my-5 text-center">
            <button
              style={{}}
              type="submit"
              content="Submit"
              className="bg-primary w-50 p-2 rounded-1 text-white border-0"
            >
              Submit
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default BusinessReg;
