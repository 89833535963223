import React, {useContext, useState} from 'react';
import format from 'date-fns/format'
import {Paper, Grid, Container} from '@mui/material';
import {DisputeContext} from "../../pages/Disputes/Disputes";
import Col from "react-bootstrap/Col";
import {statusColors} from "../../utilities/dummyData";
import {RiDeleteBin6Line} from "react-icons/ri";
import DocumentPreviewer from "../../pages/Settings/pages/updateAccount/components/docPreviewer";
import Row from "react-bootstrap/Row";
import Button from "@material-ui/core/Button";
import {BiArrowBack} from "react-icons/bi";
import {apiCall} from "../../utilities/apis";
import {createAuthDisputes, deleteAuthDispute} from "../../plugins/urls";
import {Slide, toast} from "react-toastify";
import Modal from "../Modal/Modal";
import Loader from "../Loader";

const ResolveAuthDispute = () => {
	const {state, setState} = useContext(DisputeContext);
	const dispute = state?.dispute;
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	const complainAgain = () => {
		const data = new FormData();
		data.append('attachment', dispute?.attachment);
		apiCall({
			url: `${createAuthDisputes}?category=${dispute?.category}&description=${dispute?.description}`,
			data, defaultMeta: true, isSuccess: (data) => data.respDesc === 'Dispute has been created',

			method: 'post', setLoading, success: (data) => toast.success('Dispute successfully logged again', {
				transition: Slide,
				hideProgressBar: true,
				autoClose: 3000,
			})
		});
	};
	const deleteDispute = () => apiCall({
		url:`${deleteAuthDispute}?disputeId=${dispute?.id}`, setLoading, method: 'delete', defaultMeta: true,
		success: (data) => {
			toast.success( data, {
				transition: Slide,
				hideProgressBar: true,
				autoClose: 3000,
			});
			setState({...state, dispute: {}});
		}
	});

	return (
		<Container container>
			<div className='mb-2 d-flex'>
				<Button className=' ms-2' onClick={() => setState({...state, dispute: {}})}>
					<BiArrowBack  />
				</Button>

				<div className="ms-3">
					{`Dispute Ticket I.D #${dispute.trackingNumber}`}
				</div>
			</div>

			<Row item>
				<Col xs={12} md={6} xl={8}>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border'>
						<div className="">Status</div>
						<div className={`text-${statusColors[dispute?.status] || ''}`}>
							{dispute?.status}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Type</div>
						<div className=''>
							{dispute?.status}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Category</div>
						<div className=''>
							{dispute?.subject}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Dispute Amount</div>
						<div className=''>
							{dispute?.status}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Transaction Charges</div>
						<div className=''>
							{dispute?.status}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Transaction Date</div>
						<div className=''>
							{format(new Date(dispute?.transactionDate), 'do, MMM. yyyy')}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Dispute Date</div>
						<div className=''>
							{format(new Date(dispute?.initiationDate), 'do, MMM. yyyy')}
						</div>
					</div>
					<div className='w-100 p-3 d-flex justify-content-between align-items-center border border-top-0'>
						<div className="">Dispute Descriptions</div>
						<div className=''>
							{dispute?.description}
						</div>
					</div>

				</Col>
				<Col xs={12} md={6} xl={4}>
					<DocumentPreviewer doc={dispute?.attachment} />
				</Col>
			</Row>
			<Row className='mt-3'>
				<Paper>
					<div>ACTIONS</div>
					<div className="d-flex align-items-center py-2">
						<div><span className="text-muted me-3">Dissatisfied?</span></div>
						<button className='orange-button' disabled={loading} onClick={complainAgain}>
							{
								loading ? 'working...': 'Complain Again'
							}
						</button>
						<Button className='d-flex align-items-center ms-2' onClick={() =>setShow(true)}>
							<RiDeleteBin6Line className='text-orange'  />
							Delete Dispute
						</Button>

						<Modal
							children={
								loading
									? <Loader />
									: (
										<div>
											<div className="text-center">
												<p className="text-danger fs-18 fw-400">Are you sure you want to delete this dispuet?</p>
												<small>This action will close your dispute and it is irreversible</small>
											</div>
											<div className="d-flex justify-content-center mt-5">
												<div className="d-flex ">
													<Button color='primary' onClick={() => setShow(false)}>No, Cancel</Button>
													<Button color='secondary' onClick={deleteDispute}>Yes, Proceed</Button>
												</div>
											</div>
										</div>
									)
							}
							show={show}
							click={() => setShow(false)}
							title={`Delete dispute ${dispute.trackingNumber}`}
						/>
					</div>
				</Paper>
			</Row>
		</Container>
	);
};

export default ResolveAuthDispute;
