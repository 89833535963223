import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import format from 'date-fns/format'
import {changeHandler} from "../../utilities/handlers";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import {apiCall} from "../../utilities/apis";
import {createAuthDisputes, createWayaDispute} from "../../plugins/urls";
import useUser from "../Hooks/useUser";
import {uploadModal} from "../../pages/Settings/pages/updateAccount/screens/KYC/temps";
import {Slide, toast} from "react-toastify";
import NumberFormat from "react-currency-format";
import {formatDonation2} from "../../utilities/stringOperations";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";

const CreateAuthenticationDispute = ({close}) => {
	const user = useUser();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const handleChange = (e) => changeHandler({e, setFormData});
	const handleDateChange = (date) => setFormData({...formData, transactionDate: date});
	const submit = () => {
		const data = new FormData();
			data.append('attachment', formData?.attachment);
		apiCall({
			url: `${createAuthDisputes}?category=${formData?.category}&description=${formData?.description}
			&transactionCharges=${formatDonation2(formData.charges)}&transactionDate=${format(formData.transactionDate, 'yyyy-MM-dd')}
			&disputeAmount=${formatDonation2(formData.amount)}&disputeType=${formData.disputeType}`,
			data, defaultMeta: true, isSuccess: (data) => data.respDesc === 'Dispute has been created',

			method: 'post', setLoading, success: (data) => toast.success('Dispute successfully logged', {
				transition: Slide,
				hideProgressBar: true,
				autoClose: 3000, onClose: close
			})
		});
	};
	return (
		<div
			className='px-2 mx-auto pb-4'
			style={{
				width: '80%',
				fontFamily: 'Lato',
				fontStyle: 'normal',
				color: '#4F4F4F',
			}}>

			Payments
			Social
			Auth & Notifications
			Others

			<FormControl variant='outlined' className='col-12 my-2'>
				<InputLabel id='demo-simple-select-outlined-label'>
					Dispute Category
				</InputLabel>
				<Select
					name='category'
					value={formData.category}
					onChange={handleChange}
					label='Dispute Category'
				>
					<MenuItem value=''>
						<em>Select Category of this Dispute</em>
					</MenuItem>

					<MenuItem value='Payments'>Payments</MenuItem>
					<MenuItem value='Social'>Social</MenuItem>
					<MenuItem value='Auth & Notifications'>Auth & Notifications</MenuItem>
					<MenuItem value='Others'>Others</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant='outlined' className='col-12 my-2'>
				<InputLabel id='demo-simple-select-outlined-label'>
					Dispute Type
				</InputLabel>
				<Select
					name='disputeType'
					value={formData.disputeType}
					onChange={handleChange}
					label='Dispute Type'
				>
					<MenuItem value=''>
						<em>Select Type of Dispute</em>
					</MenuItem>

					<MenuItem value='Chargeback'>Charge back</MenuItem>
					<MenuItem value='Fraud'>Fraud</MenuItem>
					<MenuItem value='Complaint'>Complaint</MenuItem>
					<MenuItem value='Unsettled_transaction'>Unsettled Transaction</MenuItem>
				</Select>
			</FormControl>
			<NumberFormat className="w-100 my-3" value={formData?.amount}  onChange={handleChange} name="amount" label="Transaction Amount" variant="outlined" customInput={TextField} thousandSeparator prefix='&#8358;'  />
			<NumberFormat className='w-100 mb-3' value={formData?.charges}  onChange={handleChange} name="charges" label="Transaction Charges" variant="outlined" customInput={TextField} thousandSeparator prefix='&#8358;'  />
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DesktopDatePicker
					label='Transaction Date'
					inputFormat='yyyy-MM-dd'
					name='transactionDate'
					value={formData.transactionDate}
					onChange={handleDateChange}
					renderInput={(params) => (
						<TextField className='w-100' variant='outlined'{...params} />
					)}
					date={formData.date}

				/>
			</LocalizationProvider>
			<TextField
				className='col-12 my-2'
				value={formData.attachment?.path}
				// onChange={handleChange}
				onClick={() => setOpen('attachment')}
				name='attachment'
				label='Attachment'
				variant='outlined'
			/>

			<TextField
				className='col-12 my-2'
				value={formData.description}
				onChange={handleChange}
				name='description'
				multiline
				rows={5}
				label='Dispute description'
				variant='outlined'
			/>

			<div className="modalfooter">
				<button type="submit" className="orange-button extra-padding" onClick={submit}>
					{
						loading ?
							<SubmitLoader />
							:
							'Submit'
					}
				</button>
			</div>
			{uploadModal(handleChange, setOpen, open, () => setOpen(false))}
		</div>
	);
};

export default CreateAuthenticationDispute;
