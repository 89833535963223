import React from 'react';
import './loader.scss'

const EllipsisLoader = () => {
    return (
        <div className='align-items-center d-flex justify-content-center'>
            <div className="lds-ellipsis">
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>    )
}

export default EllipsisLoader
