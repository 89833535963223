import React from "react";
import Drawer from "@material-ui/core/Drawer";
import MobileList from "./MobileList";
import { externalLinks, sidebarMenu } from "../../../utilities/dummyData";
import Button from "@material-ui/core/Button";
import Col from "react-bootstrap/Col";
import { FcMenu } from "react-icons/fc";

const MobileSidebar = ({ title, setTitle }) => {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => setState(open);

  return (
    <React.Fragment>
      <Col lg={1} sm={1} md={1} xs={2} className="hide">
        <Button onClick={() => toggleDrawer(true)}>
          <FcMenu />
        </Button>
      </Col>
      <Drawer anchor="left" open={state} onClose={() => toggleDrawer(false)}>
        {MobileList({
          sidebarMenu: sidebarMenu(title),
          externalLinks,
          toggleDrawer,
          title,
          setTitle,
        })}
      </Drawer>
    </React.Fragment>
  );
};
export default MobileSidebar;
