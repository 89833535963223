import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { DisputeContext } from "../../pages/Disputes/Disputes";
import {
  allWayaDisputes,
  filterWayaDisputes,
  wayaDisputesMetrics,
} from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import { disputesFilters, disputesTableCols } from "../../utilities/dummyData";
import useSimpleTable from "../Hooks/useSimpleTable";
import useUser from "../Hooks/useUser";
import Loader from "../Loader";
import Modal from "../Modal/Modal";
import CreateWayaDispute from "./CreateWayaDispute";
import "./dispute.scss";
import WayaCards from "./WayaCards";

const WayaDispute = () => {
  const { user } = useUser();

  const { state, setState } = useContext(DisputeContext);

  const [metrics, setMetrics] = useState({});
  const [loadingCre, setLoadingCre] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const tableAction = (dispute) => setState({ ...state, dispute });

  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: allWayaDisputes,
    method: "get",
    includeMeta: true,
    filters: disputesFilters,
    parseString: true,
    filterUrl: filterWayaDisputes,
    filterMethod: "get",
    showSearchIcon: true,
    csvName: "WAYA_POS_DISPUTES_",
    dateFilterMenuName: "date",
    cols: disputesTableCols(
      tableAction,
      <AiFillEye size={20} color="#FF6700" />
    ),
    // filter2: true,
    dataAddOn: {
      userid: user?.id,
    },
    // deps: [walletNo],
    startCb: () => {
      getSettlementStats();
      // getUserWallets();
    },
  });

  const getSettlementStats = () =>
    apiCall({
      url: wayaDisputesMetrics,
      method: "get",
      setLoading,
      defaultMeta: true,
      success: (data) => setMetrics(data),
    });

  return loading ? (
    <Loader />
  ) : (
    <>
      {/* <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>
          {tableData?.content?.length > 0 && exportButton}
          <div className="d-flex justify-content-center align-items-center">
            <div className="request-button" onClick={() => setOpen(true)}>
              <VscAdd color={"#fff"} className="mr-5" />
              Log a Complaint
            </div>
          </div>
        </div>
      </div> */}

      <Container fluid>
        <Row className="mt-40">
          <Col>
            <WayaCards
              color="text-orange"
              title="DISPUTE NEED RESPONSE"
              number={metrics?.needResponse}
            />
          </Col>
          <Col>
            <WayaCards
              color="text-sharp-green"
              title="WON DISPUTE"
              number={metrics?.won}
            />
          </Col>
          <Col>
            <WayaCards
              color="text-deep-grey"
              title="LOST DISPUTE"
              number={metrics?.lost}
            />
          </Col>
          <Col>
            <WayaCards
              color="text-orange"
              title=" DISPUTE UNDER RIVIEW"
              number={metrics?.underReview}
            />
          </Col>
        </Row>

        <div className="mt-40">{simpleTable}</div>
        <Modal
          show={open}
          clicked={() => setOpen(false)}
          loading={loadingCre}
          title="Create New Dispute"
        >
          <CreateWayaDispute
            close={() => setOpen(false)}
            setLoadingCre={setLoadingCre}
          />
        </Modal>
      </Container>
    </>
  );
};

export default WayaDispute;
