import DragNDropFileInput from "./DragNDropFileInput";
import SubmitLoader from "../../../../../components/SubmitLoader/SubmitLoader";
import React from "react";
import { Input } from "antd";

const UploadProfilePicture = ({ uploadProfile, uploadPic, btnTxt = 'Upload',setPostData, postData, setIdType, loading, onChange, examples, title = 'Display Picture', name = 'profile', label = 'profile picture' }) => (
    <div>
        <div>{title}</div>
        {/* <div className={isFile ? '' :  'd-none'}> */}
        {title === 'NATIONAL IDENTITY' && <div className="my-3">
            <select
              className="border border-2 border-radius-2"
              onChange={(e) => setPostData({...postData, title, fileName: e.target.value})}
            >
              <option value="" disabled selected>
                Select Identification Type
              </option>
              <option value="INTERNATIONAL_PASSPORT">International Passport</option>
              <option value="NATIONAL_ID">National Identification Card</option>
              <option value="VOTERS_CARD">Voters Card</option>
              <option value="LICENSE">Drivers Licence</option>
            </select>
              <div className="my-3">
                <Input
                  placeholder="Identitfication Number"
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>}
        <DragNDropFileInput
            name={name}
            supportedFiles={['jpeg,', ' ', 'jpg']}
            onChange={onChange}
            uploadPic={uploadPic}
            label={label}
            examples={examples}
            title={title}
            postData={postData}
            setPostData={setPostData}
        />
        <div className="d-flex justify-content-end">
            <button color="warning" className="mt-20 orange-button" onClick={uploadProfile}>
                {   loading ?
                    <SubmitLoader className="left-43" />
                    :
                   <div className='text-center w-100'>{btnTxt}</div>
                }
            </button>
        </div>
    </div>
);
export default UploadProfilePicture;
