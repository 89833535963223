import React, { useState } from 'react';
// @mui
import {
  Stack,
  Box, TextField, IconButton, OutlinedInput, Input, InputLabel, FormControl
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useResetPassword from "../../../../../components/Hooks/useResetPassword";
import SubmitLoader from "../../../../../components/SubmitLoader/SubmitLoader";
import {useNavigate} from "react-router-dom";

export default function ChangePasswordComponent({ dat, success }) {
    const navigate = useNavigate();

    const [showConfirm, setShowConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { handleChangePassword, formData, loading, submitPassword} = useResetPassword({ success, dat });

  return (
    <Stack spacing={3}>
      <TextField value={formData.oldPassword} name="oldPassword" label="Old Password" type="password" variant="standard" onChange={handleChangePassword} />

      <FormControl sx={{ m: 1 }} variant="standard">
        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
        <Input
          id="outlined-adornment-password"
          onChange={handleChangePassword}
          name="newPassword"
          value={formData.newPassword}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <FormControl sx={{ m: 1, }} variant="standard">
        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
        <Input
          name="confirm_password"
          value={formData.confirm_password}
          id="outlined-adornment-password"
          onChange={handleChangePassword}
          type={showConfirm ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirm(!showConfirm)}
                edge="end"
              >
                {showConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
          {
              formData?.newPassword && formData?.confirm_password && formData?.newPassword !== formData?.confirm_password
              && <p className='text-danger'>passwords do not match</p>
          }
      </FormControl>

        <button
            disabled={formData?.newPassword === '' || formData?.confirm_password === '' || formData?.newPassword !== formData?.confirm_password || formData.oldPassword === ''}
            className="mt-20 orange-button full-width" onClick={submitPassword}>
            {   loading ?
                <SubmitLoader className="left-43" />
                :
                'Change Password'
            }
        </button>
        <div className="text-center mt-20">
            <h4 className="fs-16 fw-400 text-default cursor-pointer" onClick={()=>navigate('/login')} >Back to Sign in?</h4>
        </div>

    </Stack>
  );
}
