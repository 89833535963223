import Button from "@material-ui/core/Button";
import { isEmpty } from "lodash";
import moment from "moment";
import { createContext } from "react";
import { Table } from "react-bootstrap";
import NoResultFound from "../../NoResultFound/NoResultFound";
import TableFooter from "./TableFooter";
export const SimpleTableContext = createContext();

const SimpleTable = ({
  color = "text-orange",
  bg = "bg-orange-light",
  cols,
  tableData,
  setEntity,
}) => {
  const renderTd = (type, val, col, data, sub1, sub2) => {
    console.log('yyli', data?.sub2?.sub1)
    console.log({val});
    if (type === undefined) return val || "";
    if (type === "object" && val) {
      let toDes = val.split(".");
      let res = data;
      toDes.forEach((element) => {
        res = res[element];
      });
      return res;
    }
    switch (type) {
      case "date":
        return moment(val).format("MMMM Do YYYY, h:mm a");
      case "currency":
        return (
          <span>
            <span>&#8358;</span>
            <span className="ps-2">{val?.toLocaleString() || ""}</span>
          </span>
        );
      case "number":
        return (
          <span className="ps-2">{Number(val?.toLocaleString()) || ""}</span>
        );
      case "subValue":
      return (
        <span className="ps-2">{(data[sub1][sub2]?.toLocaleString()) || ""}</span>
      );
      case "number1":
          return (
            <span className="ps-2">{(Number(val?.toLocaleString()))/100 || ""}</span>
      );
      case "action":
        console.log(val,"val")
        return (

          <Button onClick={() => col.click(data)} className="ps-2">
            {val}
          </Button>
        );

      default:
        return val || null;
    }
  };
  return (
    <SimpleTableContext.Provider
      value={{
        color,
        bg,
        cols,
        setEntity,
        ...tableData,
        ...tableData.pageable,
      }}
    >
      <div>
        <Table
          responsive
          borderless
          className="bg-inherit table table-responsive"
        >
          <thead className={`${bg} ${color} ps-5`}>
            <tr className="ps-3" style={{ borderRadius: "5px" }}>
              {cols.map((col) => (
                <th key={col.name}>{col.name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {isEmpty(tableData?.content || tableData) ? (
              <NoResultFound />
            ) : (
              tableData?.content?.map((data) => {
                return (
                  <tr key={data?.id}>
                    {cols.map((col, key) => (
                      <td key={key}>
                        <div
                          className={
                            col?.cls
                              ? `
                                ${
                                  col?.cls[
                                    data[col?.clsIndex]?.toLowerCase()
                                  ] || ""
                                }
                                ${col?.cls?.other || ""}
                                `
                              : ""
                          }
                        >
                          {renderTd(
                            col?.type,
                            data[col?.value] ||  col?.value || "N/A",
                            col,
                            data,
                            col?.sub1,
                            col?.sub2,
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {tableData?.content?.length > 0 && <TableFooter />}
      </div>
    </SimpleTableContext.Provider>
  );
};

export default SimpleTable;
