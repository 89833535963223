import axios from "axios";
import { useState } from "react";
import { getPosMerchantProfile, getUserProfile } from "../../plugins/urls";
import { apiCall2 } from "../../utilities/apis2";

const useUserDetails = () => {
  const [userDetails, setUserDetails] = useState({});
  const getUserDetails = (id, success, token) => {
    let ud = {};
    apiCall2({
      url: `${getUserProfile}${id}`,
      method: "get",
      token,
      success: async (dat) => {
        axios
          .get(`${getPosMerchantProfile}`, {
            headers: {
              Authorization: `serial ${token}`,
            },
          })
          .then((resp) => {
            ud = {
              ...dat?.data,
              posUserProfile: resp?.data?.respBody || { merchantId: "" },
            };
            setUserDetails(ud);
          })
          .finally(() => {
            success(ud);
          });
      },
    });
  };

  return { userDetails, getUserDetails };
};

export default useUserDetails;
