import card from './icons/card.svg';
import phoneDot from './icons/phone_dot.svg';
import transfer from './icons/transfer.svg';
import transferOutlined from './icons/transferOutlined.svg';
import network from './icons/network.svg';
import tv from './icons/tv.svg';
import close from './icons/close.svg';
import walletOutlined from './icons/wallet-outlined.svg';
import dstv from './icons/dstv.svg';
import gotv from './icons/gotv.svg';
import startimes from './icons/startimes.svg';
import mtn from './icons/mtn.svg';
import glo from './icons/glo.svg';
import ninemobile from './icons/9mobile.svg';
import airtel from './icons/airtel.svg';
import nairabet from './icons/nairabet.svg';
import betway from './icons/betway.svg';
import surebet from './icons/surebet.svg';
import notification from './icons/notifications.svg';
import guide from './icons/guide.svg';
import api from './icons/api.svg';
import dashGuide from './icons/dashGuide.svg';
import faqGuide from './icons/faqGuide.svg';
import feedback from './icons/feedback.svg';
import exportCopy from './icons/exportCopy.svg';
import securedPin from './icons/securedPin.svg';

export const SVG = {
    tv, card, phoneDot, transfer, network, walletOutlined, close, transferOutlined, startimes, dstv, gotv, ninemobile, glo, mtn, airtel, surebet, nairabet, betway, notification,
    guide, api, dashGuide, faqGuide, feedback, exportCopy, securedPin
};
