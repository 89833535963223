const validateImage = (file) => {
    let valid = true;
    let message = 'uploading...';
    /* eslint-disable no-plusplus */
    for (let i = 0; i < file.length; i++) {
      if (file[i] && !/\/(jpg|jpeg|tiff|png)$/i.test(file[i].type)) {
        valid = false;
        message = 'Invalid picture format';
      }
  
      if (file[i] && parseFloat(file[i].size) > 10097152) {
        valid = false;
        message = 'File size exceeds 10MB';
      }
    }
  
    return { valid, message };
  };
  
  export default validateImage;