import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Button, Box } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import AccountStatement from '../components/AccountStatement';
import useUser from "../../../../../components/Hooks/useUser";
// import Statement from '../components/Statement';

GenerateAccountStatement.propTypes = {
	setCurrentTab: PropTypes.func,
};

export default function GenerateAccountStatement({ setCurrentTab }) {
	const { user } = useUser();
	return (
		<Container>
			<Box className='border bg-white p-4'>
				<div>
					<Button onClick={() => setCurrentTab({ component: null })}>
						<div className='d-flex align-items-center' style={{ color: 'black' }}>
							<ArrowBackIosOutlinedIcon />
							<small className='ml-1'>Back</small>
						</div>
					</Button>
				</div>

				<div className=''>
					<AccountStatement />
				</div>
			</Box>
		</Container>
	);
}
