export const terminalRequestCard = {
  totalTerminalRequest: {
    name: "Total Requested Terminals",
    color: "text-semi-dark",
    textColor: "text-semi-dark",
  },
  totalApproved: {
    name: "Total Approved Terminals",
    color: "text-sharp-green",
    textColor: "text-sharp-green",
  },
  totalRejected: {
    name: "Total Rejected Terminals",
    color: "text-red",
    textColor: "text-red",
  },
};
