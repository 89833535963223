import * as React from 'react';
import FilePreview from 'react-file-preview-latest';

const DocumentPreviewer = ({ doc }) => (
  <FilePreview
    type={"file"}
    file={doc}
    // onError={this.onError}
  />

);
export default DocumentPreviewer;
