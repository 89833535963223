import React from 'react';
import './cards.scss';

const Cards = (props) => {
	return (
		<div className={`cards w-100 h-100 my-2 my-lg-0 ${props.bg ? props.bg : ''} `}>
			<h4 className={`${props.color ? props.color : ''} fs-14 fw-700`}>
				{props.cardTitle}
			</h4>
			<h4
				className={`${props.textColor ? props.textColor : ''} ${
					props.size ? props.size : 'fs-24'
				} fw-700 mt-20`}>
				<span>{!props.noCurrency && 'NGN'}</span>
				<span className="ps-2"> {props?.value || 0}</span>
			</h4>
			<h4
				className={`${
					props.date ? 'text-semi-dark' : ''
				} fs-12 fw-400 mt-20 m-0`}>
				{props.date}
			</h4>
		</div>
	);
};

export default Cards;
