import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { allOtherDisputes2, filterOtherDispute2 } from "../../plugins/urls";
import Modal from "../Modal";
import AuthCard from "./AuthCard";

import { AiFillEye } from "react-icons/ai";
import { DisputeContext } from "../../pages/Disputes/Disputes";
import {
  otherDisputesFilters,
  otherDisputesTableCols,
} from "../../utilities/dummyData";
import useSimpleTable from "../Hooks/useSimpleTable";
import useUser from "../Hooks/useUser";
import CircularLoader from "../SubmitLoader/CircularLoader";
import EllipsisLoader from "../SubmitLoader/ElipseLoader";
import CreateOtherDispute from "./CreateOtherDispute";

const OtherDispute = () => {
  const { user } = useUser();

  const { state, setState } = useContext(DisputeContext);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const statusCount = (data, status) =>
    data?.filter((item) => item?.status === status)?.length;

  const tableAction = (dispute) => setState({ ...state, dispute });

  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: allOtherDisputes2,
    method: "get",
    includeMeta: true,
    filters: otherDisputesFilters,
    filterUrl: filterOtherDispute2,
    filterMethod: "get",
    showSearchIcon: true,
    csvName: "WAYA_POS_OTHER_DISPUTES_",
    passDate: false,
    dateFilterMenuName: "date",
    cols: otherDisputesTableCols(
      tableAction,
      <AiFillEye size={20} color="#FF6700" />
    ),
    isRange: false,
    dateName: "date",
    parseString: `&username=${user?.email}&deleted=false`,
    // filter2: true,
    // dataAddOn: {
    //   userid: user?.id,
    // }
  });

  const metricCards = [
    { title: "Other Disputes", filterValue: "", color: "text-darker" },
    {
      title: "Resolved Disputes",
      filterValue: "CLOSED",
      color: "text-sharp-green",
    },
    { title: "Pending Disputes", filterValue: "PENDING", color: "text-red" },
    { title: "Under Review", filterValue: "OPEN", color: "text-yellow" },
  ];

  return (
    <>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>
          {/*  <div className="d-flex ">
            {
              tableData?.content?.length > 0 && exportButton
            }
            <div className="d-flex justify-content-center align-items-center">
              <div className="request-button" onClick={() => setOpen(true)}>
                <VscAdd color={'#fff'} className="mr-5 fw-bold fs-14" />
                Log a Complaint
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <Container fluid>
        {loading ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            {metricCards.map(({ color, title, filterValue }) => (
              <Col xs={6} lg={3} className="mt-2">
                <AuthCard
                  color={color}
                  cardTitle={title}
                  value={
                    filterValue === ""
                      ? tableData?.content?.length || 0
                      : statusCount(tableData?.content, filterValue) || 0
                  }
                />
              </Col>
            ))}
          </Row>
        )}

        <div className="mt-40">
          {loading ? <CircularLoader /> : simpleTable}
        </div>
      </Container>

      <Modal
        show={open}
        onclick={() => setOpen(false)}
        loading={loading}
        title="Create New Dispute"
      >
        <CreateOtherDispute close={() => setOpen(false)} />
      </Modal>
    </>
  );
};

export default OtherDispute;
