import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from '../../../../../components/Modal';

const RemoveMember = ({ show, onClose, submit, user }) => {
	return (
		<>
			<Modal show={show} onclick={onClose} title='Remove Team Member'>
				<div className='modal-form' style={{ width: '80%' }}>
					<p
						className='text-center fs-12 py-3 mx-auto'
						style={{ width: '90%' }}>
						By doing this, <strong>{user.email}</strong> will no
						longer be able to access this business anymore. Do you
						want to continue?
					</p>

					<Button
						type='submit'
						onClick={submit}
						size='md'
						className='mx-auto py-2 w-75'
						style={{
							display: 'block',
							backgroundColor: '#FF6700',
							border: '0.5px solid #FF6700',
							fontWeight: '500',
							fontSize: '.8rem',
						}}>
						Yes, Remove
					</Button>
					<p
						onClick={onClose}
						style={{
							display: 'block',
							color: '#FF6700',
							fontSize: '.7rem',
							fontWeight: '400',
							cursor: 'pointer',
						}}
						className='m-auto my-3 text-decoration-none text-center'>
						Cancel
					</p>
				</div>
			</Modal>
		</>
	);
};

export default RemoveMember;
