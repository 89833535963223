import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SettingsMenus, { settingsMenu } from "./components/settingsMenus";
import Layout from "../../components/Layout/Layout";
import {
  MdOutlineArrowDropDownCircle,
  MdOutlineArrowCircleUp,
} from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router";
import TeamMembers from "./pages/TeamMembers";
import axios from "axios";
import { get2FaStatus } from "plugins/urls";
import useUser from "components/Hooks/useUser";

export default function Settings() {
  const [currentTab, setCurrentTab] = useState({});
  const { user, token } = useUser();
  const [show, setShow] = useState(false);
  const {
    state: { enableTeamMember },
  } = useLocation();
  const get2Fa = () => {
    axios
    .get(get2FaStatus({userId: user?.id}))
    .then((res) => {
      if (res.status) {
        localStorage.setItem('2fa', JSON.stringify(res?.data?.data['2fa']));
        //  updateSuccess(userId);
      }
    })
  }
  useEffect(() => {
    get2Fa();
    console.log('k');
    setCurrentTab(settingsMenu({ setCurrentTab, invitees: 15 })[0]);
  }, []);
  const menus = () => {
    const menuList = settingsMenu({ setCurrentTab, invitees: 15 });
    if (enableTeamMember) {
      menuList.splice(2, 0, {
        name: "Team Members",
        description: "Roles & Access Management.",
        page: <TeamMembers setCurrentTab={setCurrentTab} />,
      });
    }
    return menuList;
  };
  return (
    <Layout title="Settings" className="">
      <div className="">
        {!currentTab?.component ? (
          <Box>{currentTab?.page}</Box>
        ) : (
          <Grid container spacing={2} className="">
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#fff" }}
              className="d-flex justify-content-center d-lg-none"
            >
              <div>
                <div className="d-flex justify-content-between">
                  <div className="col-md-8">&nbsp;</div>
                  <div className="d-flex justify-content-end">
                    <IconButton onClick={() => setShow(!show)}>
                      {show ? (
                        <MdOutlineArrowCircleUp />
                      ) : (
                        <MdOutlineArrowDropDownCircle />
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              className={show ? "pt-0 mx-4" : "pt-0 d-none d-lg-block"}
            >
              <Box className="mt-0">
                <SettingsMenus
                  className=""
                  setCurrent={setCurrentTab}
                  currentTab={currentTab}
                  menu={menus()}
                  clss={{
                    main: "mt-0 pointer",
                    name: "",
                    icon: "mt-2",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={8} className="">
              <Box>
                <div className="">{currentTab?.component}</div>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    </Layout>
  );
}
