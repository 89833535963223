import {Box} from "@mui/material";
import {isEmpty} from 'lodash';
import React, {useState} from "react";
import DragNDropFileInput from "../../../pages/Settings/pages/updateAccount/components/DragNDropFileInput";
import {apiCall} from "../../../utilities/apis";
import {acceptDisputeChargeback, otherFile, rejectDisputeChargeback} from "../../../plugins/urls";
import useUser from "../../Hooks/useUser";
import {Loader} from "semantic-ui-react";
import {formatDonation2, stringDoesNotExist} from "../../../utilities/stringOperations";
import Form from "react-bootstrap/Form";
import Backdrop from "../../Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

const RejectChargeBack = ({formData, handleChange, dispute, handleResolve}) => {
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);

    /* ###### HOOK #######*/
    const {user} = useUser();

    /* ###### API #######*/

    // get file path
    const addFile = () => {
        const data = new FormData();
        data.append('file', formData?.attachment);
        apiCall({
            url: `${otherFile}?fileName=${formData?.filename}&userId=${user?.id}`,
            setLoading: setLoadingFile, data, defaultMeta: true, method: 'post',
            success: res => handleChange({target: {name: 'filePath', value: res.data}}),
        })
    };

    // reject with proof
    const rejectChargeBack = () => {
        const data = {
            amount: formData?.fullRefund ? dispute?.amount : formatDonation2(formData?.amount),
            comment: formData?.comment,
            disputeId: dispute?.id,
            fileName: formData?.filename,
            filePath: formData?.filePath,
            merchantId: dispute?.merchantID,
        };
        apiCall({
            url: rejectDisputeChargeback, setLoading, data, defaultMeta: true, success: handleResolve, method: 'put'
        })
    };

    /* ###### LOGIC #######*/

    const titleText = () => {
        if (isEmpty(formData?.attachment)) return 'First attach some sort of proof as why you are rejecting this dispute'
        else {
            if (stringDoesNotExist(formData?.filename)) return 'You must provide a name for this attached file'
        }
        return 'Add this file to support your action'
    };

    /* ###### DISPLAY #######*/

    return loading
    ? <Loader />
    : (
            <Box className='mt-4'>
                <h3>Upload Documents <span className="text-danger">*</span></h3>
                <p
                    className='my-4 text-dark fw-normal'
                    style={{
                        wordSpacing: '1px',
                        fontSize: '.8rem',
                    }}>
                    You now need to upload further evidence. This could be a proof of delivery, an invoice or some user corespondence.
                </p>

                <div className="my-2">
                    <Form.Control
                        as='input'
                        onChange={handleChange}
                        name='filename'
                        placeholder='Give this file a name (Important)'
                        style={{
                            height: '15px',
                            backgroundColor: 'transparent',
                            color: '#000',
                            resize: 'none',
                        }}
                        className='w-100 fs-12 form-style mt-2 mb-3'
                    />
                </div>

                <div className="d-flex flex-wrap my-3">
                    <DragNDropFileInput onChange={handleChange} supportedFiles='JPEG, jpg (images)' name='attachment' dotted={true} noBtn={true} text='Click or drag files to upload' />
                    <div className="d-flex justify-content-center align-items-center">
                        <Tooltip title={titleText()}>
                            <div>
                                <Button
                                    onClick={addFile}
                                    disabled={isEmpty(formData?.attachment) || stringDoesNotExist(formData?.filename)}
                                    style={{
                                        backgroundColor: !loadingFile ? '#858585' : '',
                                        border: !loadingFile ? '0.5px solid #858585' : '',
                                        color: !loadingFile ? '#fff' : '',

                                    }}
                                    className="fs-10 ms-1"
                                >
                                    {
                                        loadingFile
                                            ?  <CircularProgress color="inherit" />
                                            : `Add ${formData?.attachment?.length > 1 ? 'These Files' : 'This File'}`
                                    }
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <div className="my-2">
                    <Form.Control
                        as='textarea'
                        onChange={handleChange}
                        name='comment'
                        aria-required='true'
                        placeholder='Add a comment'
                        style={{
                            height: '15px',
                            backgroundColor: 'transparent',
                            color: '#000',
                            resize: 'none',
                        }}
                        className='w-100 fs-12 form-style mt-2 mb-3'
                    />
                </div>

                <Button
                    type='submit'
                    onClick={rejectChargeBack}
                    size='md'
                    disabled={stringDoesNotExist(formData?.filePath)}
                    className='mx-auto py-2 fw-semi-bold'
                    style={{
                        float: 'right',
                        clear: 'all',
                        width: '45%',
                        display: 'block',
                        backgroundColor: '#FF6700',
                        border: '0.5px solid #FF6700',
                        fontSize: '.8rem',
                        letterSpacing: '.6px',
                        color: '#fff'
                    }}>
                    Reject Chargeback
                </Button>
            </Box>
        )
}
export default RejectChargeBack;
