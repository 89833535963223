import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ExportJsonCsv } from "react-export-json-csv";
import { useReactToPrint } from "react-to-print";

const AccountStatement = () => {
  const [wallets, setWallets] = useState([]);
  const [defaultWallet, setDefaultWallet] = useState({});

  const [disabled, setDisabled] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fileType, setFileType] = useState("csv");
  const [csv, setCsv] = useState(false);
  const [pdfBtn, setPdfBtn] = useState(false);

  const [state, setState] = useState({
    start: "",
    end: "",
    accountNumber: "",
  });

  const { start, end, accountNumber } = state;

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  // const [print] = useState(false);
  const pageToPrintRef = useRef(false);

  // for printing pdf
  const handlePrint = useReactToPrint({
    content: () => pageToPrintRef.current,
    // onAfterPrint: () => setPageState(false)
  });

  // okay
  const split = (date) => {
    const d = date.split("T");
    return d[0];
  };

  useEffect(() => {
    getUserWallets();

    if (wallets.length > 0) {
      setState((state) => ({
        ...state,
        accountNumber: wallets[0].accountNo,
      }));
    }
  }, [wallets]);

  const handleFetchStatement = async () => {
    // setLoading(true);
    // const res = await httpGet(
    //   `${
    //     customBaseUrl.temporalservice
    //   }/api/v1/wallet/account/statement/${accountNumber}?fromdate=${split(
    //     new Date(start).toISOString()
    //   )}&todate=${split(new Date(end).toISOString())}`
    // );
    // if (res.data) {
    //   setLoading(false);
    //   setData(res.data);
    //   if (fileType === 'csv') {
    //     setCsv(true);
    //   } else if (fileType === 'pdf') {
    //     setPdfBtn(true);
    //   }
    // } else {
    //   setLoading(false);
    //   swal('Oops!', 'No Transactions within these period', 'error');
    // }
  };

  // useEffect(() => {
  //   if (start === '' || end === '') {
  //     setDisables(true);
  //   } else {
  //     setDisables(false);
  //   }
  // }, [start, end]);
  // console.log('start', data);

  const { user } = JSON.parse(localStorage.getItem("userDetails"));

  // export const walletDefault = `${baseUrl().wallet}/wallet/default`;

  const getUserWallets = async () => {
    await axios({
      method: "get",
      url: `https://services.wayapos.ng/temporal-service/api/v1/wallet/accounts/${user?.id}`,
    }).then((res) => {
      if (res && res?.status) {
        setWallets(res.data.data);
        // if (res.data.length && res.data.length < 2) {
        // if (res.data.length && res.data.length < 2) {
        //   const result = res.data.filter((item) => item?.walletDefault);
        //   setDefaultWallet(result[0]);
        //   console.log(result[0])
        // }
        // setWalletLoading(false);
      }
    });
    return [];
  };

  const exportHeader = [
    { name: "AccountNo", key: "accountNo" },
    { name: "Transaction Type", key: "debitCredit" },
    { name: "Receivers Email", key: "receiverEmail" },
    { name: "tranId", key: "tranId" },
    { name: "Reason", key: "tranNarration" },
    { name: "Transaction Date", key: "transactionDate" },
    { name: "Transaction Time", key: "transactionTime" },
  ];

  return (
    <div className="account-statement w-100">
      <div className="as-head">
        <h4
          className="mb-1 text-center text-capitalize"
          style={{ fontSize: "1rem" }}
        >
          account statement
        </h4>

        <p
          className="delete-modal-sub text-center mt-2 lh-base"
          style={{ color: "#000", fontSize: "1rem" }}
        >
          Choose a timeline for your statment
        </p>
      </div>

      <div className="m-auto mt-5" style={{ width: "60%" }}>
        <div className="my-3">
          <h6 className="mb-2 text-capitalize" style={{ fontSize: ".8rem" }}>
            start date
          </h6>

          <Form.Control
            type="date"
            value={start}
            name="start"
            size="lg"
            placeholder="Start Date"
            onChange={handleChange}
            // onChange={handleChange}
            className="w-100 fs-12"
            style={{
              border: "0.5px solid #E7472C",
              letterSpacing: ".3px",
              height: "45px",
              // width: '60%',
              borderRadius: "3px",
            }}
          />
        </div>

        <div className="my-3 mb-4 w-100">
          <h6 className="mb-1 text-capitalize" style={{ fontSize: ".8rem" }}>
            end date
          </h6>

          <Form.Control
            type="date"
            value={end}
            name="end"
            size="lg"
            placeholder="End Date"
            // onChange={() => {
            // 	handleChange();
            // 	setCsv(false);
            // }}
            onChange={handleChange}
            className="w-100 fs-12"
            style={{
              border: "0.5px solid #E7472C",
              letterSpacing: ".3px",
              height: "45px",
              borderRadius: "3px",
            }}
          />
        </div>

        <div className="my-3 mb-4 w-100">
          <h6 className="mb-1 text-capitalize" style={{ fontSize: ".8rem" }}>
            select account no
          </h6>
          <Form.Select
            aria-label="Default select example"
            name="accountNo"
            value={accountNumber}
            size="lg"
            onChange={handleChange}
            id=""
            className="w-100 fs-12 mb-3"
            style={{
              border: "0.5px solid #E7472C",
              letterSpacing: ".3px",
              height: "45px",
              borderRadius: "3px",
            }}
          >
            <>
              <option className="text-capitalize">select account no</option>

              {wallets.length > 0 ? (
                wallets.map((wallet) => (
                  <option key={wallet.id} value={wallet.accountNo}>
                    {wallet.accountNo}
                  </option>
                ))
              ) : (
                <option value="0">No Account Yet</option>
              )}
            </>
          </Form.Select>
        </div>

        <div className="my-3 mb-4 w-100">
          <h6 className="mb-1 text-capitalize" style={{ fontSize: ".8rem" }}>
            select file
          </h6>

          <Form.Select
            name=""
            id=""
            onChange={(e) => setFileType(e.target.value)}
            className="w-100 fs-12 mb-3"
            style={{
              border: "0.5px solid #E7472C",
              letterSpacing: ".3px",
              height: "45px",
              borderRadius: "3px",
            }}
          >
            <option value={fileType} name="csv">
              CSV
            </option>
            <option value={fileType} name="pdf">
              PDF
            </option>
          </Form.Select>
        </div>

        <div className="as-button">
          {!csv && !pdfBtn && (
            <Button
              type="button"
              loading={loading}
              disabled={(loading ? true : null) || disabled}
              onClick={handleFetchStatement}
              content="Generate Statment of Account"
              className="mx-auto py-2 w-100 mb-4 fw-semi-bold"
              style={{
                display: "block",
                backgroundColor: "#34A300",
                border: "0.5px solid #34A300",
                fontSize: ".9rem",
                height: "45px",
                opacity: disabled ? 0.5 : 1,
              }}
            >
              Generate Statment of Account
            </Button>
          )}

          {pdfBtn && (
            <Button
              type="button"
              loading={loading}
              disabled={(loading ? true : null) || disabled}
              onClick={handlePrint}
              style={{ opacity: disabled ? 0.5 : 1 }}
              content="Print to pdf"
            >
              Print to pdf
            </Button>
          )}

          {csv && (
            <ExportJsonCsv
              style={{
                border: "none",
                background: "none",
                display: "flex",
                justtifyContent: "center",
              }}
              headers={exportHeader}
              items={data}
            >
              <div className="btn_Export">Download Csv</div>
            </ExportJsonCsv>
          )}
        </div>
      </div>

      <div style={{ position: "absolute", zIndex: -50, opacity: 0 }}>
        {/* <PageToPrint ref={pageToPrintRef} data={data} /> */}
      </div>
    </div>
  );
};

export default AccountStatement;
