import moment from "moment";
import { settlementStatistics } from "plugins/urls";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { apiCall } from "utilities/apis";
import { ReactComponent as Naira } from "../../assets/icons/naira_balance.svg";
import "./dashboard.scss";

const DashboardCard = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { lastSettlementAmount, nextSettlementAmount } = state || {};
  const getSettlementStatistics = () =>
    apiCall({
      method: "get",
      url: settlementStatistics,
      setLoading,
      noError: true,
      defaultMeta: true,
      success: (data) => {
        const {
          totalActualAmount,
          totalSettlementAmount,
          nextSettlementAmount,
          lastSettlementAmount,
        } = data;
        setState((state) => ({
          ...state,
          totalActualAmount,
          totalSettlementAmount,
          nextSettlementAmount,
          lastSettlementAmount,
        }));
      },
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });
  useEffect(() => {
    getSettlementStatistics();
  }, []);
  return (
    <Row className="mt-40">
      <Col>
        <div className="settlement-card font-default mt-2 mt-md-0">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="fs-18 text-darker fw-700">
                <Naira className="mr-5" />
                {lastSettlementAmount?.settledAmount?.toLocaleString() ||
                  "0.00"}
              </h4>
              <h4 className="fs-14 text-orange cursor-pointer text-decoration-underline">
                See all settlements
              </h4>
            </div>

            <div>
              <h4 className="fs-18 text-orange fw-700">Last Settlement</h4>
              <h4 className="fs-14 text-semi-dark mt-20">
                {lastSettlementAmount?.createdAt
                  ? `Settled on ${moment(
                      lastSettlementAmount?.createdAt
                    )?.format("MMMM Do YYYY, h:mm a")}`
                  : "No settlement yet"}
              </h4>
            </div>
          </div>
        </div>
      </Col>

      <Col>
        <div className="settlement-card font-default mt-2 mt-md-0">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="fs-18 text-darker fw-700">
                <Naira className="mr-5" />
                {nextSettlementAmount?.settledAmount?.toLocaleString() ||
                  "0.00"}
              </h4>
              <h4 className="fs-14 text-orange cursor-pointer text-decoration-underline">
                See all settlements
              </h4>
            </div>
            <div>
              <h4 className="fs-18 text-orange fw-700">Next Settlement</h4>
              <h4 className="fs-14 text-semi-dark mt-20">
                {nextSettlementAmount?.createdAt
                  ? `Settled on ${moment(
                      nextSettlementAmount?.createdAt
                    )?.format("MMMM Do YYYY, h:mm a")}`
                  : "No settlement yet"}
              </h4>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DashboardCard;
