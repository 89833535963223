import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from '../../../../../components/Modal';

const ChangeRole = ({ show, onClose, roleId, handleChange, roles, submit }) => {
	return (
		<>
			<Modal show={show} onclick={onClose} title='Change Role'>
				<div className='modal-form' style={{ width: '80%' }}>
					<p
						className='text-center fs-12 py-3 mx-auto'
						style={{ width: '90%' }}>
						This action will change the role of this user. This user
						will no longer have the access to the previous role.
					</p>

					<Form className='px-1 mx-auto' style={{ width: '75%' }}>
						<Form.Select
							aria-label='Default select example'
							name='roleId'
							value={roleId}
							size='lg'
							onChange={handleChange}
							placeholder='select'
							className='w-100 fs-12 form-style'
							style={{
								cursor: 'pointer',
							}}>
							{roles?.map((role, index) => {
								return (
									<option key={index} value={role.id}>
										{role.name}
									</option>
								);
							})}
						</Form.Select>
						<br />
					</Form>

					<Button
						type='submit'
						onClick={submit}
						size='md'
						className='mx-auto py-2 w-75'
						style={{
							display: 'block',
							backgroundColor: '#FF6700',
							border: '0.5px solid #FF6700',
							fontWeight: '500',
							fontSize: '.8rem',
						}}>
						Transfer
					</Button>

					<p
						onClick={onClose}
						style={{
							display: 'block',
							color: '#FF6700',
							fontSize: '.7rem',
							fontWeight: '400',
							cursor: 'pointer',
						}}
						className='m-auto my-3 text-decoration-none text-center'>
						Cancel
					</p>
				</div>
			</Modal>
		</>
	);
};

export default ChangeRole;
