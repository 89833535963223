import React, { useState } from "react";
import { Box, DialogActions, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";

export const successCheckCircle = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.6322 23.5039L26.1922 36.9573L22.1602 32.9253"
      stroke="#0EA002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 58C45.464 58 58 45.464 58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58Z"
      stroke="#0EA002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const successCheckFilled = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#219653" fillOpacity="0.1" />
    <path
      d="M32.6903 48.4295L45.7506 63.0677L68.9348 39.5124"
      stroke="#219653"
      strokeWidth="6"
    />
  </svg>
);

SuccessTemp.propTypes = {
  close: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.string,
  circle: PropTypes.bool,
  titleTop: PropTypes.string,
  titleDown: PropTypes.string,
};

export function FailedTemp({
  close,
  loading,
  message,
  circle = false,
  titleTop,
  titleDown,
  okText,
}) {
  return (
    <Stack spacing={3}>
      <Box alignSelf="center" textAlign="center" mt={5} mb={7}>
        <Box className="heading">{"Sorry,Insufficient Fund"}</Box>
      </Box>

      <Box alignSelf="center" textAlign="center" mt={2}>
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" fill="#FDEDEA" />
          <path
            d="M63.0423 40.4852L60.5161 37.959L50.5007 47.9744L40.4852 37.959L37.959 40.4852L47.9744 50.5007L37.959 60.5161L40.4852 63.0423L50.5007 53.0269L60.5161 63.0423L63.0423 60.5161L53.0269 50.5007L63.0423 40.4852Z"
            fill="#FF5349"
          />
        </svg>
        <Box mb={2} mt={2}>
          <Box className="heading">{titleDown}</Box>
          <p className="mt-3 sub-heading">{message}</p>
        </Box>
      </Box>

      <DialogActions className="justify-content-center">
        <div className="justify-center d-flex">
          <LoadingButton
            fullWidth
            size="large"
            loading={loading}
            color="error"
            className="grey-button"
            onClick={close}
          >
            {okText ? okText : "Ok"}
          </LoadingButton>
        </div>
      </DialogActions>
    </Stack>
  );
}

export default function SuccessTemp({
  close,
  loading,
  message,
  circle = false,
  titleTop,
  titleDown,
  okText,
}) {
  return (
    <Stack spacing={3}>
      {titleTop && (
        <Box alignSelf="center" textAlign="center" mt={5} mb={7}>
          <Box className="heading">{titleTop}</Box>
        </Box>
      )}
      <Box alignSelf="center" textAlign="center" mt={15}>
        <h1>{circle ? successCheckCircle : successCheckFilled}</h1>
        <Box mb={7} mt={5}>
          <Box className="heading">{titleDown}</Box>
          <p className="mt-3 sub-heading">{message}</p>
        </Box>
      </Box>

      <DialogActions className="justify-content-center">
        <div className="justify-center d-flex">
          <LoadingButton
            fullWidth
            size="large"
            loading={loading}
            color="error"
            className="grey-button"
            onClick={close}
          >
            {okText ? okText : "Ok"}
          </LoadingButton>
        </div>
      </DialogActions>
    </Stack>
  );
}
