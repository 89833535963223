import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { Slide, toast } from "react-toastify";
import { SettlementPreference } from "../pages/SettlementPreference/SettlementPrefence";
import TeamMembers from "../pages/TeamMembers";
import Transparacy from "../pages/Transparacy";
import LoginInformation from "../pages/updateAccount/components/LoginInformation";
import GenerateAccountStatement from "../pages/updateAccount/screens/GenerateAccountStatement";
import InviteFriend from "../pages/updateAccount/screens/InviteFriend";
import UpdateAccount from "../pages/updateAccount/UpdateAccount";

const settingTabs = [
  /* {
    id: '102',
    title: 'Notification settings',
    active: 'notification',
    description: 'Email, SMS, Push notification',
    options: [
      // {
      //   id: '2001',
      //   title: 'Push notifications',
      //   active: false,
      // },
      {
        id: '2002',
        title: 'SMS notifications',
		  name: 'sms',
        active: true,
      },
      {
        id: '2003',
        title: 'Email notifications',
		  name: 'email',
        active: false,
      },
      // {
      //   id: '2004',
      //   title: 'New notifications',
      //   active: true,
      // },
    ],
  }, */
  {
    id: "113",
    title: "Login Information",
    active: "loginInfo",
    description: "Location, device, time of login",
  },
];

export const settingsMenu = ({ setCurrentTab, invitees }) => [
  {
    name: "Account Settings",
    description: "Update profile, change password, change pin e.t.c.",
    component: <UpdateAccount />,
  },
  /*   {
    name: "Notification settings",
    description: "Email, SMS, Push notification",
    component: (
      <NotificationSettings
        options={settingTabs[0].options}
        setCurrentTab={setCurrentTab}
      />
    ),
  }, */
  {
    name: "Wayapos Settings",
    description: "Settlement Account, Payment Preference",
    page: <SettlementPreference />,
  },

  {
    name: "Transparency",
    description: "Terms & Condition, privacy policy, about us!",
    component: <Transparacy />,
  },
  {
    name: "Invite a friend",
    description: "Share Wayabank to your friends & family",
    component: (
      <InviteFriend setCurrentTab={setCurrentTab} invitees={invitees} />
    ),
  },
  {
    name: "Login Information",
    description: "Location, device, time of login",
    component: <LoginInformation setCurrentTab={setCurrentTab} />,
  },
];

const handleSuccess = (setCurrentTab, data) => {
  toast.success(data?.data?.message || "Pin created successfully", {
    transition: Slide,
    hideProgressBar: true,
    autoClose: 3000,
  });
  setCurrentTab({ component: null });
};

export const updateMenu = ({ click, setCurrentTab }) => [
  {
    name: "Edit Profile",
    component: null,
    click,
    class: "pt-4",
  },
  {
    name: "Account Statement",
    component: <GenerateAccountStatement setCurrentTab={setCurrentTab} />,
  },
  /*   {
    name: "Change Pin",
    // description: 'Switch from Personal to Corperate Accounts',
    component: (
      <NewPinIndex close={(data) => handleSuccess(setCurrentTab, data)} />
    ),
  }, */
];

export default function SettingsMenus({
  setCurrent,
  menu,
  clss,
  currentTab,
  activeClass = "active",
}) {
  return menu.map((item, index) => (
    <Box
      tabIndex={index}
      onClick={() =>
        (typeof item.click === "function" && item.click(item)) ||
        (typeof setCurrent === "function" && setCurrent(item))
      }
      key={item.name}
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 15px 10px -15px #ccc",
        border: "0.5px solid #F3F5F6",
      }}
      className={`d-flex justify-content-between align-items-center p-2 mb-2 br-2 rounded menu-div ${
        item.class
      } ${clss?.main} ${currentTab?.name === item.name ? activeClass : ""}`}
    >
      <div className="" style={{ backgroundColor: "common.white" }}>
        <div tabIndex={index} className={`heading fw-semi-bold ${clss?.name}`}>
          {item.name}
        </div>
        {item.description && (
          <div className="sub-heading">
            <small>{item.description}</small>
          </div>
        )}
      </div>

      <div className={`${clss?.icon}`}>
        {item.element || <ChevronRightIcon />}
      </div>
    </Box>
  ));
}
