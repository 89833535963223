import React from 'react';
import './loader.scss'
import Spinner from "react-bootstrap/Spinner";

const CircularLoader = () => {
    return (
        <div className='align-items-center d-flex justify-content-center mt-5'>
            <Spinner animation="border" role="status" variant='success' />
        </div>
           )
};

export default CircularLoader;
