import {useNavigate, Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import Divider from "../../Divider/Divider";
import {logout} from "../SideBar/SideBar";

const MobileList = ({ sidebarMenu, externalLinks, toggleDrawer, setTitle, title}) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => toggleDrawer(false)}
        >
            <List>
                {sidebarMenu.map(({name, link, icon}, index) => (
                    <ListItem
                        key={name} disablePadding
                        onClick={() => {
                            navigate(link);
                            setTitle(name);
                        }}
                        className={
                            title === name ? 'Navigation-active' : 'Navigation'
                        }
                    >
                        <Link to={link}>
                            <ListItemButton>
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {externalLinks.map(({name, link, icon}) => (
                    <ListItem
                        key={name} disablePadding
                        onClick={() => {
                            if (name === 'Logout'){
                                return logout()
                            }
                            navigate(link);
                            setTitle(name);
                        }}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
export default MobileList;
