import { ArrowForward, Check, Pending } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import UploadModal from './uploadModal';


const Tier3 = ({uploadDoc, postData, setPostData, showModal, hideModal, kycStatus, level3}) => {
//  const [] = useState(false);
// const [postData, setPostData] = useState({})
const [name, setName] = useState('');
const [passport, setPassport] = useState([]);
const [id, setId] = useState([]);
const [utility, setUtility] = useState([]);
const rejected='';
const pending='';
const upload='';
const check='';



const {
    userPassportPhotograph,
    userInternationalPassport,
    userDriversLicense,
    userNationalId,
    userVotersCard,
    usersUtilityBill,
    userCAC,
    TIN,
  } = kycStatus;

  useEffect(() => {
    const passport1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'PHOTO'
    )[0];
    const id1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'ID'
    )[0];
    const utility1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'UTILITY'
    )[0];
    setPassport(passport1);
    setId(id1);
    setUtility(utility1);
  }, [kycStatus]);


    return (
        <div className="tier" id="tier3">
          {/* <div className="info my-3">
            <p className="info-1">Tier 3</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  kycStatus?.passPortPhoto?.status === true &&
                  kycStatus?.utilityBill?.status === true &&
                  (kycStatus?.voterCard?.status === true ||
                    kycStatus?.internationalPassport?.status === true ||
                    kycStatus?.driverLicense?.status === true)
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {kycStatus?.passPortPhoto?.status === true &&
                kycStatus?.utilityBill?.status === true &&
                (kycStatus?.voterCard?.status === true ||
                  kycStatus?.internationalPassport?.status === true ||
                  kycStatus?.driverLicense?.status === true)
                  ? 'Completed'
                  : 'Not Completed'}
              </p>
            </div>
          </div>*/}
          <div >
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">Passport Photograph</td>
                  <td className="d-flex flex-column">
                    <button
                      disabled={passport?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('photograph');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {passport?.status === 'APPROVED'
                          ? 'Uploaded'
                          : 'Upload'}
                      </span>
                    </button>
                  </td>
                  <td>
                    <div
                      className={
                        passport?.status === 'APPROVED'
                          ? 'status-true'
                          : passport?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          passport?.status === 'APPROVED'
                            ? check
                            : passport?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {passport?.status}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">
                    NIN
                  </td>
                  <td className="d-flex flex-column">
                    <button
                    disabled={id?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('ID');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {id?.status === 'APPROVED'
                          ? 'Uploaded'
                          : 'Upload'}
                      </span>
                    </button>
                    </td>
                    <td>
                    <div
                      className={
                        id?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          id?.status === 'APPROVED'
                            ? check
                            : id?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {id?.status}
                      </span>
                    </div>
                    {/* <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>{kycStatus?.voterCard?.status}</span>
                    </div> */}
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">
                    <div>
                      <p>Utility bill</p>
                      <p>
                        (Any TV subscription bills, any internet subscription
                        bills, bank statement)
                      </p>
                    </div>
                  </td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={utility?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('utility');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {utility?.status === 'APPROVED'
                          ? 'Uploaded'
                          : 'Upload'}
                      </span>
                    </button>
                  </td>
                  <td className="">
                    <div
                      className={
                        utility?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          utility?.status === 'APPROVED'
                            ? check
                            : utility?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {utility?.status}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*<div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- External (
              {level3?.maxNumberOfTxnAllowed}){' '}
            </p>
            <p className="limit-info">
              Daily limit - External transfer (N
              {level3?.singleTransferLimit}){' '}
            </p>
            <p className="limit-info">
              Daily Number of Trasfers - Internal (
              {level3?.maxNumberOfTxnAllowed}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit- External Transfer (N
              {level3?.singleTransferLimit}){' '}
            </p>
            <p className="limit-info">
              Daily Number of Trasfers - Internal (Unlimited)
            </p>
            <p className="limit-info">
              Daily limit - Internal Transfer -wallet to wallet: (N
              {level3?.maximumLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit- Internal Transfer -wallet to wallet :(N
              {level3?.singleTransferLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="col">
              <div className="tier-foot-1">
                <p className="limit-info fw-bold my-3"> NB: </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – sole proprietorship:
                </p>
                <p> Upload Any 1 Government ID for the director. </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – LTD/PLC and above:
                </p>
                <p>
                  Upload at least 2 Government ID of the directors - Scanned to
                  one file as PDF and upload once.
                </p>
              </div>
              <div className="tier-foot-1">
                Supported files for upload includes, jpeg, png, pdf
              </div>
            </div>
            
          </div>
          <div style={{ height: 100 }} />*/}
          {showModal && 
          <UploadModal
            showModal={showModal}
            hideModal={hideModal}
            postData={postData}
            setPostData={setPostData}
            name={name}
            uploadDoc={uploadDoc}
          />}
        </div>
    )
}
export default Tier3