import { Box, Grid, Typography } from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import PropTypes from "prop-types";
import InviteFriend from "../screens/InviteFriend";

InviteEarnComponent.propTypes = {
  amount: PropTypes.number,
  setCurrentTab: PropTypes.func,
  hide: PropTypes.bool,
  code: PropTypes.string,
};

export default function InviteEarnComponent({ amount, setCurrentTab, hide }) {
  return (
    <Box
      className="pointer mt-3"
      onClick={() =>
        setCurrentTab({
          component: <InviteFriend setCurrentTab={setCurrentTab} />,
        })
      }
      style={{ border: "0.5px solid grey" }}
      p={1}
    >
      <Grid container spacing={2} border>
        <Grid item xs={12} lg={8} pr={4}>
          <Box sx={{ mb: 3 }} className="fw-bold fs-14">
            Invite & Earn
          </Box>

          <Button
            type="submit"
            size="md"
            className="py-2 w-75 mb-2 fw-semi-bold text-capitalize"
            style={{
              display: "block",
              backgroundColor: "#FF6700",
              border: "0.5px solid #FF6700",
              fontSize: ".8rem",
            }}
          >
            view referral code
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{}}
            className="fs-12 mb-1"
            style={{ fontWeight: "500", color: "#444" }}
          >
            Amount Earn:
          </Box>

          <div className="fs-16" style={{ fontWeight: "500" }}>
            {hide ? "*********" : `N${amount.toLocaleString()}K`}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
