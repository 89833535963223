import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const CreateAdminSchema = Yup.object().shape({
    firstName:Yup.string()
        .min(2,"Too short!")
        .max(50,"too long!")
        .required("Required!"),
    surname:Yup.string()
        .min(2,"Too short!")
        .max(50,"too long!")
        .required("Required!"),
    email:Yup.string()
        .email("Invalid email")
        .required("Required!"),
    phoneNumber:Yup.string()
        .required("Phone number is required")
        .matches(phoneRegExp,{message:"Please enter a valid phone number",excludeEmptyString:false})
        .min(10,"too short")
        .max(13,"too long")

})

export const createAdminInitialVal = {
    firstName:'',
    surname:'',
    email:'',
    phoneNumber:''
}
