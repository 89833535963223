import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import useUser from "../../components/Hooks/useUser";
import useUserDetails from "../../components/Hooks/useUserDetails";
import Loader from "../../components/Loader";
import SubmitLoader from "../../components/SubmitLoader/SubmitLoader";
import { signIn, twoFA, validate, walletDetails } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import ChangePinModal from "../Settings/pages/updateAccount/components/ChangePinModal";
import PinModal from "./twoFa";
import VerifyOtp from "./VerifyOtp";
// import { decrypt } from "utils/helper";
const AuthLogin = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    emailOrPhoneNumber: "",
    password: "",
    otp: false,
    message: "",
    twoFa: "",
  });
  const [refreshing, setRefreshing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState({ dateOfBirth: new Date() });
  const [showPassword, setShowPassword] = useState(false);
  const [show2Fa, setShow2Fa] = useState(false);
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsloading] = useState(true);

  const { getUser } = useUser();
  const { getUserDetails } = useUserDetails();

  const { emailOrPhoneNumber, password, message, otp } = state;
  const onLogin2 = () => {
    const iv = new URLSearchParams(window.location?.href).get('iv');
    const ecncryptedToken = new URLSearchParams(window.location?.href).get('token');
    const userId = new URLSearchParams(window.location?.href).get('userId');
    const tkn = new URLSearchParams(window.location?.href).get('t');

    
    //  console.log({ ecncryptedToken });

    const token = `serial ${tkn}` // decrypt({ iv, encryptedData: ecncryptedToken });
    console.log({ token });
    window.location.href='/get-started'
    localStorage.setItem("userDetails", {firstName: 'ff'});
  }

  const onLogin = (e) => {
    // e.preventDefault();
    
    let reqBody = {
    };
    const iv = new URLSearchParams(window.location?.href).get('iv');
    const ecncryptedToken = new URLSearchParams(window.location?.href).get('token');
    const userId = new URLSearchParams(window.location?.href).get('userId');
    const tkn = new URLSearchParams(window.location?.href).get('t');

    
    //  console.log({ ecncryptedToken });

    const token = `serial ${tkn}` // decrypt({ iv, encryptedData: ecncryptedToken });
    // return console.log({ token });
    apiCall({
      data: reqBody,
      url: validate,
      method: "post",
      token,
      setLoading,
      error: (err) => setState({ ...state, message: err }),
      success: (data) => {
        setRefreshing(true);
        const user = data.data;
        const userCopy = { ...user };
        delete userCopy.address;

        setAuthUser({ ...userCopy, dateOfBirth: new Date(user?.dateOfBirth) });

        const secondSuccess = async (res) => {
          const completeDetails = {
            ...res,
            ...user,
            // id: res?.userId,
            officeAddress: res?.organizationAddress,
            dateOfBirth: new Date(user.dateOfBirth),
          };
          console.log({user, completeDetails });
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              token,
              user: completeDetails,
            })
          );

          getUser(completeDetails, token).then((r) => {
            //  console.log({r})
            localStorage.setItem('token', token);
            window.location.assign("/get-started")
        });
        };

        getUserDetails(user?.id, secondSuccess, token);
      },
    });
  };

  const onOTP = (e) => {
    e.preventDefault();

    let reqBody = {
      emailOrPhoneNumber,
      otp,
      password,
    };


    axios({
      method: "post",
      url: `${twoFA}/${state.emailOrPhoneNumber}`,
      data: reqBody,
    })
      .then((res) => {
        
        if (res.data.status === true) {
          setShow2Fa(true);

        } else {
          if(res.data?.message ===" 2FA not active for this user"){
            onLogin();
          }
        }
      })
      .catch((err) => {
        onLogin();
        console.log(err);
      
      });

    /*  apiCall({
      data: reqBody,
      url: `${twoFA}${state.emailOrPhoneNumber}`,
      method: "post",
      setLoading,
      error: (err) => setState({ ...state, message: err }),
      success: (data) => {
        if (data.status) {
          setShow2Fa(true);
        } else {
          if (data?.message == " 2FA not active for this user") {
            onLogin();
          } else {
            toast.error(data?.message);
          }
        }
      },
    }).catch((err) => {
      console.log(err);
      onLogin();
    
    });;*/
  };
  useEffect(() => {
    //  window.location.href='https://staging.wayabank.ng/login?client=wayaquick'
    onLogin()
    //  window.location.href='http://localhost:3001/login?client=wayapos'
}, []);
  const onOTP1 = () => {
    axios.post(`${twoFA}${state.emailOrPhoneNumber}`).then((res) => {
      console.log(res);
      setState((state) => ({
        ...state,
        twoFa: true,
      }));
    });
  };
  return (
    <>
      {refreshing ? (
        <div className="row h-100vh">
          <div className="d-flex justify-content-center align-items-center">
            <Box>
              <Loader />
            </Box>
          </div>
        </div>
      ) : (
        <div>
          {otp ? (
            <VerifyOtp phoneOrEmail={emailOrPhoneNumber} />
          ) : (
            <AuthComponent>
              <AuthContainer>
                <div className="login-header">
                  <h4 className="fs-24 fw-500 login-text">Welcome Back</h4>
                </div>
              </AuthContainer>

              {/* expired credentials */}
            </AuthComponent>
          )}
        </div>
      )}
    </>
  );
};

export default AuthLogin;
