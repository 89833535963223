import React from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { getAllUnit } from "./ManageUnit";
import { getAllMenu } from "plugins/urls";
import { Spinner } from "react-bootstrap";

export const EditAdminMenu = (props) => {
  const { modalState, role, onChange, menu, submit } = props;
  const [menulist, setMenulist] = useState({
    menu: [],
    allUnit: [],
    checked: [],
  });

  const getDefaultMenu = () => {
    if (!isEmpty(menu)) {
      setMenulist((s) => ({ ...s, checked: menu }));
    }
    axios.get(getAllMenu).then((res) => {
      setMenulist((s) => ({ ...s, menu: res.data.data }));
    });
  };

  useEffect(() => {
    if (modalState) {
      getDefaultMenu();
      getAllUnit(setMenulist);
    }
  }, [modalState]);

  return (
    <form>
      <div className="container-fluid ">
        <div className="row mb-3">
          {menulist.menu.map(({ menuName }) => (
            <div className="col-12 col-md-6 py-1 d-flex align-items-center">
              <input
                placeholder=""
                type="checkbox"
                defaultChecked={menulist.checked.includes(menuName)}
                required
                value={menuName}
                name={"menuItem"}
                onChange={onChange}
              />
              <label className="fs-14 fw-400 ms-1">{menuName}</label>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column">
          <label className="fs-12 fw-400 mb-1"> SELECT UNIT</label>
          <div className="dropdown bg-white w-100">
            <select
              className="select admin-user-inputs px-3 py-2"
              name="unitId"
              onChange={onChange}
            >
              <option>{"--Select a unit--"}</option>
              {menulist.allUnit.map(({ unitName, unitId }) => (
                <option value={unitId}>{unitName}</option>
              ))}
            </select>
          </div>
        </div>

        {/*    <div className="d-flex flex-column">
          <label className="fs-12 fw-400 mb-1">CHECKER</label>
          <div className="dropdown bg-white w-100">
            <select
              className="select admin-user-inputs px-3 py-2"
              name="checker"
              onChange={onChange}
            >
              <option>{"--Select Checker--"}</option>
              {checker.map(({ name, value }) => (
                <option value={value}>{name}</option>
              ))}
            </select>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingBlock: "8px",
          }}
        >
          <button
            onClick={submit}
            className={`${props.extraPadding} orange-button`}
          >
            {"Save Changes"}
            {submit && <Spinner />}
          </button>
        </div>
      </div>
    </form>
  );
};
